import React, { useEffect, useState, useRef } from "react";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import MatchHeader from "./MatchHeader";
import TeamScore from "../../../components/Shared/TeamScore";
import MatchFooterCard from "./MatchFooterCard";
import i18n from "../../../i18n/i18n";
import { appStore, matchStore } from "../../../zustand/globalStore";
import { MatchSelectedProps } from "../../../definitions/interfaces/GlobalInterfaces";
import { TypeTimeOfMatch } from "../../../definitions/enums/GlobalEnums";
import {
  formatLocalDate,
  formatLocalHour,
  formatLocalMinutes,
} from "../../../utils/tools";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";

interface ServerTimeResponse {
  date: string;
}

const MatchSelected: React.FC<MatchSelectedProps> = ({
  firstTeam,
  secondTeam,
  roles,
}) => {
  const timeRef = useRef(0);
  const [isPlay, setIsPlay] = useState(false);
  const intervalTimer = useRef<any>(null);
  const [isMobile] = useMediaQuery("(max-width: 665px)");
  const [timeLabel, setTimeLabel] = useState("00:00");
  const { currentTournament } = appStore((state) => state);
  const { currentMatch } = matchStore((state) => state);

  const firstTeamScore =
    currentMatch?.scores?.[currentMatch?.first_team_id]?.goals || 0;
  const secondTeamScore =
    currentMatch?.scores?.[currentMatch?.second_team_id]?.goals || 0;

  const isLeftTeamHigherScore = firstTeamScore > secondTeamScore;
  const isRightTeamHigherScore = secondTeamScore > firstTeamScore;

  useEffect(() => {
    if (!currentMatch || !currentTournament) {
      return;
    }
    let startCounter = false;
    if (currentMatch?.isRunning && timeRef.current === 0) {
      startCounter = true;
    }
    startMatchTime(startCounter);
  }, [currentMatch, currentTournament]);

  const startMatchTime = (runTime = false) => {
    httpsCallable<unknown, ServerTimeResponse>(
      functions,
      "getServerTime"
    )().then((res) => {
      const currentServerDate = new Date(res.data.date);
      const lastDateMatchStarted = currentMatch?.on_last_play_time
        ? new Date(currentMatch?.on_last_play_time.seconds * 1000)
        : currentServerDate;
      let timePlayedDifference = Math.floor(
        (currentServerDate.getTime() - lastDateMatchStarted.getTime()) / 1000
      );
      let timePlayed = currentMatch?.time_played || 0;
      timeRef.current = Math.floor(timePlayed + timePlayedDifference);
      let { labelData } = timeForDisplay();
      setTimeLabel(labelData);
      if (runTime) {
        setIsPlay(true);
        runCounter();
      }
    });
  };

  const runCounter = () => {
    if (intervalTimer.current) {
      clearInterval(intervalTimer.current);
      intervalTimer.current = null;
    }
    intervalTimer.current = setInterval(() => {
      timeRef.current += 1;
      let { labelData } = timeForDisplay();
      setTimeLabel(labelData);
    }, 1000);
  };

  const timeForDisplay = () => {
    let timeForFirstTime =
      (currentTournament.minuts_first_time
        ? Math.ceil(currentTournament.minuts_first_time)
        : 45) * 60;
    //second time
    let timeForSecondTime =
      (currentTournament.minuts_second_time
        ? Math.ceil(currentTournament.minuts_second_time)
        : 45) * 60;
    //extra time
    let timeForExtraTime =
      (currentTournament.extra_time_minuts
        ? Math.ceil(currentTournament.extra_time_minuts)
        : 30) * 60;

    let totalSecondsPlayed = 0;
    let timesOfMatch = 0;
    if (currentMatch?.type_time_playing === TypeTimeOfMatch.firstTime) {
      totalSecondsPlayed = Math.floor(timeRef.current);
      timesOfMatch = timeForFirstTime;
    }
    if (currentMatch?.type_time_playing === TypeTimeOfMatch.secondTime) {
      let firstTimePlayed = Math.floor(currentMatch.first_time_played || 0);
      let extraTime = 0;
      if (firstTimePlayed >= timeForFirstTime) {
        extraTime = firstTimePlayed - timeForFirstTime;
      }
      totalSecondsPlayed = Math.floor(timeRef.current - extraTime);
      timesOfMatch = timeForSecondTime + timeForFirstTime;
    }

    if (currentMatch?.type_time_playing === TypeTimeOfMatch.firstOvertime) {
      let firstTimePlayed = Math.floor(currentMatch.first_time_played || 0);
      let extraTime = 0;
      if (firstTimePlayed >= timeForFirstTime) {
        extraTime = firstTimePlayed - timeForFirstTime;
      }
      let secondTimePlayed = Math.floor(currentMatch.second_time_played || 0);
      let extraTime2 = 0;
      if (secondTimePlayed >= timeForSecondTime) {
        extraTime2 = secondTimePlayed - timeForSecondTime;
      }
      totalSecondsPlayed = Math.floor(timeRef.current - extraTime - extraTime2);
      timesOfMatch = timeForExtraTime + timeForSecondTime + timeForFirstTime;
    }

    if (currentMatch?.type_time_playing === TypeTimeOfMatch.secondOvertime) {
      let firstTimePlayed = Math.floor(currentMatch.first_time_played || 0);
      let extraTime = 0;
      if (firstTimePlayed >= timeForFirstTime) {
        extraTime = firstTimePlayed - timeForFirstTime;
      }
      let secondTimePlayed = Math.floor(currentMatch.second_time_played || 0);
      let extraTime2 = 0;
      if (secondTimePlayed >= timeForSecondTime) {
        extraTime2 = secondTimePlayed - timeForSecondTime;
      }
      let firstExtraTime = Math.floor(
        currentMatch.extra_first_time_played || 0
      );
      let extraTime3 = 0;
      if (firstExtraTime >= timeForExtraTime) {
        extraTime3 = firstExtraTime - timeForExtraTime;
      }
      totalSecondsPlayed = Math.floor(
        timeRef.current - extraTime - extraTime2 - extraTime3
      );
      timesOfMatch =
        timeForExtraTime +
        timeForSecondTime +
        timeForFirstTime +
        timeForExtraTime;
    }

    const seconds = totalSecondsPlayed % 60;
    let minutsPlayed = Math.floor(totalSecondsPlayed / 60);
    let labelData = "";
    if (totalSecondsPlayed <= timesOfMatch) {
      labelData = `${minutsPlayed}:${seconds < 10 ? `0${seconds}` : seconds}`;
    } else {
      labelData = `${timesOfMatch / 60} +${Math.floor(
        (totalSecondsPlayed - timesOfMatch) / 60
      )}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return {
      labelData: labelData,
      timePlayed: timeRef.current,
    };
  };

  return (
    <Flex gap={4} flexDirection={"column"}>
      <Flex gap={1.5} flexDirection={"column"} alignItems="center">
        <Flex
          flexDirection={"column"}
          padding={"8px 0"}
          width={`${isMobile ? "100%" : "480px"}`}
        >
          <MatchHeader
            roles={roles}
            time={timeLabel}
            isRight={true}
            status={currentMatch?.status}
          />
          <Flex
            gap={1.5}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <TeamScore
              teamImage={firstTeam && firstTeam.media_url}
              team={firstTeam && firstTeam.name}
              score={firstTeamScore}
              showArrowIcon={isLeftTeamHigherScore}
              isRight={true}
            />
            <BodyText
              fontSize={17}
              fontWeight="semiBold"
              color={colors.text.black}
            >
              -
            </BodyText>
            <TeamScore
              teamImage={secondTeam && secondTeam.media_url}
              team={secondTeam && secondTeam.name}
              score={secondTeamScore}
              showArrowIcon={isRightTeamHigherScore}
              reverse
              isRight={true}
            />
          </Flex>
          <MatchFooterCard
            date={
              formatLocalDate(currentMatch?.match_date) ?? i18n.t("undefined")
            }
            hour={
              formatLocalHour(currentMatch?.match_date) ?? i18n.t("undefined")
            }
            minutes={
              formatLocalMinutes(currentMatch?.match_date) ??
              i18n.t("undefined")
            }
            location={currentMatch?.field_name ?? i18n.t("undefined")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MatchSelected;
