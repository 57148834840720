import React, { useState, useEffect } from 'react';
import { Box, Flex, Progress } from "@chakra-ui/react";
import i18n from "../../i18n/i18n";
import BodyText from "../../components/Common/BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";

const ProgressBarComponent = () => {
    const maxSteps = 7;
    const [currentStep, setCurrentStep] = useState(1);
    const [progress, setProgress] = useState(100 / maxSteps);

    const tournamentStepsFirstText = [
      i18n.t("inviteTeamsYourTournament"),
      i18n.t("createTournamentRules"),
      i18n.t("createTournamentPhases"),
      i18n.t("selectTeamPhase"),
      i18n.t("createMatch"),
      i18n.t("registerTeams"),
      i18n.t("goForCup"),
    ];

    const createStepText = (lastText: string) => (
      <>
        <BodyText color={colors.text.black} size="xxSmall" fontWeight="regular">
          {i18n.t("continueAt")}
        </BodyText>
        <BodyText color={colors.text.black} size="xxSmall" fontWeight="bold">
          {i18n.t("options")}/{i18n.t("seasons")}/{i18n.t(lastText)}
        </BodyText>
      </>
    );

     const tournamentStepsSecondText = [
       createStepText("inviteTeams"),
       createStepText("tournamentRules"),
       createStepText("tournamentPhases"),
       createStepText("selectTeams"),
       createStepText("createMatches"),
       <>
         <BodyText
           color={colors.text.black}
           size="xxSmall"
           fontWeight="regular"
         >
           {i18n.t("continueAt")}
         </BodyText>
         <BodyText color={colors.text.black} size="xxSmall" fontWeight="bold">
           {i18n.t("tournament")}/{i18n.t("teams")}/{i18n.t("sendForm")}
         </BodyText>
       </>,
       <BodyText color={colors.text.black} size="xxSmall" fontWeight="regular">
         {i18n.t("putTheBall")}
       </BodyText>,
     ];
    
    const currentStepTextFirst = tournamentStepsFirstText[currentStep - 1];
    const currentStepTextSecond = tournamentStepsSecondText[currentStep - 1];

    useEffect(() => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const increment = 100 / maxSteps;
          let nextProgress = prevProgress + increment;

          if (nextProgress >= 100) {
            setCurrentStep(maxSteps);
            nextProgress = 100;
            setTimeout(() => {
              setProgress(100 / maxSteps);
              setCurrentStep(1);
            }, 5000);
            return 100;
          } else {
            const step = Math.ceil(nextProgress / increment);
            setCurrentStep(step);
          }

          return nextProgress;
        });
      }, 5000);

      return () => clearInterval(interval);
    }, []);

  return (
    <Flex
      borderTopWidth="1px"
      borderTop="1px"
      borderBottomWidth="1px"
      padding={"8px 0px"}
      flexDirection={"column"}
      gap={2}
      borderColor={colors.figmaColors.grey1}
    >
      <Flex alignItems="center" gap={1}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="100%"
          background={colors.figmaColors.leaguesGradient1}
          position="relative"
          width="35px"
          height="35px"
        >
          <BodyText
            color={colors.text.white}
            size="extraSmall"
            fontWeight="bold"
            textAlign="center"
          >
            {currentStep}/{maxSteps}
          </BodyText>
        </Box>
        <Flex gap={0.5} flexDirection="column">
          <BodyText
            color={colors.figmaColors.secColor}
            size="extraSmall"
            fontWeight="bold"
          >
            {currentStepTextFirst}
          </BodyText>
          <Flex gap={1}>{currentStepTextSecond}</Flex>
        </Flex>
      </Flex>
      <Progress
        value={progress}
        size="sm"
        height="18px"
        borderRadius="10px"
        bg={colors.figmaColors.grey1}
        sx={{
          "& div": {
            backgroundColor: colors.figmaColors.leaguesHexGradient,
            transition: "width 0.5s ease-in-out",
          },
        }}
      />
    </Flex>
  );
}

export default ProgressBarComponent