import React, { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { trackEvent } from "../../utils/tools";
import i18n from "../../i18n/i18n";
import { auth } from "../../config/firebase";
import { useToast } from "@chakra-ui/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import CustomTextInput from "../../components/Common/CustomTextInput";
import FormComponent from "../../components/Common/FormComponent";
import AccessComponent from "../../components/LayoutsComponents/AccessComponent";

const RegisterPage: React.FC = () => {
  const toast = useToast();
  const [nameInput, setNameInput] = useState("");
  const [nameError, setNameError] = useState(false);
  const [lastnameInput, setLastnameInput] = useState("");
  const [lastnameError, setLastnameError] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent("SignUpScreen", {});
  }, []);

  const sendRegister = (event) => {
    event.preventDefault();

    if (nameInput === "") {
      setNameError(true);
    }
    if (lastnameInput === "") {
      setLastnameError(true);
    }
    if (emailInput === "") {
      setEmailError(true);
    }
    if (passwordInput === "") {
      setPasswordError(true);
    }

    if (
      nameInput !== "" &&
      lastnameInput !== "" &&
      emailInput !== "" &&
      passwordInput !== ""
    ) {
      setNameError(false);
      setLastnameError(false);
      setEmailError(false);
      setPasswordError(false);
      setLoading(true);
      registerUser();
    }
  };

  const registerUser = async () => {
    try {
      await createUserWithEmailAndPassword(
        auth,
        emailInput.trim(),
        passwordInput
      ).then(async (userCredential) => {
        const user = userCredential.user;

        await localStorage.setItem("signup_credential", user.uid);
        await localStorage.setItem("signup_firstname", nameInput);
        await localStorage.setItem("signup_lastname", lastnameInput);
        await localStorage.setItem("signup_email", emailInput);
      });
    } catch (error) {
      console.error(error);

      const errorMapping = {
        "auth/email-already-in-use": i18n.t("msgUserExist"),
        "auth/invalid-email": i18n.t("wrong_email"),
        "auth/operation-not-allowed": i18n.t("operation_not_allowed"),
        "auth/weak-password": i18n.t("weak_password"),
      };

      const _error =
        error instanceof FirebaseError ? error.code : error.toString();
      const message = errorMapping[_error] || i18n.t("error_creating_account");

      toast({
        title: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccessComponent>
      <FormComponent
        isLogin={false}
        isRegister={true}
        titleFrom={i18n.t("sign_up")}
        buttonText={i18n.t("registerMe")}
        footerText={i18n.t("already_have_account")}
        footerLink={i18n.t("log_in")}
        onSubmit={sendRegister}
      >
        <CustomTextInput
          textField={i18n.t("input_name")}
          placeholder={i18n.t("input_name")}
          isRequired={true}
          onChangeText={(text) => setNameInput(text)}
          isPassword={false}
          showError={nameError}
          marginTop={0}
        />
        <CustomTextInput
          textField={i18n.t("input_last_name")}
          placeholder={i18n.t("input_last_name")}
          isRequired={true}
          onChangeText={(text) => setLastnameInput(text)}
          isPassword={false}
          showError={lastnameError}
          marginTop={0}
        />
        <CustomTextInput
          textField={i18n.t("input_email")}
          placeholder={i18n.t("input_email")}
          isRequired={true}
          onChangeText={(text) => setEmailInput(text)}
          isPassword={false}
          showError={emailError}
          marginTop={0}
        />
        <CustomTextInput
          textField={i18n.t("input_password")}
          placeholder={i18n.t("input_password")}
          isRequired={true}
          onChangeText={(text) => setPasswordInput(text)}
          isPassword={true}
          showError={passwordError}
          marginTop={0}
        />
      </FormComponent>
    </AccessComponent>
  );
};

export default RegisterPage;
