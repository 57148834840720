import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";
import { CircleCheckIcon } from "../../definitions/constants/IconsComponent";
import ImageFromText from "./ImageFromText";
import CountryFlag from "./CountryFlag";

interface CardComponentProps {
  items: any[];
  isTeam: boolean;
  onSetCurrentItem: (item: any) => void;
  getItemName: (item: any) => string;
  getItemRegion: (item: any) => string;
  getItemImage: (item: any) => string | undefined;
  getItemCountryImage: (item: any) => string | undefined;
}

const CardComponent: React.FC<CardComponentProps> = ({
  items,
  isTeam,
  onSetCurrentItem,
  getItemName,
  getItemRegion,
  getItemImage,
  getItemCountryImage,
}) => {
  const [activeCardIndex, setActiveCardIndex] = useState<number>(-1);

  useEffect(() => {
    if (items.length > 0) {
      setActiveCardIndex(0);
      onSetCurrentItem(items[0]);
    }
  }, [items, onSetCurrentItem]);

  return (
    <Flex gap={4} flexDirection={"column"}>
      {items.map((item, index) => (
        <Flex
          key={index}
          align="center"
          borderRadius={20}
          padding={3}
          gap={2}
          border="1px solid"
          borderColor={
            activeCardIndex === index
              ? colors.figmaColors.borderCard
              : colors.figmaColors.grey1
          }
          onClick={() => {
            setActiveCardIndex(index);
            onSetCurrentItem(item);
          }}
          cursor="pointer"
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap={2}>
            <Box
              display="flex"
              borderRadius="100%"
              minWidth="60px"
              border={isTeam ? "none" : `2px solid ${colors.figmaColors.secD}`}
              borderColor={
                isTeam ? "transparent" : colors.figmaColors.leaguesGradient1
              }
              justifyContent="center"
              alignItems="center"
              padding={isTeam ? "10px" : "0"}
              width="60px"
              height="60px"
            >
              {getItemImage(item) ? (
                <Image
                  src={getItemImage(item)}
                  style={{
                    borderRadius: "100%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <ImageFromText
                  sizeIcon={{
                    width: "100%",
                    height: "100%",
                  }}
                  text={`${getItemName(item) ?? ""}`}
                />
              )}
            </Box>
            <Flex flexDirection="column" alignItems="flex-start">
              <BodyText
                size="small"
                fontWeight="regular"
                color={colors.text.black}
              >
                {getItemName(item)}
              </BodyText>
              <Flex alignItems="center" gap={1}>
                <CountryFlag countryName={getItemCountryImage(item)} />
                <BodyText
                  color={colors.text.lightGray3}
                  size="xxSmall"
                  fontWeight="regular"
                >
                  {getItemRegion(item)}
                </BodyText>
              </Flex>
            </Flex>
          </Flex>
          <Button
            borderRadius={10}
            gap={1}
            padding={"0px 8px"}
            height={"30px"}
            minWidth="fit-content"
            bg={
              activeCardIndex === index
                ? colors.figmaColors.secColor
                : colors.backgrounds.white
            }
            color={
              activeCardIndex === index
                ? colors.backgrounds.white
                : colors.figmaColors.secColor
            }
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="13px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={() => {
              setActiveCardIndex(index);
              onSetCurrentItem(item);
            }}
          >
            {activeCardIndex === index
              ? i18n.t("selected")
              : i18n.t("seeInformation")}
            {activeCardIndex === index && <CircleCheckIcon />}
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};

export default CardComponent;
