import { Box, Flex, Button } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../Common/BodyText";
import {
  CloseCircleIcon,
  WuamballIcon,
} from "../../definitions/constants/IconsComponent";
import i18n from "../../i18n/i18n";

function ShowSupportGroupView() {
  const { hideContainerModal } = appStore((state) => state);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      gap={8}
    >
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={hideContainerModal}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>

      <Flex direction="column" justifyContent="center">
        <WuamballIcon width="100%" height="36px" />
      </Flex>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
        gap={2}
        width={{
          base: `100%`,
          sm: "85%",
          md: `75%`,
          lg: "65%",
        }}
      >
        <BodyText
          fontSize={{ base: "20px", md: "28px" }}
          fontWeight="light"
          textAlign="center"
          textColor={colors.text.black}
        >
          {i18n.t("whatsApp")}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
          whiteSpace="normal"
          wordBreak="break-word"
        >
          {i18n.t("needHelp")}
        </BodyText>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap={2}
          w="fit-content"
        >
          <Button
            borderRadius="12px"
            bg={colors.backgrounds.white}
            color={colors.figmaColors.secColor}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            height="48px"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={hideContainerModal}
          >
            {i18n.t("writeWhatsApp")}
          </Button>
          <Button
            borderRadius="12px"
            bg={colors.figmaColors.secColor}
            color={colors.text.white}
            border="1px solid"
            borderColor={colors.figmaColors.secColor}
            fontSize="14px"
            height="48px"
            width="100%"
            fontWeight={"600"}
            _hover={{
              color: colors.backgrounds.white,
              bg: colors.figmaColors.secColor,
              borderColor: "blue.600",
            }}
            _active={{
              bg: colors.figmaColors.secColor,
              borderColor: "blue.700",
            }}
            onClick={hideContainerModal}
          >
            {i18n.t("joinGroup")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default ShowSupportGroupView;
