import React, { useState, useEffect, useCallback } from "react";
import { Avatar, Box, Flex } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../../components/Common/BodyText";
import OptionCard from "../../../components/Common/OptionCard";
import HeaderDefaultOptions from "../../../components/Common/HeaderDefaultOptions";
import {
  ProfileCircle,
  FlagIcon,
  RulerAndPenIcon,
  FieldIcon,
  MessageSquareIcon,
} from "../../../definitions/constants/IconsComponent";
import { consumeApi } from "../../../utils/tools";
import i18n from "../../../i18n/i18n";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import DeleteContentModal from "../../../components/Modals/DeleteContentModal";
import CreateOrEditPhase from "./Options/Phases/CreateOrEditPhase";
import CreateOrEditGroup from "./Options/Groups/CreateOrEditGroup";
import CreateOrEditSeason from "./Options/Seasons/CreateOrEditSeason";
import MessageConfirmationComponent from "../../../components/MessageConfirmationComponent";
import IconEdit from "../../../assets/icons/IconEdit";
import IconTrash from "../../../assets/icons/IconTrash";
import IconGroup from "../../../assets/icons/IconGroup";
import IconAddSquare from "../../../assets/icons/IconAddSquare";
import IconPeopleFC from "../../../assets/icons/IconPeopleFC";
import CreateOrEditTournament from "../../../components/Tournaments/CreateOrEditTournament";
import { getPhasesByTournamentId } from "../../../models/Tournament";
import { stepsTournaments } from "../../../definitions/constants/GlobalConstants";
import { getGroupsByTournamentAndPhase } from "../../../models/Tournament";
import DeletePhase from "./Options/Phases/DeletePhase";
import DeleteGroup from "./Options/Groups/DeleteGroup";
import DeleteSeason from "./Options/Seasons/DeleteSeason";
import DeleteTournament from "./Options/DeleteTournament/DeleteTournament";
import TournamentRegulations from "./Options/Regulations/TournamentRegulations";

interface StepData {
  name?: string;
}

function UpdateOrViewTournamentData() {
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(stepsTournaments.default);
  const [stepHistory, setStepHistory] = useState<string[]>([]);
  const [stepData, setStepData] = useState<StepData>({});
  const [phases, setPhases] = useState([]);
  const [groups, setGroups] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const { currentTournament, hideContainerModal } = appStore((state) => state);
  console.log("The current step is: ", currentStep);

  const goToStep = (stepKey: string, data?: StepData) => {
    setStepHistory((prevHistory) => [...prevHistory, currentStep]);
    setCurrentStep(stepKey);
    if (data) setStepData((prevData) => ({ ...prevData, ...data }));
  };

  const handleBack = () => {
    const previousStep = stepHistory[stepHistory.length - 1];
    if (previousStep) {
      setStepHistory((prevHistory) => prevHistory.slice(0, -1));
      setCurrentStep(previousStep);
    }
  };

  const fetchSeasons = useCallback(async () => {
    try {
      const payload = { tournament_id: currentTournament?.id };
      const response = await consumeApi(
        "tournaments/get_seasons",
        "POST",
        payload
      );

      if (response.status === "success") {
        setSeasons(response.seasons);
      } else {
        console.error("Error fetching seasons:", response.message);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }, [currentTournament?.id, setSeasons]);

  const fetchPhases = useCallback(async () => {
    try {
      const phasesFetch = await getPhasesByTournamentId(currentTournament.id);
      setPhases(phasesFetch);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching phases:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  }, [currentTournament?.id, setPhases]);

  const fetchGroups = useCallback(async () => {
    if (selectedPhase?.id) {
      try {
        const groupsData = await getGroupsByTournamentAndPhase(
          currentTournament?.id,
          selectedPhase.id
        );
        setGroups(groupsData);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    }
  }, [currentTournament?.id, selectedPhase?.id, setGroups]);

  useEffect(() => {
    if (currentTournament?.id) {
      fetchPhases();
      fetchSeasons();
    }
  }, [fetchPhases, fetchSeasons, currentTournament]);

  useEffect(() => {
    if (selectedPhase?.id) {
      fetchGroups();
    }
  }, [fetchGroups, selectedPhase?.id, groups]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={
        currentStep === stepsTournaments.deletePhase ||
        currentStep === stepsTournaments.deleteMatch ||
        currentStep === stepsTournaments.deleteSeason ||
        currentStep === stepsTournaments.deleteTournament ||
        currentStep === stepsTournaments.sureDeletion
          ? 8
          : 4
      }
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {currentStep === stepsTournaments.default && (
        <HeaderDefaultOptions
          title={i18n.t("tournamentOptions")}
          description={i18n.t("readyTournament")}
        />
      )}
      {/* Content for header modals */}
      {currentStep === stepsTournaments.optionsSeason && selectedSeason && (
        <ModalHeaderComponent
          title={i18n.t("seasonOptions", { name: selectedSeason.name })}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.default)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.finishSeason && (
        <ModalHeaderComponent
          title={i18n.t("selectChampion")}
          subtitle={i18n.t("readyTournament")}
          confirmText={i18n.t("continue")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          onConfirm={() => goToStep(stepsTournaments.successFinishSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={true}
        />
      )}
      {currentStep === stepsTournaments.modality && (
        <ModalHeaderComponent
          title={i18n.t("modality")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.selectTeams && (
        <ModalHeaderComponent
          title={i18n.t("selectTeams")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.teamsSelection && (
        <ModalHeaderComponent
          title={i18n.t("teamSelection", { name: selectedPhase.name })}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.selectTeams)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.createMatches && (
        <ModalHeaderComponent
          title={i18n.t("createMatches")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTournaments.inviteTeams && (
        <ModalHeaderComponent
          title={i18n.t("inviteTeams")}
          subtitle={i18n.t("readyTournament")}
          confirmText={i18n.t("invite")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          onConfirm={() => goToStep(stepsTournaments.successInviteTeams)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={true}
        />
      )}
      {currentStep === stepsTournaments.requestsSent && (
        <ModalHeaderComponent
          title={i18n.t("applicationSent")}
          subtitle={i18n.t("readyTournament")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTournaments.optionsSeason)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {/* Content for body modals */}
      {currentStep === stepsTournaments.default && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("profile")}
            </BodyText>
            <OptionCard
              title={i18n.t("profileTournament")}
              description={i18n.t("renewTournament")}
              icon={<ProfileCircle />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.editTournament)}
            />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("tournamentSeasons")}
            </BodyText>
            <Flex
              padding="0px 16px"
              borderRadius={16}
              gap={3}
              height="60px"
              alignItems="center"
              cursor="pointer"
              justifyContent="center"
              backgroundColor={colors.backgrounds.gray0}
              onClick={() => goToStep(stepsTournaments.newSeason)}
            >
              <IconAddSquare stroke={colors.text.lightGray3} />
              <BodyText
                fontSize={{ base: "12px", md: "14px" }}
                fontWeight="medium"
                textColor={colors.text.lightGray3}
              >
                {i18n.t("createNewSeason")}
              </BodyText>
            </Flex>
            {seasons.length > 0 ? (
              seasons.map((season) => (
                <Flex
                  padding="0px 16px"
                  borderRadius={16}
                  gap={3}
                  height="60px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor={colors.backgrounds.gray0}
                >
                  <Flex direction="column">
                    <BodyText
                      fontSize={{ base: "12px", md: "14px" }}
                      fontWeight="medium"
                      textColor={colors.text.black}
                    >
                      {season.name}
                    </BodyText>
                    <BodyText
                      fontSize={{ base: "11px", md: "12px" }}
                      fontWeight="semiBold"
                      textColor={colors.text.lightGray3} //Current season other color
                    >
                      {season.year}
                    </BodyText>
                  </Flex>
                  <Flex gap={3}>
                    <IconEdit
                      stroke={colors.figmaColors.secColor}
                      cursor="pointer"
                      onClick={() => {
                        setSelectedSeason(season);
                        goToStep(stepsTournaments.optionsSeason);
                      }}
                    />
                    <IconTrash
                      stroke={colors.backgrounds.red}
                      cursor="pointer"
                      onClick={() => {
                        setSelectedSeason(season);
                        goToStep(stepsTournaments.deleteSeason);
                      }}
                    />
                  </Flex>
                </Flex>
              ))
            ) : (
              <BodyText
                fontSize={{ base: "11px", md: "12px" }}
                fontWeight="semiBold"
                textColor={colors.text.black}
              >
                {i18n.t("noData")}
              </BodyText>
            )}
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("additionalSettings")}
            </BodyText>
            <OptionCard
              title={i18n.t("deleteTournament")}
              description={i18n.t("deleteTournamentData")}
              icon={<IconTrash stroke={colors.backgrounds.white} />}
              iconColor={colors.backgrounds.red}
              onClick={() => goToStep(stepsTournaments.deleteTournament)}
            />
          </Flex>
        </Flex>
      )}
      {currentStep === stepsTournaments.editTournament && (
        <CreateOrEditTournament
          isEdit={true}
          steps={stepsTournaments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successEdit && (
        <MessageConfirmationComponent
          title={i18n.t("tournamentUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.newSeason && (
        <CreateOrEditSeason
          isEdit={false}
          steps={stepsTournaments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successNewSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonCreated", { name: stepData.name })}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.optionsSeason && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("seasonOption")}
            </BodyText>
            <OptionCard
              title={i18n.t("seasonName")}
              description={i18n.t("updateSeasonName")}
              icon={<ProfileCircle />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.seasonName)}
            />
            <OptionCard
              title={i18n.t("culminateSeason")}
              description={i18n.t("chooseChampion")}
              icon={<FlagIcon />}
              iconColor={colors.backgrounds.black}
              onClick={() => goToStep(stepsTournaments.finishSeason)}
            />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("seasonAdjustments")}
            </BodyText>
            <OptionCard
              title={i18n.t("tournamentRules")}
              description={i18n.t("regulateRules")}
              icon={<RulerAndPenIcon />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.regulations)}
            />
            <OptionCard
              title={i18n.t("tournamentPhases")}
              description={i18n.t("createTournamentPhases")}
              icon={
                <IconGroup
                  width="24px"
                  height="24px"
                  stroke={colors.backgrounds.white}
                />
              }
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.modality)}
            />
            <OptionCard
              title={i18n.t("selectTeams")}
              description={i18n.t("teamEachPhases")}
              icon={
                <IconPeopleFC
                  fill={colors.backgrounds.white}
                  stroke={colors.backgrounds.white}
                />
              }
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.selectTeams)}
            />
            <OptionCard
              title={i18n.t("createMatches")}
              description={i18n.t("selectClashes")}
              icon={<FieldIcon />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.createMatches)}
            />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("inviteMoreTeams")}
            </BodyText>
            <OptionCard
              title={i18n.t("inviteTeams")}
              description={i18n.t("joinTeams")}
              icon={
                <IconPeopleFC
                  fill={colors.backgrounds.white}
                  stroke={colors.backgrounds.white}
                />
              }
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.inviteTeams)}
            />
            <OptionCard
              title={i18n.t("applicationSent")}
              description={i18n.t("checkStatusInvitations")}
              icon={<MessageSquareIcon />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTournaments.requestsSent)}
            />
          </Flex>
        </Flex>
      )}
      {currentStep === stepsTournaments.seasonName && (
        <CreateOrEditSeason
          isEdit={true}
          steps={stepsTournaments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedSeason={selectedSeason}
        />
      )}
      {currentStep === stepsTournaments.successSeasonName && (
        <MessageConfirmationComponent
          title={i18n.t("nameSeasonUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.finishSeason && null}
      {currentStep === stepsTournaments.successFinishSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonFinished")}
          middleTitle={i18n.t("haveChampion", { name: selectedSeason.name })}
          subtitle={i18n.t("seeNextSeason")}
          teamIcon={<Avatar boxSize="60px" />} //Set avatar
          teamName={selectedSeason.name} //Set correct name
          isDelete={false}
          isFinished={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.regulations && (
        <TournamentRegulations
          steps={stepsTournaments}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successSaveRegulations && (
        <MessageConfirmationComponent
          title={i18n.t("regulationsUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.modality && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <Flex
            padding="0px 16px"
            borderRadius={16}
            gap={3}
            height="60px"
            alignItems="center"
            cursor="pointer"
            justifyContent="center"
            backgroundColor={colors.backgrounds.gray0}
            onClick={() => goToStep(stepsTournaments.newPhase)}
          >
            <IconAddSquare stroke={colors.text.lightGray3} />
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("createNewPhase")}
            </BodyText>
          </Flex>
          {phases.length > 0 ? (
            phases.map((phase) => (
              <Flex
                padding="0px 16px"
                borderRadius={16}
                gap={3}
                height="60px"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid"
                borderColor={colors.backgrounds.gray1}
              >
                <Flex direction="column">
                  <BodyText
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight="medium"
                    textColor={colors.text.black}
                  >
                    {phase.name}
                  </BodyText>
                </Flex>
                <Flex gap={3}>
                  <IconEdit
                    stroke={colors.figmaColors.secColor}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedPhase(phase);
                      goToStep(stepsTournaments.editPhase);
                    }}
                  />
                  <IconTrash
                    stroke={colors.backgrounds.red}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedPhase(phase);
                      goToStep(stepsTournaments.deletePhase);
                    }}
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <BodyText
              fontSize={{ base: "11px", md: "12px" }}
              fontWeight="semiBold"
              textColor={colors.text.black}
            >
              {i18n.t("noData")}
            </BodyText>
          )}
        </Flex>
      )}
      {currentStep === stepsTournaments.newPhase && (
        <CreateOrEditPhase
          isEdit={false}
          steps={stepsTournaments}
          goToStep={goToStep}
          groups={groups}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.successNewPhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseCreated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.editPhase && (
        <CreateOrEditPhase
          isEdit={true}
          steps={stepsTournaments}
          goToStep={goToStep}
          groups={groups}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          setSelectedGroup={setSelectedGroup}
        />
      )}
      {currentStep === stepsTournaments.successEditPhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseEdited")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.deletePhase && (
        <DeletePhase goToStep={goToStep} selectedPhase={selectedPhase} />
      )}
      {currentStep === stepsTournaments.successDeletePhase && (
        <MessageConfirmationComponent
          title={i18n.t("phaseDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.createGroup && (
        <CreateOrEditGroup
          isEdit={false}
          steps={stepsTournaments}
          onBack={handleBack}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTournaments.updateGroup && (
        <CreateOrEditGroup
          isEdit={true}
          steps={stepsTournaments}
          onBack={handleBack}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
        />
      )}
      {currentStep === stepsTournaments.deleteGroup && (
        <DeleteGroup
          onBack={handleBack}
          selectedPhase={selectedPhase}
          selectedGroup={selectedGroup}
        />
      )}
      {currentStep === stepsTournaments.selectTeams && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          {phases.length > 0 ? (
            phases.map((phase) => (
              <OptionCard
                title={phase.name}
                description={i18n.t("teamsSelected", {
                  teams: phase.count_teams_by_group,
                  teamsLimit: phase.count_teams_by_group,
                })}
                arrowColor={colors.figmaColors.secColor}
                onClick={() => {
                  setSelectedPhase(phase);
                  goToStep(stepsTournaments.teamsSelection);
                }}
              />
            ))
          ) : (
            <BodyText
              fontSize={{ base: "11px", md: "12px" }}
              fontWeight="semiBold"
              textColor={colors.text.black}
            >
              {i18n.t("noData")}
            </BodyText>
          )}
        </Flex>
      )}
      {currentStep === stepsTournaments.createMatches && null}
      {currentStep === stepsTournaments.inviteTeams && null}
      {currentStep === stepsTournaments.successInviteTeams && (
        <MessageConfirmationComponent
          title={i18n.t("invitationSuccessfully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.requestsSent && null}
      {currentStep === stepsTournaments.deleteSeason && (
        <DeleteSeason
          goToStep={goToStep}
          selectedSeason={selectedSeason}
          setSeasons={setSeasons}
        />
      )}
      {currentStep === stepsTournaments.successDeleteSeason && (
        <MessageConfirmationComponent
          title={i18n.t("seasonDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTournaments.deleteTournament && (
        <DeleteContentModal
          message={i18n.t("sureDeleteTournament")}
          subMessage={i18n.t("allDeleteTournamentData")}
          onCancel={hideContainerModal}
          onConfirm={() => goToStep(stepsTournaments.sureDeletion)}
        />
      )}
      {currentStep === stepsTournaments.sureDeletion && (
        <DeleteTournament goToStep={goToStep} />
      )}
      {currentStep === stepsTournaments.successDeleteTournament && (
        <MessageConfirmationComponent
          title={i18n.t("tournamentDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
    </Box>
  );
}

export default UpdateOrViewTournamentData;
