import { useState } from "react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { updateTournament } from "../../../../../models/Tournament";
import SureDeleteContent from "../../../../../components/Modals/SureDeleteContent";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";

const DeleteTeam = ({
  goToStep,
}: {
  goToStep: (key: string) => void;
}): JSX.Element => {
  const { currentTournament, hideContainerModal, tournaments, setTournaments } =
    appStore((store) => store);
  const [loading, setLoading] = useState(false);

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      await updateTournament(currentTournament.id, { deleted: true });
      setTournaments(
        tournaments.filter(
          (tournament) => tournament.id !== currentTournament.id
        )
      );
      goToStep(stepsTournaments.successDeleteTournament);
    } catch (error) {
      console.error("Error deleting tournament:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SureDeleteContent
      title={i18n.t("enterTournamentName")}
      subtitle={i18n.t("tournamentSureDeleted")}
      textLabel={i18n.t("nameTournament")}
      textInput={i18n.t("nameTournament")}
      onCancel={hideContainerModal}
      onConfirm={onConfirmDelete}
      loading={loading}
      currentName={currentTournament.name.trim()}
    />
  );
};

export default DeleteTeam;
