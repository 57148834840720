import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import BodyText from "../../Common/BodyText";
import PrivacySwitch from "../../Common/PrivacySwitch";
import CitySelector from "../../Common/CitySelector";
import ImageUploader from "../../Common/ImageUploader";
import { colors } from "../../../definitions/constants/GlobalStyles";
import i18n from "../../../i18n/i18n";
import { createTeam } from "../../../models/TeamModel";
import { PlaceDetails } from "../../../definitions/interfaces/Place.interface";
import { appStore } from "../../../zustand/globalStore";
import "react-datepicker/dist/react-datepicker.css";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import MessageConfirmationComponent from "../../MessageConfirmationComponent";
// import "./styles/main.style.scss";
import {
  handleImageChange,
  consumeApi,
  createLocalityFromPlace,
  uploadFileToAmazonS3Web,
} from "../../../utils/tools";
import { steps } from "../Players/containers/steps.container";
import { DataformTeam } from "./Interfaces/DataFormPlayer.interface";
import DatePicker from "react-datepicker";
import SelectDate from "../../Common/SelectDate";
import ImageIdUploader from "../../Common/ImageIdUploader";
import { getUserByUsername } from "../../../models/UserModel";
import { v4 as uuidV4 } from 'uuid';
import { DeviceOs } from "../../../hooks/DeviceOs";
import { addPlayerToTeam } from "../../../models/Tournament";
import { User } from "../../../definitions/interfaces/Users/Users.interface";

const RegisterAndEditPlayer: React.FC<{
  action: string;
  goToStep: (key: string) => void;
  player?: User 
  // isSaving: boolean;
  // setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ action, goToStep, player }) => {
  const [place, setPlace] = useState<PlaceDetails | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {
    currentTeam,
    currentTournament,
    hideContainerModal,
    userData,
  } = appStore((state) => state);

  const dataFormInit = action === "edit"?{
    jerseyNumber: player.number,
    name: player.firstname,
    lastname: player.lastname,
    date: player.bith_date,
    email: player.email,
    direction: player.direction,
    city: "",
    mediaProfile: player.image_url,
    mediaId: player.image_dni,
  }:{
    jerseyNumber: "",
    name: "",
    lastname: "",
    date: "",
    email: "",
    direction: "",
    city: "",
    mediaProfile: "",
    mediaId: "",
  };
  const [formData, setFormData] = useState<DataformTeam>(dataFormInit);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [image, setImage] = useState(
    action === "edit" ? currentTeam.media_url : ""
  );
  const [imageId, setImageId] = useState("")
  const [mediaId, setMediaId] = useState<any>(null)
  useEffect(() => {
    if (action === "edit" && currentTeam?.private !== undefined) {
      setIsPrivate(currentTeam.private);
    }
  }, [action, currentTeam]);

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

    const handleInputIdImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImageId, setMediaId);
  };

  const onPressRegisterUser = async (data: any) => {
    try{
      const dataSave:any = {}
      dataSave.direction = data.direction;
      dataSave.email = data.email;
      if (place) {
          dataSave.location_data = createLocalityFromPlace(place)
      }
      if (data.birthDate) {
          dataSave.bith_date = data.birthDate 
      }
      // else {
      //     delete data.bith_date
      // }
      let username = `${data.name}${data.lastname}`
          .toLowerCase()
          .split(' ')
          .join('')
          .split('@')
          .join('');
      dataSave.number = data.jerseyNumber;
      const usersByUsername = await getUserByUsername(username);
          if (usersByUsername.length > 0) {
              username = `${username}${usersByUsername.length}`;
          }
          dataSave.username = username;
      if (media) {
        console.log(media, "**********MEDIA upload*************");
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        dataSave.image_url = response;
      }
      if (mediaId) {
        console.log(media, "**********MEDIA upload*************");
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        dataSave.image_dni = response;
      }
      let publicIp = await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => data.ip);
        dataSave.device_os = DeviceOs();
        dataSave.ip_address = publicIp;
        
      let createOrEditUser = null
      if(action === "edit"){
        delete data.email;
        delete dataSave.email;
        createOrEditUser = await consumeApi(`users/update_user`, 'POST', {
            user_id: player.id,
            fields_to_update: dataSave,
        });
      }else{
        dataSave.firebase_id = uuidV4().toString();
        createOrEditUser = await consumeApi(`users/create_user`, 'POST', dataSave);
      }
      console.log(createOrEditUser, "USER RESPONSE CREATE")
      if(createOrEditUser?.status === "success"){
        if(action === "create"){
          dataSave.id =  dataSave.firebase_id
          await addPlayerToTeam(userData, dataSave, currentTeam, currentTournament)
          goToStep(steps.successRegisterPlayer)
        }else{
          goToStep(steps.successEditPlayer)
        }
      }    
    }catch(e){
      console.error(e)
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
      { key: "email", value: formData.email },
      { key: "jerseyNumber", value: formData.jerseyNumber },
      { key: "lastname", value: formData.lastname },
      { key: "direction", value: formData.direction },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      setIsSaving(true)
      await onPressRegisterUser(formData);
      setIsSaving(false)
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Box>
          <Box
            as="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={"16px"}
            flexGrow={1}
          >
            {action === "edit" ? (
              <ModalHeaderComponent
                title={i18n.t("editPlayer")}
                subtitle={i18n.t("StartManagingTeam")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={() => goToStep(steps.default)}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
            ) : (
              <ModalHeaderComponent
                title={i18n.t("registerPlayers")}
                subtitle={i18n.t("get_ready_for_the_tournament")}
                hasMoreSteps={true}
                actionButtonsRequired={true}
                onBack={() => goToStep(steps.default)}
                onCancel={hideContainerModal}
                onConfirm={handleSubmit}
                confirmText={i18n.t("confirm")}
                isSaving={isSaving}
              />
            )}
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap={8}
              width={"100%"}
              height={"100%"}
            >
              <GridItem
                colSpan={1}
                display="flex"
              >
                <Flex 
                    direction={"column"} 
                    gap={"20px"} 
                >
                  <FormControl
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ImageUploader
                      image={formData.mediaProfile?formData.mediaProfile:image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                  <FormControl
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ImageIdUploader 
                      image={formData.mediaId?formData.mediaId:imageId}
                      onImageChange={handleInputIdImageChange}
                    />
                  </FormControl>
                </Flex>                
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <Flex gap={2.5} flexDirection={"column"}>
                    {/* INPUT JERSEY */}
                    <Flex gap={1} flexDirection={"column"}>
                      <Flex gap={1} alignItems="center">
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("jersey")}
                        </FormLabel>
                        <BodyText
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.lightGray3}
                        >
                          {i18n.t("required")}
                        </BodyText>
                      </Flex>
                      <Input
                        type="number"
                        {...inputStyle}
                        placeholder={i18n.t("jerseyNumber") + "..."}
                        value={formData.jerseyNumber}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            jerseyNumber: e.target.value,
                          }));
                          setErrors((prev) => ({ ...prev, jerseyNumber: "" }));
                        }}
                      />
                      {errors.jerseyNumber && (
                        <BodyText
                          paddingLeft={4}
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.red}
                        >
                          {errors.jerseyNumber}
                        </BodyText>
                      )}
                    </Flex>
                    {/* INPUT NAME */}
                    <Flex gap={1} flexDirection={"column"}>
                      <Flex gap={1} alignItems="center">
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("name")}
                        </FormLabel>
                        <BodyText
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.lightGray3}
                        >
                          {i18n.t("required")}
                        </BodyText>
                      </Flex>
                      <Input
                        type="text"
                        {...inputStyle}
                        placeholder={i18n.t("name") + "..."}
                        value={formData.name}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                          setErrors((prev) => ({ ...prev, nombre: "" }));
                        }}
                      />
                      {errors.name && (
                        <BodyText
                          paddingLeft={4}
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.red}
                        >
                          {errors.name}
                        </BodyText>
                      )}
                    </Flex>
                    {/* INPUT LASTNAME */}
                    <Flex gap={1} flexDirection={"column"}>
                      <Flex gap={1} alignItems="center">
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("lastName")}
                        </FormLabel>
                        <BodyText
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.lightGray3}
                        >
                          {i18n.t("required")}
                        </BodyText>
                      </Flex>
                      <Input
                        type="text"
                        {...inputStyle}
                        placeholder={i18n.t("lastName") + "..."}
                        value={formData.lastname}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            lastname: e.target.value,
                          }));
                          setErrors((prev) => ({ ...prev, lastname: "" }));
                        }}
                      />
                      {errors.lastname && (
                        <BodyText
                          paddingLeft={4}
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.red}
                        >
                          {errors.lastname}
                        </BodyText>
                      )}
                    </Flex>
                    {/* INPUT DATE PICKER */}
                    <SelectDate
                      date={birthDate}
                      error={errors.birthDate}
                      setDate={setBirthDate}
                      label="birthDate"
                      placeholder="birthDate"
                      required={true}
                    />
                  </Flex>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <Flex gap={2.5} flexDirection={"column"}>
                    {/* INPUT JERSEY */}
                    <Flex gap={1} flexDirection={"column"}>
                      <Flex gap={1} alignItems="center">
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("email")}
                        </FormLabel>
                        <BodyText
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.lightGray3}
                        >
                          {i18n.t("required")}
                        </BodyText>
                      </Flex>
                      <Input
                        type="email"
                        {...inputStyle}
                        placeholder={i18n.t("email") + "..."}
                        value={formData.email}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                          setErrors((prev) => ({ ...prev, email: "" }));
                        }}
                      />
                      {errors.email && (
                        <BodyText
                          paddingLeft={4}
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.red}
                        >
                          {errors.email}
                        </BodyText>
                      )}
                    </Flex>
                    {/* INPUT NAME */}
                    <Flex gap={1} flexDirection={"column"}>
                      <Flex gap={1} alignItems="center">
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("direction")}
                        </FormLabel>
                        <BodyText
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.lightGray3}
                        >
                          {i18n.t("required")}
                        </BodyText>
                      </Flex>
                      <Input
                        type="text"
                        {...inputStyle}
                        placeholder={i18n.t("direction") + "..."}
                        value={formData.direction}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            direction: e.target.value,
                          }));
                          setErrors((prev) => ({ ...prev, direction: "" }));
                        }}
                      />
                      {errors.direction && (
                        <BodyText
                          paddingLeft={4}
                          fontSize={{ base: "12px", md: "13px" }}
                          fontWeight="regular"
                          textColor={colors.text.red}
                        >
                          {errors.direction}
                        </BodyText>
                      )}
                    </Flex>
                    {/* INPUT PLACE PICKER CITY */}
                    <CitySelector
                      error={errors.city}
                      place={place}
                      setPlace={setPlace}
                    />
                  </Flex>
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RegisterAndEditPlayer;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
