import React, { useRef } from "react";
import {
  Flex,
  Input,
  InputGroup,
  FormLabel,
  InputRightElement,
} from "@chakra-ui/react";
import i18n from "../../i18n/i18n";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "./BodyText";
import { Autocomplete, Libraries, LoadScript } from "@react-google-maps/api";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";

interface CitySelectorProps {
  label?: string;
  error?: string;
  placeholder?: string;
  place: any;
  setPlace: (place: any) => void;
}

const CitySelector: React.FC<CitySelectorProps> = ({
  label = i18n.t("city"),
  error,
  placeholder = i18n.t("selectCity"),
  place,
  setPlace,
}) => {
  const libraries: Libraries = ["places"];
  const autocompleteRef = useRef(null);
  const GOOGLE_MAPS_API_KEY = "AIzaSyABQS7gwV97AAxkWXZaTf3EVWGKIPgF9nk";

  const handlePlaceChanged = () => {
    const address = autocompleteRef.current.getPlace();
    if (address && address.formatted_address) {
      setPlace(address);
    }
  };

  return (
    <Flex gap={0.5} flexDirection={"column"}>
      <Flex gap={1} alignItems="center">
        <FormLabel {...formLabelStyle}>{label}</FormLabel>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("required")}
        </BodyText>
      </Flex>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <InputGroup>
            <Input
              type="text"
              {...inputStyle}
              placeholder={placeholder}
              defaultValue={place?.description || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setPlace(null);
                } else {
                  setPlace({ ...place, description: value });
                }
              }}
            />
            <InputRightElement
              top="50%"
              right="16px"
              width={"fit-content"}
              transform="translateY(-50%) rotate(90deg)"
              pointerEvents= "none"
            >
              <IconArrowLinear stroke={colors.text.lightGray3} />
            </InputRightElement>
          </InputGroup>
        </Autocomplete>
      </LoadScript>
      {error && (
        <BodyText
          paddingLeft={4}
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.red}
        >
          {error}
        </BodyText>
      )}
    </Flex>
  );
};

export default CitySelector;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};

const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
