import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { colors } from "../definitions/constants/GlobalStyles";

const HomeAuthenticated: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      borderRadius={24}
      bg={colors.text.white}
      height={{
        base: `calc(100vh - 88px)`,
        sm: "calc(100vh - 96px)",
        md: `calc(100vh - 104px)`,
        lg: `calc(100vh - 112px)`,
      }}
      maxHeight={{
        base: `calc(100vh - 88px)`,
        sm: "calc(100vh - 96px)",
        md: `calc(100vh - 104px)`,
        lg: "calc(100vh - 112px)",
      }}
      overflow="hidden"
      shadow={"0px 4px 20px 0px rgba(0, 0, 0, 0.07)"}
    >
      <Flex
        flex="1"
        gap={4}
        flexDirection={"column"}
        overflowY="auto"
        p={{
          base: "12px 16px",
          lg: "16px 24px",
          xl: "24px 32px",
        }}
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
            backgroundColor: colors.backgrounds.gray0,
          },
          "&::-webkit-scrollbar-thumb": {
            background: colors.figmaColors.secColor,
            borderRadius: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: colors.backgrounds.gray0,
          },
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default HomeAuthenticated;
