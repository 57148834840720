import { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import BodyText from "../Common/BodyText";
import PrivacySwitch from "../Common/PrivacySwitch";
import CitySelector from "../Common/CitySelector";
import ImageUploader from "../Common/ImageUploader";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  handleImageChange,
  uploadFileToAmazonS3Web,
  createLocalityFromPlace,
} from "../../utils/tools";
import { createTeam } from "../../models/TeamModel";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";
import { appStore } from "../../zustand/globalStore";
import { dataForm } from "./types/dataForm.interface";
import ModalHeaderComponent from "../Common/ModalHeaderComponent";
import ToggleQuestion from "../Shared/ToggleQuestion";
import "./styles/main.style.scss";
import MessageConfirmationComponent from "../../components/MessageConfirmationComponent";

const CreateAndEditTeam: React.FC<{
  action: string;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ action, steps, goToStep, currentStep, isSaving, setIsSaving }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);

  const {
    currentTeam,
    hideContainerModal,
    userData,
    setCurrentTeam,
    teams,
    setTeams,
  } = appStore((state) => state);

  const dataFormInit = {
    name: action === "edit" ? currentTeam.name : "",
    address: action === "edit" ? currentTeam.address : "",
    email: action === "edit" ? currentTeam.email : "",
    phone: action === "edit" ? currentTeam.phone : "",
    private: action === "edit" ? currentTeam.private : "",
    automaticallyAcceptInvitations:
      action === "edit" ? currentTeam.automaticallyAcceptInvitations : false,
  };
  const [formData, setFormData] = useState<dataForm>(dataFormInit);
  const [place, setPlace] = useState<PlaceDetails | null>(
    action === "edit" ? currentTeam.place : null
  );
  const [image, setImage] = useState(
    action === "edit" ? currentTeam.media_url : ""
  );
  const [isPrivate, setIsPrivate] = useState(
    action === "edit" ? currentTeam.private : false
  );

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const handleSwitchChange = () => {
    setIsPrivate(!isPrivate);
  };

  const onPressCreateTeam = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      data.name = data.name.toUpperCase();
      data.locality =
        action === "edit" &&
        place?.description === currentTeam?.place?.description
          ? currentTeam.place
          : createLocalityFromPlace(place);
      data.place = data.locality;
      data.private = isPrivate;
      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "teams"
        );
        data.media_url = response;
      } else if (image) {
        data.media_url = currentTeam.media_url;
      }

      if (action === "edit") {
        data.id = currentTeam.id;
      }

      let teamCreated = await createTeam(userData.id, data);
      setCurrentTeam(teamCreated);

      if (action === "edit") {
        setTeams(
          teams.map((team) => (team.id === teamCreated.id ? teamCreated : team))
        );
        goToStep(steps.successEdit);
      } else {
        setTeams([teamCreated, ...teams]);
        goToStep(steps.successScreen);
      }
    } catch (error) {
      console.error("Error creating team:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateTeam(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {(action === "edit"
            ? currentStep === steps.editTeam
            : currentStep === steps.default) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
              flexGrow={1}
            >
              {action === "edit" ? (
                <ModalHeaderComponent
                  title={i18n.t("yourTeam")}
                  subtitle={i18n.t("StartManagingTeam")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.default)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("titleCreateTeam")}
                  subtitle={i18n.t("msgCreateTeam")}
                  confirmText={i18n.t("createTeam")}
                  onCancel={hideContainerModal}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  actionButtonsRequired={true}
                />
              )}

              {action === "edit" && (
                <ToggleQuestion
                  title={i18n.t("automaticallyAcceptInvitations")}
                  subTitle={i18n.t(
                    "you_will_be_automatically_added_to_a_tournament"
                  )}
                  value={formData.automaticallyAcceptInvitations}
                  onChange={() => {
                    setFormData((prev) => ({
                      ...prev,
                      automaticallyAcceptInvitations:
                        !prev.automaticallyAcceptInvitations,
                    }));
                  }}
                />
              )}

              <Grid templateColumns="200px 1fr 1fr" gap={8}>
                <GridItem
                  colSpan={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControl>
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("nameTeam")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("nameTeam") + "..."}
                          value={formData.name}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                            setErrors((prev) => ({ ...prev, name: "" }));
                          }}
                        />
                        {errors.name && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.name}
                          </BodyText>
                        )}
                      </Flex>
                      {/*Use CustomCitySelector instead CitySelector to show custom city list*/}
                      <CitySelector
                        error={errors.city}
                        place={place}
                        setPlace={setPlace}
                      />
                    </Flex>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("cellPhone")}
                        </FormLabel>
                        <Input
                          type="tel"
                          {...inputStyle}
                          placeholder={i18n.t("cellPhone") + "..."}
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("email")}
                        </FormLabel>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("email") + "..."}
                          value={formData.email}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("address")}
                        </FormLabel>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("address") + "..."}
                          value={formData.address}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              address: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                    </Flex>
                  </FormControl>
                </GridItem>
              </Grid>
              <PrivacySwitch
                isPrivate={isPrivate}
                isPrivateText={i18n.t("createTeamPrivate")}
                onSwitchChange={handleSwitchChange}
              />
            </Box>
          )}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("teamReady")}
              subtitle={i18n.t("messageAlert")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateAndEditTeam;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
