import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import { trackEvent } from "../../../utils/tools";

export default function CupViewComponent() {
  const { currentCup } = appStore((state) => state);
  console.log(currentCup);

  useEffect(() => {
    trackEvent("CupViewComponent", {});
  }, []);

  if (!currentCup?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_cup_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return <Flex sx={styles.container}></Flex>;
}

const styles = {
  container: {
    gap: 3,
    flexDirection: "column",
  },
};
