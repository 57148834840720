import { create } from "zustand";
import { TypeViewToShow } from "../definitions/enums/GlobalEnums";

// ***** PAGE SETUP ***** //

export interface matchStoreState {
  currentMatch: any;
  setCurrentMatch: (match: any) => void;

  incidences: any[];
  setIncidences: (incidences: any[]) => void;

  timePlayed: {
    labelData: string;
    timePlayed: number;
  };
  setTimePlayed: (labelData: string, timePlayed: number) => void;
}

export const matchStore = create<matchStoreState>((set) => ({
  currentMatch: null,
  setCurrentMatch: (match: any) => set({ currentMatch: match }),

  incidences: [],
  setIncidences: (incidences: any[]) => set({ incidences: incidences }),

  timePlayed: {
    labelData: "00:00",
    timePlayed: 0,
  },
  setTimePlayed: (labelData: string, timePlayed: number) =>
    set({ timePlayed: { labelData, timePlayed } }),
}));

export interface appStoreState {
  userData: any | null;
  setUserData: (user: any) => void;

  matchId: any | null;
  setMatchId: (team: any) => void;

  isSideMenuOpen: boolean;
  setIsSideMenuOpen: (isOpen: boolean) => void;

  currentTournament: any;
  setCurrentTournament: (team: any) => void;

  tournaments: Array<any>;
  setTournaments: (data: Array<any>) => void;

  currentView: {};
  setCurrentView: (view: any) => void;

  currentAlertView: {};
  setCurrentAlertView: (view: any) => void;

  currentTeam: any;
  setCurrentTeam: (team: any) => void;

  teams: Array<any>;
  setTeams: (data: Array<any>) => void;

  currentCup: any;
  setCurrentCup: (team: any) => void;

  showTopBar: boolean;
  setShowTopBar: (show: boolean) => void;

  showBottomBar: boolean;
  setShowBottomBar: (show: boolean) => void;

  postsFeed: any[];
  setPostsFeed: (posts: any[]) => void;

  pushToken: string | null;
  setPushToken: (token: string) => void;

  showLoadingScreen: boolean | null;
  setShowLoadingScreen: (show: boolean) => void;

  activeButton: string | null;
  setActiveButton: (show: string) => void;

  activeCardIndex: number;
  setActiveCardIndex: (index: number) => void;

  activeDataKey: string;
  setActiveDataKey: (key: string) => void;

  isContainerModalOpen: boolean | false;
  showContainerModal: (view: TypeViewToShow, viewParams?: any) => void;
  hideContainerModal: () => void;
}

export const appStore = create<appStoreState>((set) => ({
  userData: null,
  setUserData: (user: any) => {
    set({ userData: user });
  },

  matchId: null,
  setMatchId: (id: any) => set({ matchId: id }),

  isSideMenuOpen: false,
  setIsSideMenuOpen: (isOpen: boolean) => set({ isSideMenuOpen: isOpen }),

  currentTournament: null,
  setCurrentTournament: (tournament: any) =>
    set({ currentTournament: tournament }),

  tournaments: [],
  setTournaments: (data: Array<any>) => set({ tournaments: data }),

  currentAlertView: null,
  setCurrentAlertView: (view: any) => set({ currentAlertView: view }),

  currentView: null,
  setCurrentView: (view: any) => set({ currentView: view }),

  currentTeam: null,
  setCurrentTeam: (team: any) => set({ currentTeam: team }),

  teams: [],
  setTeams: (data: Array<any>) => set({ teams: data }),

  currentCup: null,
  setCurrentCup: (cup: any) => set({ currentCup: cup }),

  showTopBar: true,
  setShowTopBar: (show: boolean) => set({ showTopBar: show }),

  showBottomBar: true,
  setShowBottomBar: (show: boolean) => set({ showBottomBar: show }),

  postsFeed: [],
  setPostsFeed: (posts: any[]) =>
    set({
      postsFeed: posts,
    }),

  pushToken: null,
  setPushToken: (token: string) => set({ pushToken: token }),

  showLoadingScreen: null,
  setShowLoadingScreen: (show: boolean) => set({ showLoadingScreen: show }),

  activeButton: "matches",
  setActiveButton: (button: any) => set({ activeButton: button }),

  activeCardIndex: 0,
  setActiveCardIndex: (index: number) => set({ activeCardIndex: index }),

  activeDataKey: "week",
  setActiveDataKey: (key: string) => set({ activeDataKey: key }),

  isContainerModalOpen: false,
  showContainerModal: (view: TypeViewToShow) => {
    set({ isContainerModalOpen: true, currentView: view });
  },
  hideContainerModal: () => {
    set({ isContainerModalOpen: false, currentView: null });
    return;
  },
}));

type PostStoreState = {
  isCreatingPost: boolean;
  percentageUploaded: number;
  postCreating: any;
  existErrorCreatingPost: boolean;
  setExistErrorCreatingPost: (existError: boolean) => void;
  showCommentsModal: boolean;
  setShowCommentsModal: (show: boolean) => void;
  selectedPost: any;
  setSelectedPost: (post: any) => void;
};

export const postStore = create<PostStoreState>((set) => ({
  isCreatingPost: false,
  percentageUploaded: 0,
  postCreating: null,
  existErrorCreatingPost: false,
  setExistErrorCreatingPost: (existError: boolean) =>
    set({ existErrorCreatingPost: existError }),
  showCommentsModal: false,
  setShowCommentsModal: (show: boolean) => set({ showCommentsModal: show }),
  selectedPost: null,
  setSelectedPost: (post: any) => set({ selectedPost: post }),
}));
