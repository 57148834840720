import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Avatar } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import i18n from "../../../i18n/i18n";

const TeamDetailCard: React.FC<{ teams: any[] }> = ({ teams }) => {
  return (
    <Flex gap={1.5} flexDirection={"column"}>
      {teams.map((team, index) => (
        <Flex
          key={index}
          flexDirection={"column"}
          align="center"
          borderRadius={24}
          gap={2.5}
          padding={5}
          border="1px solid"
          borderColor={colors.figmaColors.grey1}
          bg={colors.figmaColors.grey0}
          cursor="pointer"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex alignItems="center">
              <Box
                onClick={() => {
                  // console.log("tournament.id", tournament.id)
                  // navigate(`/tournament/${tournament.id}`);
                }}
                display="flex"
                boxSize="60px"
                borderRadius="100%"
                border={`2px solid ${colors.text.white}`}
                borderColor={colors.text.white}
                background={colors.text.white}
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  width="100%"
                  height="100%"
                  src={team.media_url}
                  name={`${team.name ?? ""} `}
                />
              </Box>
              <Box
                marginLeft={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <BodyText
                  size="small"
                  fontWeight="regular"
                  color={colors.text.black}
                >
                  {team.name}
                </BodyText>
                <Flex alignItems="center">
                  <Image src={team.image_country} height="16px" width="21px" />
                  <BodyText
                    color={colors.text.lightGray3}
                    size="xxSmall"
                    fontWeight="regular"
                    marginLeft={2}
                  >
                    {team.name}
                  </BodyText>
                </Flex>
              </Box>
            </Flex>

            <Button
              borderRadius={10}
              marginLeft={2}
              bg={colors.figmaColors.grey0}
              color={colors.figmaColors.secColor}
              border="1px solid"
              borderColor={colors.figmaColors.secColor}
              fontSize="13px"
              fontWeight={"600"}
              _hover={{
                color: colors.backgrounds.white,
                bg: colors.figmaColors.secColor,
                borderColor: "blue.600",
              }}
              _active={{
                bg: colors.figmaColors.secColor,
                borderColor: "blue.700",
              }}
              onClick={() => {
                console.log("Action select team");
              }}
            >
              {i18n.t("seeInformation")}
            </Button>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default TeamDetailCard;
