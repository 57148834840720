import React, { useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import SureDeleteContent from "../Modals/SureDeleteContent";
import MessageConfirmationComponent from "../MessageConfirmationComponent";
import i18n from "../../i18n/i18n";
import { deleteMatch, getAllOwnMatchByUser } from "../../models/MatchModel";
import { cancelIndexTeam, getTeamsOwnByUser } from "../../models/TeamModel";
import { cancelIndexSearchUserByUserId } from "../../models/UserModel";
import { consumeApi } from "../../utils/tools";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { v4 as uuidV4 } from "uuid";
import { FirebaseError } from "firebase/app";

const steps = {
  default: "default",
  successAction: "successAction",
};

function SureDeleteAccountView() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const { userData, setUserData, hideContainerModal } = appStore(
    (state) => state
  );

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const onConfirm = async () => {
    try {
      setIsSaving(true);
      let matches = await getAllOwnMatchByUser(userData?.id);
      for (let match of matches) {
        deleteMatch(match.id);
      }
      let teams = await getTeamsOwnByUser(userData?.id);
      for (let team of teams) {
        cancelIndexTeam(team.id);
      }
      cancelIndexSearchUserByUserId(userData?.id);
      consumeApi(`users/update_user`, "POST", {
        user_id: userData?.id,
        fields_to_update: { username: "removeduser_" + uuidV4.toString() },
      });
      if (auth.currentUser) {
        await auth.currentUser.delete();
      }

      await handleSignOut();
      goToStep(steps.successAction);
    } catch (error) {
      console.error("Error deleting user account:", error);

      if (
        error instanceof FirebaseError &&
        error.code === "auth/requires-recent-login"
      ) {
        toast({
          title: i18n.t("deleteAccountError"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        navigate("/login");
      } else {
        toast({
          title: i18n.t("unexpectedError"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} gap={8}>
      {currentStep === steps.default && (
        <SureDeleteContent
          title={i18n.t("sureDeleteAccount")}
          subtitle={i18n.t("informationDeletedEmail")}
          textLabel={i18n.t("email")}
          textInput={i18n.t("email")}
          onCancel={hideContainerModal}
          onConfirm={onConfirm}
          loading={isSaving}
          cancelRequired={true}
          currentName={userData.email.trim()} //Review this value
        />
      )}
      {currentStep === steps.successAction && (
        <MessageConfirmationComponent
          title={i18n.t("accountDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
    </Box>
  );
}

export default SureDeleteAccountView;
