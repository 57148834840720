import React from "react";

const IconNotification = ({
  fill = "none",
  stroke = "currentColor",
  width = "16",
  height = "16",
  strokeWidth = "0.5",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.51339 1.93945C6.30673 1.93945 4.51339 3.73279 4.51339 5.93945V7.86612C4.51339 8.27279 4.34006 8.89279 4.13339 9.23945L3.36673 10.5128C2.89339 11.2995 3.22006 12.1728 4.08673 12.4661C6.96006 13.4261 10.0601 13.4261 12.9334 12.4661C13.7401 12.1995 14.0934 11.2461 13.6534 10.5128L12.8867 9.23945C12.6867 8.89279 12.5134 8.27279 12.5134 7.86612V5.93945C12.5134 3.73945 10.7134 1.93945 8.51339 1.93945Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M9.74645 2.13297C9.53979 2.07297 9.32645 2.0263 9.10645 1.99964C8.46645 1.91964 7.85312 1.9663 7.27979 2.13297C7.47312 1.63964 7.95312 1.29297 8.51312 1.29297C9.07312 1.29297 9.55312 1.63964 9.74645 2.13297Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5132 12.707C10.5132 13.807 9.61318 14.707 8.51318 14.707C7.96652 14.707 7.45985 14.4804 7.09985 14.1204C6.73985 13.7604 6.51318 13.2537 6.51318 12.707"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default IconNotification;
