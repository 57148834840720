import { Box, Flex, Button } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../../components/Common/BodyText";
import { CloseCircleIcon } from "../../../definitions/constants/IconsComponent";

function SetupRulesForTournamentView() {
  const { hideContainerModal } = appStore((state) => state);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={hideContainerModal}
          p={0}
          height={"auto"}
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        alignSelf="stretch"
        gap={2}
        flexGrow={1}
      >
        <BodyText
          fontSize={{ base: "20px", md: "28px" }}
          fontWeight="light"
          textColor={colors.text.black}
        >
          Working in this section (SetupRulesForTournamentView)
        </BodyText>
      </Flex>
    </Box>
  );
}

export default SetupRulesForTournamentView;
