import { useEffect, useState } from "react";
import {
  useMediaQuery,
  Box,
  Flex,
  SkeletonText,
  Skeleton,
} from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import {
  PostApiResponse,
  PostData,
} from "../../definitions/interfaces/GlobalInterfaces";
import i18n from "../../i18n/i18n";
import { consumeApi, languageTag } from "../../utils/tools";
import AuthorInfoComponent from "./AuthorInfoComponent";
import MediaComponent from "./MediaComponent";
import TextComponent from "./TextComponent";
import SendCommentsComponent from "./SendCommentsComponent";
import ShareCountComponent from "./ShareCountComponent";
import CommentsListComponent from "./CommentsListComponent";
import i18next from "i18next";

function PostComponent() {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [apiResponse, setApiResponse] = useState<PostApiResponse>();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);

  let firstLoad = false;

  let requestBody = {
    page: page,
    user_id: "anonymous",
    language:{
      languageCode: i18next.language,
      languageTag: languageTag[i18n.language]
    }
  };
  
  const fetchData = async () => {
    setLoader(true);
    try {
      console.log({
        languageCode: i18next.language,
        languageTag: languageTag[i18n.language]
      })
      let data = await consumeApi(`posts/get_posts`, "POST", requestBody);
      data = data.data.filter((data: any) => !!data?.local_media);

      setTimeout(() => {
        setApiResponse((prevState) => ({
          ...data,
          data: [...(prevState?.data || []), ...data],
        }));
        setLoader(false);
      }, 1000);
    } catch (error) {
    } finally {
      if (page <= 1) {
        firstLoad = true;
      }
    }
  };

  useEffect(() => {
    if (page < 4) {
      fetchData();
    }
  }, [page]);

  const nextPage = async () => {
    if (
      firstLoad &&
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
    ) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", nextPage);
    return () => window.removeEventListener("scroll", nextPage);
  }, []);

  return (
    <>
      {apiResponse &&
        apiResponse.data.map((post: PostData, index: number) => (
          <Box
            key={index}
            marginBottom={6}
            marginTop={index === 0 && isMobile ? 6 : 6}
            p={isMobile ? "0" : "4"}
            bg={isMobile ? "transparent" : colors.text.white}
            borderRadius={isMobile ? "0" : "20px"}
            width={"100%"}
            boxShadow="sm"
            //maxW={"790px"}
          >
            <Flex gap={4} wrap={!isMobile ? "nowrap" : "wrap"}>
              {!isMobile ? (
                <Box flex="55%">
                  <MediaComponent post={post} />
                </Box>
              ) : null}

              <Box
                flex={!isMobile ? "45%" : "100%"}
                position="relative"
                minHeight="400px"
              >
                <Box marginBottom={2}>
                  <AuthorInfoComponent post={post} />
                </Box>

                {isMobile ? (
                  <Box marginBottom={2} width="100%">
                    <MediaComponent post={post} />
                  </Box>
                ) : null}

                <Box marginBottom={2}>
                  <ShareCountComponent post={post} />
                </Box>

                <Box
                  paddingBottom={2}
                  borderBottom={`1px solid ${colors.figmaColors.grey0}`}
                >
                  <TextComponent post={post} />
                </Box>

                <Box marginTop={4}>
                  {post?.comments?.length > 0 ? (
                    <CommentsListComponent post={post} />
                  ) : (
                    <Box> {i18n.t("no comments")} </Box>
                  )}
                </Box>

                <Box
                  marginBottom={2}
                  position={isMobile ? "relative" : "absolute"}
                  bottom="0"
                  width="100%"
                >
                  <SendCommentsComponent />
                </Box>
              </Box>
            </Flex>
          </Box>
        ))}

      {loader ? (
        <Box
          display="flex"
          gap="10px"
          width="100%"
          flexDirection="column"
          marginTop={isMobile ? "0" : "25px"}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <Flex
              padding="6"
              boxShadow="sm"
              width="100%"
              display="flex"
              key={index}
              gap="10px"
              borderRadius={"20px"}
              bg={isMobile ? "transparent" : colors.text.white}
              direction={isMobile ? "column" : "row"}
              marginBottom={4}
            >
              <Box width={"100%"}>
                <Skeleton height="300px" borderRadius="20px" width="100%" />
              </Box>
              <Box
                width={"100%"}
                display="flex"
                flexDirection="column"
                justifyContent="start"
              >
                <SkeletonText width="20%" noOfLines={1} marginBottom={"10px"} />
                <SkeletonText noOfLines={4} />
              </Box>
            </Flex>
          ))}
        </Box>
      ) : null}
    </>
  );
}

export default PostComponent;
