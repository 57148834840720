import React from "react";

const IconPeopleFC = ({
  fill = "none",
  stroke = "currentColor",
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="PeopleFC">
      <g id="people">
        <g id="Group">
          <path
            id="Vector"
            d="M18.3586 9.92402C18.3129 9.91641 18.2597 9.91641 18.2141 9.92402C17.1646 9.88599 16.3281 9.02666 16.3281 7.96201C16.3281 6.87454 17.2027 6 18.2901 6C19.3776 6 20.2521 6.88214 20.2521 7.96201C20.2445 9.02666 19.408 9.88599 18.3586 9.92402Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M17.5736 15.4602C18.6154 15.6351 19.7637 15.4526 20.5698 14.9127C21.6421 14.1978 21.6421 13.0267 20.5698 12.3119C19.7561 11.7719 18.5926 11.5894 17.5508 11.7719"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_3"
            d="M6.01857 9.92402C6.06419 9.91641 6.11743 9.91641 6.16305 9.92402C7.2125 9.88599 8.04902 9.02666 8.04902 7.96201C8.04902 6.87454 7.17448 6 6.08701 6C4.99954 6 4.125 6.88214 4.125 7.96201C4.1326 9.02666 4.96912 9.88599 6.01857 9.92402Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_4"
            d="M6.80044 15.4602C5.7586 15.6351 4.61029 15.4526 3.80419 14.9127C2.73194 14.1978 2.73194 13.0267 3.80419 12.3119C4.6179 11.7719 5.78141 11.5894 6.82325 11.7719"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_5"
            d="M11.9981 14.63C11.9381 14.62 11.8681 14.62 11.8081 14.63C10.4281 14.58 9.32812 13.45 9.32812 12.05C9.32812 10.62 10.4781 9.46997 11.9081 9.46997C13.3381 9.46997 14.4881 10.63 14.4881 12.05C14.4781 13.45 13.3781 14.59 11.9981 14.63Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_6"
            d="M9.08875 17.78C7.67875 18.72 7.67875 20.26 9.08875 21.2C10.6888 22.27 13.3087 22.27 14.9087 21.2C16.3187 20.26 16.3187 18.72 14.9087 17.78C13.3187 16.72 10.6888 16.72 9.08875 17.78Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <g id="FC">
        <path
          id="Vector_7"
          d="M11.8867 2.52C11.8867 2.03 11.9847 1.59367 12.1807 1.211C12.3814 0.828333 12.6661 0.532 13.0347 0.322C13.4034 0.107333 13.8374 0 14.3367 0C14.7661 0 15.1487 0.0816667 15.4847 0.245C15.8254 0.403667 16.1007 0.63 16.3107 0.924C16.5254 1.218 16.6677 1.561 16.7377 1.953H15.0997C15.0251 1.78967 14.9177 1.66367 14.7777 1.575C14.6424 1.48167 14.4861 1.435 14.3087 1.435C14.0474 1.435 13.8397 1.533 13.6857 1.729C13.5364 1.925 13.4617 2.18867 13.4617 2.52C13.4617 2.85133 13.5364 3.115 13.6857 3.311C13.8397 3.507 14.0474 3.605 14.3087 3.605C14.4861 3.605 14.6424 3.56067 14.7777 3.472C14.9177 3.37867 15.0251 3.25033 15.0997 3.087H16.7377C16.6677 3.479 16.5254 3.822 16.3107 4.116C16.1007 4.41 15.8254 4.63867 15.4847 4.802C15.1487 4.96067 14.7661 5.04 14.3367 5.04C13.8374 5.04 13.4034 4.935 13.0347 4.725C12.6661 4.51033 12.3814 4.21167 12.1807 3.829C11.9847 3.44633 11.8867 3.01 11.8867 2.52Z"
          fill={fill}
        />
        <path
          id="Vector_8"
          d="M11.5 0.0489502V1.28095H9.554V1.96695H10.954V3.13595H9.554V5.00495H8V0.0489502H11.5Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default IconPeopleFC;
