import React from "react";

const IconGroup = ({
  fill = "none",
  stroke = "currentColor",
  width = "16",
  height = "16",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group">
      <path
        id="Vector"
        d="M4.00016 7.49967C4.00016 7.8533 3.85969 8.19243 3.60964 8.44248C3.35959 8.69253 3.02045 8.83301 2.66683 8.83301C2.31321 8.83301 1.97407 8.69253 1.72402 8.44248C1.47397 8.19243 1.3335 7.8533 1.3335 7.49967C1.3335 7.14605 1.47397 6.80691 1.72402 6.55687C1.97407 6.30682 2.31321 6.16634 2.66683 6.16634C3.02045 6.16634 3.35959 6.30682 3.60964 6.55687C3.85969 6.80691 4.00016 7.14605 4.00016 7.49967ZM4.00016 7.49967H6.00016C6.17697 7.49967 6.34654 7.56991 6.47157 7.69494C6.59659 7.81996 6.66683 7.98953 6.66683 8.16634V12.1663C6.66683 12.3432 6.59659 12.5127 6.47157 12.6377C6.34654 12.7628 6.17697 12.833 6.00016 12.833H4.00016M4.00016 12.833C4.00016 13.1866 3.85969 13.5258 3.60964 13.7758C3.35959 14.0259 3.02045 14.1663 2.66683 14.1663C2.31321 14.1663 1.97407 14.0259 1.72402 13.7758C1.47397 13.5258 1.3335 13.1866 1.3335 12.833C1.3335 12.4794 1.47397 12.1402 1.72402 11.8902C1.97407 11.6402 2.31321 11.4997 2.66683 11.4997C3.02045 11.4997 3.35959 11.6402 3.60964 11.8902C3.85969 12.1402 4.00016 12.4794 4.00016 12.833ZM1.3335 2.16634C1.3335 2.51996 1.47397 2.8591 1.72402 3.10915C1.97407 3.3592 2.31321 3.49967 2.66683 3.49967C3.02045 3.49967 3.35959 3.3592 3.60964 3.10915C3.85969 2.8591 4.00016 2.51996 4.00016 2.16634C4.00016 1.81272 3.85969 1.47358 3.60964 1.22353C3.35959 0.973484 3.02045 0.833008 2.66683 0.833008C2.31321 0.833008 1.97407 0.973484 1.72402 1.22353C1.47397 1.47358 1.3335 1.81272 1.3335 2.16634ZM12.0002 6.16634C12.0002 6.51996 12.1406 6.8591 12.3907 7.10915C12.6407 7.3592 12.9799 7.49967 13.3335 7.49967C13.6871 7.49967 14.0263 7.3592 14.2763 7.10915C14.5264 6.8591 14.6668 6.51996 14.6668 6.16634C14.6668 5.81272 14.5264 5.47358 14.2763 5.22353C14.0263 4.97348 13.6871 4.83301 13.3335 4.83301C12.9799 4.83301 12.6407 4.97348 12.3907 5.22353C12.1406 5.47358 12.0002 5.81272 12.0002 6.16634Z"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M4 2.16602H8.66667C8.84348 2.16602 9.01305 2.23625 9.13807 2.36128C9.2631 2.4863 9.33333 2.65587 9.33333 2.83268V9.49935C9.33333 9.67616 9.2631 9.84573 9.13807 9.97075C9.01305 10.0958 8.84348 10.166 8.66667 10.166H7.33333M9.33333 6.16602H12"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default IconGroup;
