import React, { useState, useEffect } from "react";
import { useMediaQuery, Text, Link, Box } from "@chakra-ui/react";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";
import { AuthorProps } from "../../definitions/interfaces/GlobalInterfaces";
import { colors } from "../../definitions/constants/GlobalStyles";
import { isMacOs, isIOS } from "react-device-detect";
import i18n from "../../i18n/i18n";

interface TruncatedTextProps {
  text: string;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text }) => {
  const sizeSmall = 100;
  const sizeBig = 500;
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [length, setLength] = useState(sizeSmall);
  const [showMore, setShowMore] = useState(true);
  const [textContent, setTextContent] = useState(text.substring(0, length));
  const [hideBtn, setHideBtn] = useState(false);

  const changeLength = (size: string) => {
    if (text.length <= sizeSmall) {
      setTextContent(text);
      setHideBtn(true);
    } else {
      setHideBtn(false);
      if (size === "small") {
        setShowMore(true);
        setLength(sizeSmall);
        setTextContent(text.substring(0, sizeSmall) + "...");
      } else if (size === "big") {
        setShowMore(false);
        setLength(sizeBig);
        text.length <= sizeBig
          ? setTextContent(text.substring(0, sizeBig))
          : setTextContent(text.substring(0, sizeBig) + "...");
      }
    }
  };

  useEffect(() => {
    changeLength("small");
  }, []);

  return (
    <>
      <Text
        fontSize="13px"
        fontWeight="400"
        lineHeight="18.51px"
        color={colors.figmaColors.grey3}
        p={isMobile ? "2" : "0"}
      >
        {textContent}
      </Text>

      {/* { !hideBtn ? 
              <Box 
              marginTop={1}
              >
                  { showMore 
                  ?
                    <Text 
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="18.51px"
                      color={colors.figmaColors.secColor}
                      p={isMobile ? "2" : "0"}
                      onClick={ () => changeLength("big") }
                      _hover={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      { i18n.t('see more') } 
                    </Text>
                    :
                    <Text 
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="18.51px"
                    color={colors.figmaColors.secColor}
                    p={isMobile ? "2" : "0"}
                    onClick={ () => changeLength("small") }
                    _hover={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    { i18n.t('see less') } 
                  </Text>
                }
              </Box>
              : null } */}
    </>
  );
};

export function PostText({ post }: AuthorProps) {
  return <TruncatedText text={post.post_text} />;
}

export default PostText;
