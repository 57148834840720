import { useEffect, useState } from "react";
import { Image } from "@chakra-ui/react";

const getCountryCode = async (countryName: string) => {
  try {
    const response = await fetch(
      `https://restcountries.com/v3.1/name/${countryName}?fields=cca2`
    );
    const data = await response.json();
    return data[0]?.cca2;
  } catch {
    return null;
  }
};

const CountryFlag = ({ countryName }: { countryName: string }) => {
  const [countryCode, setCountryCode] = useState<string | null>(null);

  useEffect(() => {
    getCountryCode(countryName).then(setCountryCode);
  }, [countryName]);

  const fallbackFlagUrl = "https://flagcdn.com/w320/un.png";
  const flagUrl = countryCode
    ? `https://flagcdn.com/w320/${countryCode.toLowerCase()}.png`
    : fallbackFlagUrl;

  return (
    <Image
      src={flagUrl}
      alt={`${countryName} Flag`}
      height="16px"
      width="21px"
    />
  );
};

export default CountryFlag;
