import { useState, useEffect } from "react";
import { Box, Flex, Text, Spinner, Image, Avatar } from "@chakra-ui/react";
import { appStore } from "../../../zustand/globalStore";
import { consumeApi, trackEvent } from "../../../utils/tools";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import bestTeamBackground from "../../../assets/images/bestTeamBackground.jpeg";
import { getTeamsFromTournament } from "../../../models/Tournament";
import TeamDetailCard from "../teams/TeamDetailCard";
export default function DetailsTournamentComponent() {
  // Zustand states
  const { currentTournament, setCurrentTournament } = appStore(
    (state) => state
  );
  // Local states

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    const fetchTeams = async () => {
      if (currentTournament) {
        setLoading(true);
        const fetchedTeams = await getTeamsFromTournament(currentTournament.id);
        setTeams(fetchedTeams);
        setLoading(false);
      }
    };

    fetchTeams();
  }, [currentTournament]);

  useEffect(() => {
    trackEvent("DetailsTournamentComponent", {});
  }, []);

  if (!currentTournament) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Text fontSize="lg" color="gray.500">
          {i18n.t("noCurrentTournament")}
        </Text>
      </Box>
    );
  }

  return (
    <Box w="100%">
      <Box padding={0}>
        <Flex justifyContent="space-between" width="100%">
          <Box width="49%" {...styles.card}>
            <Box flexDirection="column">
              <Box {...styles.titleBox}>
                <Text
                  color={colors.text.white}
                  fontSize="11px"
                  fontWeight="400"
                  marginLeft={2}
                >
                  {i18n.t("bestTeamTournament")}
                </Text>
              </Box>

              <Box
                marginTop={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                marginLeft={4}
              >
                <Text
                  color={colors.text.white}
                  fontSize="17px"
                  fontWeight="700"
                >
                  Real Madrid
                </Text>
                <Flex alignItems="center">
                  <Image
                    src={
                      "https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg"
                    }
                    height="16px"
                    width="21px"
                  />
                  <Text
                    color={colors.text.white}
                    fontSize="11px"
                    fontWeight="regular"
                    marginLeft={2}
                  >
                    Espana
                  </Text>
                </Flex>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              color={colors.text.white}
            >
              <Box
                borderRadius="50%"
                overflow="hidden"
                width="60px"
                height="60px"
                textAlign="center"
              >
                <Avatar
                  width="100%"
                  height="100%"
                  src="https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg" //URL image
                  name="test"
                />
              </Box>

              <Box
                borderRadius="20px"
                bg={colors.figmaColors.priColor}
                padding={2}
                textAlign="center"
                cursor="pointer"
              >
                <Text
                  color={colors.text.black}
                  fontSize="13px"
                  fontWeight="600"
                >
                  44 Puntos
                </Text>
              </Box>
            </Box>
          </Box>

          <Box width="49%" {...styles.card}>
            <Box flexDirection="column">
              <Box {...styles.titleBox}>
                <Text
                  color={colors.text.white}
                  fontSize="11px"
                  fontWeight="400"
                  marginLeft={2}
                >
                  {i18n.t("bestPlayerTournament")}
                </Text>
              </Box>

              <Box
                marginTop={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                marginLeft={4}
              >
                <Text
                  color={colors.text.white}
                  fontSize="17px"
                  fontWeight="700"
                >
                  LEONEL MESSI
                </Text>
                <Flex alignItems="center">
                  <Image
                    src={
                      "https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg"
                    }
                    height="16px"
                    width="21px"
                  />
                  <Text
                    color={colors.text.white}
                    fontSize="11px"
                    fontWeight="regular"
                    marginLeft={2}
                  >
                    Delantero
                  </Text>
                </Flex>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              color={colors.text.white}
            >
              <Box
                borderRadius="50%"
                overflow="hidden"
                width="60px"
                height="60px"
                textAlign="center"
              >
                <Avatar
                  width="100%"
                  height="100%"
                  src="https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg" //URL image
                  name="test"
                />
              </Box>

              <Box
                borderRadius="20px"
                bg={colors.figmaColors.priColor}
                padding={2}
                textAlign="center"
                cursor="pointer"
              >
                <Text
                  color={colors.text.black}
                  fontSize="13px"
                  fontWeight="600"
                >
                  13 Goles
                </Text>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>

      <Box paddingTop={5}>
        <BodyText
          color={colors.text.black}
          textAlign="left"
          size="extraSmall"
          fontWeight="semiBold"
        >
          {i18n.t("Teams")}
        </BodyText>
      </Box>

      <Box paddingTop={5}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Spinner size="lg" color="blue.500" />
          </Box>
        ) : (
          <TeamDetailCard teams={teams} />
        )}
      </Box>
    </Box>
  );
}

const styles = {
  card: {
    borderRadius: "20px",
    boxShadow: "md",
    bg: "white",
    padding: 4,
    display: "flex",
    backgroundImage: `url(${bestTeamBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleBox: {
    bg: colors.figmaColors.secColor,
    borderRadius: "16px",
    padding: 2,
    height: "40px",
    width: "230px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    color: "white",
  },
};
