import { Box, Flex, IconButton, Input } from "@chakra-ui/react";
import BodyText from "./BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import { SendIconWhite } from "../../definitions/constants/IconsComponent";
import "../styles/SearchUser.style.scss";

const SearchUser = ({
  searchText,
  handleSearch,
}: {
  searchText: string;
  handleSearch: (e:any) => void;
}): JSX.Element => {
  return (
    <Box className="container-search-user">
      <Flex gap={"5px"}>
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="bold"
          textColor={colors.text.black}
        >
          {i18n.t("searchUser")}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("required")}
        </BodyText>
      </Flex>
      <Box className="input-and-button">
        <Input size={"lg"} value={searchText} onChange={handleSearch} />
        <IconButton
          aria-label="Search user"
          background={colors.figmaColors.secColor}
          color={"white"}
        >
          <SendIconWhite />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SearchUser;
