import { Flex } from "@chakra-ui/react";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import i18n from "../../../i18n/i18n";
import { IncidenceTypesResume } from "../../../definitions/enums/GlobalEnums";

const ItemResumeIncidences = ({ index, name, value }: any) => (
  <Flex w={"100%"} justifyContent={"space-between"}>
    <BodyText
      fontSize={13}
      fontWeight="semiBold"
      color={colors.text.lightGray3}
    >
      {index}
    </BodyText>
    <BodyText fontSize={14} fontWeight="semiBold" color={colors.text.black}>
      {name}
    </BodyText>
    <BodyText
      fontSize={13}
      fontWeight="semiBold"
      color={colors.text.lightGray3}
    >
      {value}
    </BodyText>
  </Flex>
);

const getTypeIncidenceResume = (typeIncidence: string) => {
  let incidenceTypeSelected = "";
  switch (typeIncidence) {
    case IncidenceTypesResume.goals:
      incidenceTypeSelected = i18n.t("goals");
      break;
    case IncidenceTypesResume.yellow_cards:
      incidenceTypeSelected = i18n.t("yellowCards");
      break;
    case IncidenceTypesResume.red_cards:
      incidenceTypeSelected = i18n.t("redCards");
      break;
    case IncidenceTypesResume.substitutions:
      incidenceTypeSelected = i18n.t("substitutions");
      break;
  }
  return incidenceTypeSelected;
};

const MatchSummary = ({ currentMatch, typeIncidence }: any) => {
  return (
    <Flex gap={3} flexDirection={"column"}>
      <Flex
        p={4}
        gap={2.5}
        border="1px"
        borderRadius={16}
        alignItems={"center"}
        flexDirection={"column"}
        background={colors.figmaColors.appBgColor}
        borderColor={colors.figmaColors.grey1}
      >
        {typeIncidence.map((item: string, index: number) => (
          <ItemResumeIncidences
            key={index}
            index={
              currentMatch?.scores
                ? currentMatch?.scores[currentMatch?.first_team_id]
                  ? currentMatch?.scores[currentMatch?.first_team_id][item]
                  : 0
                : 0
            }
            name={getTypeIncidenceResume(item)}
            value={
              currentMatch?.scores
                ? currentMatch?.scores[currentMatch?.second_team_id]
                  ? currentMatch?.scores[currentMatch?.second_team_id][item]
                  : 0
                : 0
            }
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default MatchSummary;
