import React from "react";
import { Box, Button, Image, Flex } from "@chakra-ui/react";
import BodyText from "./BodyText";
import CountryFlag from "./CountryFlag";
import ImageFromText from "./ImageFromText";
import { SettingIcon } from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import { appStore } from "../../zustand/globalStore";
import { TypeViewToShow } from "../../definitions/enums/GlobalEnums";

interface TeamProps {
  data: any;
  isTeam: true;
}

interface TournamentProps {
  data: any;
  isTeam: false;
  onCupClick: () => void;
}

type TournamentTeamHeaderProps = TeamProps | TournamentProps;

const TournamentTeamHeader: React.FC<TournamentTeamHeaderProps> = (props) => {
  const { data, isTeam } = props;
  const { showContainerModal, userData } = appStore((state) => state);
  const isCreator = userData?.id === data?.user_id;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap={2}
        padding={"8px 0px"}
      >
        <Box
          display="flex"
          borderRadius="100%"
          boxSize="80px"
          border={isTeam ? "none" : `2px solid ${colors.figmaColors.secD}`}
          borderColor={
            isTeam ? "transparent" : colors.figmaColors.leaguesGradient1
          }
          justifyContent="center"
          alignItems="center"
          width="80px"
          height="80px"
          padding={isTeam ? "10px" : "0"}
        >
          {data?.media_url && data.media_url !== "None" ? (
            <Image
              src={data.media_url}
              style={{
                borderRadius: "100%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <ImageFromText
              sizeIcon={{
                borderRadius: "100%",
                width: "100%",
                height: "100%",
              }}
              sizeText={"x-large"}
              text={`${data?.name ?? ""} `}
            />
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={0.5}
        >
          <Flex
            alignItems="center"
            flexDirection={isTeam ? "column" : "row"}
            gap={isTeam ? 0 : 3}
          >
            <BodyText
              color={colors.text.black}
              fontWeight={"semiBold"}
              size="xMedium"
            >
              {data?.name}
            </BodyText>
            {!isTeam && (
              <BodyText
                color={colors.text.black}
                fontWeight={"semiBold"}
                size="xMedium"
              >
                -
              </BodyText>
            )}
            <Flex alignItems="center" gap={1}>
              <CountryFlag countryName={data?.place?.terms?.at(-1)?.value} />
              <BodyText
                color={colors.figmaColors.grey3}
                fontWeight={"medium"}
                size="small"
              >
                {data?.place?.terms?.at(-1)?.value || i18n.t("noRegion")}
              </BodyText>
            </Flex>
          </Flex>

          {!isTeam && "onCupClick" in props && (
            <Flex alignItems="center" gap={1}>
              <BodyText
                color={colors.text.lightGray3}
                size="extraSmall"
                fontWeight={"medium"}
              >
                {data?.name}
              </BodyText>
              <Box onClick={props.onCupClick} sx={{ cursor: "pointer" }}>
                <BodyText
                  color={colors.figmaColors.secColor}
                  size="extraSmall"
                  fontWeight={"semiBold"}
                  sx={{ textDecoration: "underline" }}
                >
                  {i18n.t("seeCup")}
                </BodyText>
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        {data?.id && isCreator && (
          <Button
            variant="outline"
            colorScheme="white"
            borderColor={colors.figmaColors.secColor}
            color={colors.figmaColors.secColor}
            borderRadius="10px"
            height="30px"
            gap={1}
            onClick={() => {
              if (isTeam) {
                showContainerModal(TypeViewToShow.UpdateOrViewTeamData);
              } else {
                showContainerModal(TypeViewToShow.UpdateOrViewTournamentData);
              }
            }}
          >
            <BodyText
              color={colors.figmaColors.secColor}
              size="xxSmall"
              fontWeight="bold"
            >
              {i18n.t("options")}
            </BodyText>
            <SettingIcon />
          </Button>
        )}
      </Flex>
    </>
  );
};

export default TournamentTeamHeader;
