import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { appStore } from "../../../zustand/globalStore";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { steps } from "./container/steps.container";
import IconAddSquare from "../../../assets/icons/IconAddSquare";
import BodyText from "../../Common/BodyText";
import i18n from "../../../i18n/i18n";
import ItemTechnicalStaff from "./components/ItemTechnicalStaff";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import InviteManagers from "./InviteManagers";
import {
  getCurrentTechnicalStaffFromTeam,
  getInvitationTechnicalStaffToTeam,
  removeInvitationToTechnicalStuff,
} from "../../../models/TeamModel";
import MessageConfirmationComponent from "../../MessageConfirmationComponent";
import DeleteContentModal from "../../Modals/DeleteContentModal";

const CoachingStaff = (): JSX.Element => {
  const [managers, setManagers] = useState<User[]>([]);
  const { currentTeam, userData, hideContainerModal, currentTournament } =
    appStore((store) => store);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [activeStep, goToStep] = useState<string>(steps.default);
  const [invitation, setInvitation] = useState<any>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const technicalStaffResp = await getCurrentTechnicalStaffFromTeam(
        currentTeam.id,
        currentTournament.id
      );
      setManagers(technicalStaffResp);
      setLoading(false);
    })();
  }, [currentTeam.id, currentTournament.id]);

  useEffect(() => {
    (async () => {
      if(currentTeam&&currentTournament&&selectedUser){
        const resp = await getInvitationTechnicalStaffToTeam(
          currentTeam?.id,
          currentTournament?.id,
          selectedUser?.id
        );
        console.log(resp, "***respinvitation****")
        if (resp != null) {
          setInvitation(resp);
        } else {
          setInvitation(null);
        }
      }
     
    })();
  }, [selectedUser, currentTeam, currentTournament]);

  if (loading) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={999}
      >
        <Spinner size="lg" color="blue.500" />
      </Box>
    );
  }

  const handleDeletePlayer = async () => {
    if(invitation){
      setLoading(true);
      await removeInvitationToTechnicalStuff(
        currentTeam?.id,
        invitation,
        selectedUser?.id
      );
      setLoading(false);
      goToStep(steps.successDeleteTechnicalStaff)
    }else{
      goToStep(steps.default)
    }
  };
  return (
    <>
      {activeStep === steps.default && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <ModalHeaderComponent
            title={i18n.t("teamManagers")}
            subtitle={i18n.t("StartManagingTeam")}
            onCancel={hideContainerModal}
            isSaving={loading}
            hasMoreSteps={false}
            actionButtonsRequired={false}
          />
          <Flex
            padding="0px 16px"
            borderRadius={16}
            gap={3}
            height="60px"
            alignItems="center"
            cursor="pointer"
            justifyContent="center"
            backgroundColor={colors.backgrounds.gray0}
            onClick={() => goToStep(steps.inviteTechnicalStaff)}
          >
            <IconAddSquare />
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("addNewMember")}
            </BodyText>
          </Flex>
          {managers.length > 0 &&
            managers.map((item) => {
              return (
                <ItemTechnicalStaff
                  item={item}
                  setSelectedUser={setSelectedUser}
                  goToStep={goToStep}
                />
              );
            })}
        </Flex>
      )}
      {activeStep === steps.inviteTechnicalStaff && (
        <Flex width={"100%"}>
          <InviteManagers
            goToStep={goToStep}
            hideContainerModal={hideContainerModal}
          />
        </Flex>
      )}
      {activeStep === steps.successInviteTechnicalStaff && (
        <MessageConfirmationComponent
          title={i18n.t("invitationSuccessfully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {activeStep === steps.deleteTechnicalStaff && (
        <Flex width={"100%"} height={"100%"} direction={"column"}>
          <DeleteContentModal
            message={i18n.t("sureDeletePlayer", {
              manager: `${selectedUser.firstname} ${selectedUser.lastname}`,
            })}
            onCancel={hideContainerModal}
            onConfirm={handleDeletePlayer}
            loading={loading}
          />
        </Flex>
      )}
    </>
  );
};

export default CoachingStaff;
