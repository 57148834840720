import React from "react";
import { useMediaQuery, Box, Text, Flex, Avatar } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { AuthorComments } from "../../definitions/interfaces/GlobalInterfaces";
import i18n from "../../i18n/i18n";

function PostAuthor({ comment }: AuthorComments) {
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  return (
    <Box marginBottom={6} marginTop={2} paddingLeft={"15px"}>
      <Flex gap={2} p={isMobile ? "2" : "0"}>
        <Flex alignSelf="center">
          <Avatar
            boxSize="40px"
            borderRadius="100%"
            src={comment.image_url}
            name={comment.firstname + comment.lastname}
          />
        </Flex>
        <Flex direction="column" justifyContent="center">
          <Text
            fontSize="13px"
            fontWeight="600"
            lineHeight="18.51px"
            color={colors.figmaColors.black}
          >
            {comment.firstname}
            {comment.lastname}
          </Text>
          <Flex gap={2} alignItems="center">
            <Text
              fontSize="11px"
              fontWeight="400"
              lineHeight="18.51px"
              color={colors.figmaColors.grey3}
            >
              {comment.created_minutes < 60
                ? `${i18n.t("commented on")} ${
                    comment.created_minutes
                  } ${i18n.t("minutes")}`
                : comment.created_hours < 48
                ? `${i18n.t("commented on")} ${comment.created_hours} ${i18n.t(
                    "hours"
                  )}`
                : comment.created_days < 30
                ? `${i18n.t("commented on")} ${comment.created_days} ${i18n.t(
                    "days"
                  )}`
                : `${i18n.t("commented on")} ${comment.date_format}`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex justify="start" align="center" paddingLeft="48px">
        <Text
          fontSize="13px"
          fontWeight="400"
          lineHeight="18.51px"
          color={colors.figmaColors.grey3}
        >
          {comment.text}
        </Text>
      </Flex>
    </Box>
  );
}

export default PostAuthor;
