import React, { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import i18n from "../../../../../i18n/i18n";
import CitySelector from "../../../../../components/Common/CitySelector";
import { PlaceDetails } from "../../../../../definitions/interfaces/Place.interface";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";

const TournamentRegulations: React.FC<{
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ steps, goToStep, currentStep, isSaving, setIsSaving }) => {
  const [place, setPlace] = useState<PlaceDetails | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: "",
    address: "",
    phone_number: "",
    email: "",
    location: "",
  };
  const [formData, setFormData] = useState(dataForm);

  const onPressRegulations = async (data: any) => {
    if (isSaving) return;

    setIsSaving(true);
    try {
      goToStep(steps.successSaveRegulations);
    } catch (error) {
      console.error("Error creating or updating tournament: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressRegulations(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Box
          as="form"
          onSubmit={handleSubmit}
          display="flex"
          gap={4}
          flexDirection="column"
          justifyContent="space-between"
        >
          <ModalHeaderComponent
            title={i18n.t("regulations")}
            subtitle={i18n.t("readyTournament")}
            confirmText={i18n.t("save")}
            onCancel={hideContainerModal}
            onBack={() => goToStep(steps.optionsSeason)}
            onConfirm={() => goToStep(steps.successSaveRegulations)}
            isSaving={isSaving}
            hasMoreSteps={true}
            actionButtonsRequired={true}
          />
          <Grid templateColumns="1fr 1fr" gap={4}>
            <GridItem colSpan={1}>
              <FormControl>
                <Flex gap={2.5} flexDirection={"column"}>
                  <Flex gap={1} flexDirection={"column"}>
                    <Flex gap={1} alignItems="center">
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("nameTournament")}
                      </FormLabel>
                      <BodyText
                        fontSize={{ base: "12px", md: "13px" }}
                        fontWeight="regular"
                        textColor={colors.text.lightGray3}
                      >
                        {i18n.t("required")}
                      </BodyText>
                    </Flex>
                    <Input
                      type="text"
                      {...inputStyle}
                      placeholder={i18n.t("nameTournament") + "..."}
                      value={formData.name}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                        setErrors((prev) => ({ ...prev, name: "" }));
                      }}
                    />
                    {errors.name && (
                      <BodyText
                        paddingLeft={4}
                        fontSize={{ base: "12px", md: "13px" }}
                        fontWeight="regular"
                        textColor={colors.text.red}
                      >
                        {errors.name}
                      </BodyText>
                    )}
                  </Flex>
                  <CitySelector
                    error={errors.city}
                    place={place}
                    setPlace={setPlace}
                  />
                </Flex>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <Flex gap={2.5} flexDirection={"column"}>
                  <Flex gap={1} flexDirection={"column"}>
                    <FormLabel {...formLabelStyle}>
                      {i18n.t("cellPhone")}
                    </FormLabel>
                    <Input
                      type="tel"
                      {...inputStyle}
                      placeholder={i18n.t("cellPhone") + "..."}
                      value={formData.phone_number}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          phone_number: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                  <Flex gap={1} flexDirection={"column"}>
                    <FormLabel {...formLabelStyle}>{i18n.t("email")}</FormLabel>
                    <Input
                      type="text"
                      {...inputStyle}
                      placeholder={i18n.t("email") + "..."}
                      value={formData.email}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                  <Flex gap={1} flexDirection={"column"}>
                    <FormLabel {...formLabelStyle}>
                      {i18n.t("address")}
                    </FormLabel>
                    <Input
                      type="text"
                      {...inputStyle}
                      placeholder={i18n.t("address") + "..."}
                      value={formData.address}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }))
                      }
                    />
                  </Flex>
                </Flex>
              </FormControl>
            </GridItem>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TournamentRegulations;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
};
