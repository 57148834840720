import React from "react";
import { Flex, Avatar } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../Common/BodyText";
import IconArrowShort from "../../assets/icons/IconArrowShort";

const TeamScore: React.FC<{
  team: string;
  teamImage: string;
  score: number;
  reverse?: boolean;
  showArrowIcon?: boolean;
  isRight?: boolean;
}> = ({
  team,
  teamImage,
  score,
  isRight = false,
  reverse = false,
  showArrowIcon = false,
}) => (
  <Flex
    gap={1.5}
    alignItems={"center"}
    flexDirection={reverse ? "row-reverse" : "row"}
    w={"100%"}
  >
    <Flex w={"100%"} gap={1} flexDirection={"column"} alignItems={"center"}>
      <Avatar
        boxSize={`${isRight ? "64px" : "48px"}`}
        src={teamImage}
        name={`${team ?? ""} `}
        sx={{
          ".chakra-avatar__initials": {
            fontSize: isRight ? "1.5rem" : "1rem",
          },
        }}
      />
      <Flex gap={1} alignItems={"center"}>
        {showArrowIcon && !reverse && (
          <IconArrowShort
            style={{
              transform: "rotate(-90deg)",
            }}
          />
        )}
        <BodyText
          fontSize={14}
          fontWeight={showArrowIcon ? "semiBold" : "regular"}
          color={showArrowIcon ? colors.text.black : colors.text.lightGray3}
          textAlign="center"
        >
          {team}
        </BodyText>
        {showArrowIcon && reverse && (
          <IconArrowShort
            style={{
              transform: "rotate(90deg)",
            }}
          />
        )}
      </Flex>
    </Flex>
    {score != null ? (
      <Flex
        boxSize={"60px"}
        backgroundColor={colors.backgrounds.gray0}
        borderRadius={16}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <BodyText
          fontSize={17}
          fontWeight={showArrowIcon ? "semiBold" : "regular"}
          color={showArrowIcon ? colors.text.black : colors.text.lightGray3}
        >
          {score}
        </BodyText>
      </Flex>
    ) : (
      <Flex
        boxSize={"60px"}
        borderRadius={16}
        bg={colors.backgrounds.gray0}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <BodyText
          fontSize={17}
          fontWeight={showArrowIcon ? "semiBold" : "regular"}
          color={showArrowIcon ? colors.text.black : colors.text.lightGray3}
        >
          -
        </BodyText>
      </Flex>
    )}
  </Flex>
);

export default TeamScore;
