import { ReactComponent as WuamballIconSVG } from "../../components/imgs/icons/wuamball-icon.svg";
import { ReactComponent as PublicIconSVG } from "../../components/imgs/icons/public-icon.svg";
import { ReactComponent as EllipseIconSVG } from "../../components/imgs/icons/ellipse.svg";
import { ReactComponent as CommentsIconSVG } from "../../components/imgs/icons/comments-icon.svg";
import { ReactComponent as ShareIconSVG } from "../../components/imgs/icons/share-icon.svg";
import { ReactComponent as AppleStoreIconSVG } from "../../components/imgs/icons/apple-store.svg";
import { ReactComponent as GooglePlayIconSVG } from "../../components/imgs/icons/google-play-icon.svg";
import { ReactComponent as SendIconSVG } from "../../components/imgs/icons/send-icon.svg";
import { ReactComponent as SendIconWhiteSVG } from "../../components/imgs/icons/send-white.svg";
import { ReactComponent as MetaIconSVG } from "../../assets/images/login-screen/meta-btn.svg";
import { ReactComponent as GoogleIconSVG } from "../../assets/images/login-screen/google-btn.svg";
import { ReactComponent as AppleIconSVG } from "../../assets/images/login-screen/apple-btn.svg";
import { ReactComponent as InputErrorSVG } from "../../assets/icons/new-icons/input-error.svg";
import { ReactComponent as EyeOpenedSVG } from "../../assets/icons/new-icons/eye-opened.svg";
import { ReactComponent as EyeClosedSVG } from "../../assets/icons/new-icons/eye-closed.svg";
import { ReactComponent as HamburgerSVG } from "../../assets/icons/new-icons/hamburger.svg";
import { ReactComponent as TShirtIconSVG } from "../../assets/icons/new-icons/t-shirt.svg";
import { ReactComponent as SortIconSVG } from "../../assets/icons/new-icons/sort.svg";
import { ReactComponent as AddIconSVG } from "../../assets/icons/new-icons/add.svg";
import { ReactComponent as CircleCheckSVG } from "../../assets/icons/new-icons/tick-circle.svg";
import { ReactComponent as SearchSVG } from "../../assets/icons/new-icons/search-normal.svg";
import { ReactComponent as SettingSVG } from "../../assets/icons/new-icons/setting.svg";
import { ReactComponent as CloseCircleSVG } from "../../assets/icons/new-icons/close-circle.svg";
import { ReactComponent as PeopleIconSVG } from "../../assets/icons/new-icons/people.svg";
import { ReactComponent as MessageIconSVG } from "../../assets/icons/new-icons/message-question.svg";
import { ReactComponent as GroupPublicIconSVG } from "../../assets/images/groupPublic.svg";
import { ReactComponent as ConfettiIconSVG } from "../../assets/images/confetti.svg";
import { ReactComponent as PlayerIconSVG } from "../../assets/images/playerImage.svg";
import { ReactComponent as PlayerGrayIconSVG } from "../../assets/images/playerImageGray.svg";
import { ReactComponent as ProfileCircleSVG } from "../../assets/icons/new-icons/profile-circle.svg";
import { ReactComponent as ShieldIconSVG } from "../../assets/icons/new-icons/shield.svg";
import { ReactComponent as MessageSquareIconSVG } from "../../assets/icons/new-icons/message-square.svg";
import { ReactComponent as FlagIconSVG } from "../../assets/icons/new-icons/flag.svg";
import { ReactComponent as RulerAndPenIconSVG } from "../../assets/icons/new-icons/ruler-pen.svg";
import { ReactComponent as FieldIconSVG } from "../../assets/icons/new-icons/field.svg";
import { ReactComponent as EditIconSVG } from "../../assets/icons/new-icons/edit.svg";
import { ReactComponent as Profile } from "../../assets/icons/new-icons/profile.svg";
import { ReactComponent as MessageDot } from "../../assets/icons/new-icons/message-dots.svg";

export const CloseCircleIcon = (props: any) => {
  return <CloseCircleSVG {...props} />;
};

export const SettingIcon = (props: any) => {
  return <SettingSVG {...props} />;
};

export const SearchICon = (props: any) => {
  return <SearchSVG {...props} />;
};

export const CircleCheckIcon = (props: any) => {
  return <CircleCheckSVG {...props} />;
};
export const SortIcon = (props: any) => {
  return <SortIconSVG {...props} />;
};

export const AddIcon = (props: any) => {
  return <AddIconSVG {...props} />;
};

export const WuamballIcon = (props: any) => {
  return <WuamballIconSVG {...props} />;
};

export const PublicIcon = (props: any) => {
  return <PublicIconSVG {...props} />;
};

export const EllipseIcon = (props: any) => {
  return <EllipseIconSVG {...props} />;
};

export const CommentsIcon = (props: any) => {
  return <CommentsIconSVG {...props} />;
};

export const ShareIcon = (props: any) => {
  return <ShareIconSVG {...props} />;
};

export const AppleStoreIcon = (props: any) => {
  return <AppleStoreIconSVG {...props} />;
};

export const GooglePlayIcon = (props: any) => {
  return <GooglePlayIconSVG {...props} />;
};

export const SendIcon = (props: any) => {
  return <SendIconSVG {...props} />;
};

export const MetaIcon = (props: any) => {
  return <MetaIconSVG {...props} />;
};

export const GoogleIcon = (props: any) => {
  return <GoogleIconSVG {...props} />;
};

export const AppleIcon = (props: any) => {
  return <AppleIconSVG {...props} />;
};

export const InputErrorIcon = (props: any) => {
  return <InputErrorSVG {...props} />;
};

export const EyeOpenedIcon = (props: any) => {
  return <EyeOpenedSVG {...props} />;
};

export const EyeClosedIcon = (props: any) => {
  return <EyeClosedSVG {...props} />;
};

export const HamburgerIcon = (props: any) => {
  return <HamburgerSVG {...props} />;
};

export const TShirtIcon = (props: any) => {
  return <TShirtIconSVG {...props} />;
};

export const PeopleIcon = (props: any) => {
  return <PeopleIconSVG {...props} />;
};

export const MessageIcon = (props: any) => {
  return <MessageIconSVG {...props} />;
};

export const GroupPublicIcon = (props: any) => {
  return <GroupPublicIconSVG {...props} />;
};

export const ConfettiIcon = (props: any) => {
  return <ConfettiIconSVG {...props} />;
};

export const PlayerIcon = (props: any) => {
  return <PlayerIconSVG {...props} />;
};

export const PlayerIconGray = (props: any) => {
  return <PlayerGrayIconSVG {...props} />;
};

export const ProfileCircle = (props: any) => {
  return <ProfileCircleSVG {...props} />;
};

export const ShieldIcon = (props: any) => {
  return <ShieldIconSVG {...props} />;
};

export const MessageSquareIcon = (props: any) => {
  return <MessageSquareIconSVG {...props} />;
};

export const FlagIcon = (props: any) => {
  return <FlagIconSVG {...props} />;
};

export const RulerAndPenIcon = (props: any) => {
  return <RulerAndPenIconSVG {...props} />;
};

export const FieldIcon = (props: any) => {
  return <FieldIconSVG {...props} />;
};

export const SendIconWhite = (props: any): JSX.Element => {
  return <SendIconWhiteSVG {...props} />;
};

export const EditIcon = (props: any): JSX.Element => {
  return <EditIconSVG {...props} />;
};

export const ProfileIcon = (props: any): JSX.Element => {
  return <Profile {...props}/>
}

export const MessageDotIcon = (props:any): JSX.Element => {
  return <MessageDot {...props}/>
}
