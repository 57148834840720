import React, { useState } from "react";
import { Box, FormLabel, Input, Flex, Spinner, Button } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import PrivacySwitch from "../../../../../components/Common/PrivacySwitch";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import CustomMenuSelect from "../../../../../components/Shared/CustomMenuSelect";
import { TypePhasesSetup } from "../../../../../definitions/enums/GlobalEnums";
import { createPhase, updatePhase } from "../../../../../models/Tournament";
import IconEdit from "../../../../../assets/icons/IconEdit";
import IconTrash from "../../../../../assets/icons/IconTrash";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";

interface CreatePhaseBaseProps {
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  groups: any[];
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditPhaseProps =
  | (CreatePhaseBaseProps & {
      isEdit: false;
      selectedPhase?: never;
      setSelectedGroup?: never;
    })
  | (CreatePhaseBaseProps & {
      isEdit: true;
      selectedPhase: { [key: string]: any };
      setSelectedGroup: React.Dispatch<
        React.SetStateAction<{ [key: string]: any } | undefined>
      >;
    });

const CreateOrEditPhase: React.FC<CreateOrEditPhaseProps> = ({
  isEdit,
  steps,
  goToStep,
  groups,
  currentStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  setSelectedGroup
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { currentTournament, hideContainerModal } = appStore((state) => state);
  console.log("Current phase:", selectedPhase);

  const dataForm = {
    name: isEdit ? selectedPhase.name : "",
    count_teams_by_group: isEdit ? selectedPhase.count_teams_by_group : "",
    count_teams_for_next_phase: isEdit
      ? selectedPhase.count_teams_for_next_phase
      : "",
    count_groups: isEdit ? selectedPhase.count_groups : "",
  };
  const [formData, setFormData] = useState(dataForm);
  const [typePhaseDescription, setTypePhaseDescription] = useState(
    isEdit ? selectedPhase.type_phase_setup : ""
  );
  const [haveGroups, setHaveGroups] = useState(
    isEdit ? selectedPhase.have_groups : false
  );
  const [isRound, setIsRound] = useState(
    isEdit ? selectedPhase.is_round : false
  );

  const onPressCreateOrEditPhase = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      data.have_groups = haveGroups;
      data.type_phase_setup = typePhaseDescription;
      data.is_round = isRound;

      if (isEdit) {
        await updatePhase(selectedPhase.id, currentTournament.id, data);
        goToStep(steps.successEditPhase);
      } else {
        await createPhase(currentTournament.id, data, groups);
        goToStep(steps.successNewPhase);
      }
    } catch (error) {
      console.error("Error creating or updating phase: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSwitchChange = () => {
    setIsRound(!isRound);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
      {
        key: "typePhaseDescription",
        value: typePhaseDescription,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditPhase(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.editPhase
            : currentStep === steps.newPhase) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              {isEdit ? (
                <ModalHeaderComponent
                  title={i18n.t("editPhase")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.modality)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("newPhase")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.modality)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              )}
              <Flex gap={2} flexDirection={"column"}>
                <Flex gap={1} flexDirection={"column"}>
                  <Flex gap={1} alignItems="center">
                    <FormLabel {...formLabelStyle}>
                      {i18n.t("phaseName")}
                    </FormLabel>
                    <BodyText
                      fontSize={{ base: "12px", md: "13px" }}
                      fontWeight="regular"
                      textColor={colors.text.lightGray3}
                    >
                      {i18n.t("required")}
                    </BodyText>
                  </Flex>
                  <Input
                    type="text"
                    {...inputStyle}
                    placeholder={i18n.t("phaseName") + "..."}
                    defaultValue={formData.name}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                      setErrors((prev) => ({ ...prev, name: "" }));
                    }}
                  />
                  {errors.name && (
                    <BodyText
                      paddingLeft={4}
                      fontSize={{ base: "12px", md: "13px" }}
                      fontWeight="regular"
                      textColor={colors.text.red}
                    >
                      {errors.name}
                    </BodyText>
                  )}
                </Flex>
                <Flex gap={1} flexDirection={"column"} width="100%">
                  <Flex gap={1} alignItems="center">
                    <FormLabel {...formLabelStyle}>
                      {i18n.t("phaseType")}
                    </FormLabel>
                    <BodyText
                      fontSize={{ base: "12px", md: "13px" }}
                      fontWeight="regular"
                      textColor={colors.text.lightGray3}
                    >
                      {i18n.t("required")}
                    </BodyText>
                  </Flex>
                  <CustomMenuSelect
                    options={TypePhasesSetup}
                    selectedDescription={typePhaseDescription}
                    setSelectedDescription={setTypePhaseDescription}
                  />
                  {errors.typePhaseDescription && (
                    <BodyText
                      paddingLeft={4}
                      fontSize={{ base: "12px", md: "13px" }}
                      fontWeight="regular"
                      textColor={colors.text.red}
                    >
                      {errors.typePhaseDescription}
                    </BodyText>
                  )}
                </Flex>
                <Flex gap={4}>
                  <Flex gap={1} flexDirection={"column"} width="100%">
                    <Flex gap={1} alignItems="center">
                      <FormLabel {...formLabelStyle}>
                        {i18n.t("numberTeams")}
                      </FormLabel>
                    </Flex>
                    <Input
                      type="number"
                      {...inputStyle}
                      placeholder={i18n.t("numberTeams") + "..."}
                      defaultValue={formData.count_teams_by_group}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          count_teams_by_group: e.target.value,
                        }));
                      }}
                    />
                  </Flex>
                  <Flex gap={1} flexDirection={"column"} width="100%">
                    <Flex gap={1} alignItems="center">
                      <FormLabel {...formLabelStyle}>
                        {typePhaseDescription === TypePhasesSetup.groups
                          ? i18n.t("numberClassifiedGroup")
                          : i18n.t("numberClassified")}
                      </FormLabel>
                    </Flex>
                    <Input
                      type="number"
                      {...inputStyle}
                      placeholder={
                        (typePhaseDescription === TypePhasesSetup.groups
                          ? i18n.t("numberClassifiedGroup")
                          : i18n.t("numberClassified")) + "..."
                      }
                      defaultValue={formData.count_teams_for_next_phase}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          count_teams_for_next_phase: e.target.value,
                        }));
                      }}
                    />
                  </Flex>
                </Flex>
                <Box padding="0 16px 0 16px">
                  <PrivacySwitch
                    isPrivate={isRound}
                    isPrivateText={i18n.t("roundTrip")}
                    onSwitchChange={handleSwitchChange}
                  />
                </Box>
                {typePhaseDescription === TypePhasesSetup.groups && (
                  <>
                    <Flex
                      borderBottom="1px"
                      borderColor={colors.backgrounds.gray1}
                      padding="8px 16px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <BodyText
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="semiBold"
                        textColor={colors.text.black}
                      >
                        {i18n.t("groups")}
                      </BodyText>
                      <Button
                        fontSize={{ base: "12px", md: "14px" }}
                        fontWeight="600"
                        gap={2}
                        color={colors.figmaColors.secD}
                        bg="white"
                        onClick={() => {
                          goToStep(steps.createGroup);
                        }}
                      >
                        {i18n.t("add")}
                        <IconAddSquare />
                      </Button>
                    </Flex>
                    {groups.length > 0 ? (
                      groups.map((group) => (
                        <Flex
                          padding="12px 16px"
                          borderRadius={16}
                          gap={3}
                          alignItems="center"
                          justifyContent="space-between"
                          bg={colors.backgrounds.gray0}
                        >
                          <Flex direction="column">
                            <BodyText
                              fontSize={{ base: "12px", md: "14px" }}
                              fontWeight="medium"
                              textColor={colors.text.black}
                            >
                              {group.name}
                            </BodyText>
                          </Flex>
                          <Flex gap={3}>
                            <IconEdit
                              stroke={colors.figmaColors.secColor}
                              cursor="pointer"
                              onClick={() => {
                                setSelectedGroup(group);
                                goToStep(steps.updateGroup);
                              }}
                            />
                            <IconTrash
                              stroke={colors.backgrounds.red}
                              cursor="pointer"
                              onClick={() => {
                                setSelectedGroup(group);
                                goToStep(steps.deleteGroup);
                              }}
                            />
                          </Flex>
                        </Flex>
                      ))
                    ) : (
                      <BodyText
                        fontSize={{ base: "11px", md: "12px" }}
                        fontWeight="semiBold"
                        textColor={colors.text.black}
                      >
                        {i18n.t("noData")}
                      </BodyText>
                    )}
                  </>
                )}
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditPhase;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
