import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
} from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import BodyText from "./BodyText";

function NotificationModal({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        position="fixed"
        bottom={{
          base: "8px",
          md: "12px",
        }}
        right={{
          base: "8px",
          sm: "16px",
          md: "24px",
          lg: "32px",
        }}
        margin={0}
        borderRadius={24}
        boxShadow="lg"
        p="24px 32px 24px 32px"
        gap={4}
      >
        <ModalHeader p={0}>
          <BodyText fontSize={22} fontWeigh="small" color={colors.text.black}>
            {i18n.t("join_action")}
          </BodyText>
          <BodyText
            fontSize={13}
            fontWeight="regular"
            color={colors.text.lightGray3}
          >
            {i18n.t("accept_invitations")}
          </BodyText>
        </ModalHeader>
        <ModalCloseButton position="absolute" top="16px" right="16px" />
        <ModalBody p={0}>
          <Box>
            <Flex>{/*Here Notifications mapping*/}</Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NotificationModal;
