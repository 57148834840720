import { useState } from "react";
import { Link as ChakraLink, Box, Checkbox, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { colors } from "../../definitions/constants/GlobalStyles";
import { WuamballIcon } from "../../definitions/constants/IconsComponent";
import BodyText from "./BodyText";
import LoginOptionsGrid from "./LoginOptionsGrid";
import ButtonComponent from "./ButtonComponent";
import i18n from "../../i18n/i18n";

const FormComponent = ({
  titleFrom,
  buttonText,
  footerText,
  footerLink,
  isLogin,
  isRegister,
  children,
  onSubmit,
  isInputValid = true,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Box
      as="form"
      margin="0 auto"
      backgroundColor="white"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      width={["95%", "95%", "388px"]}
      maxWidth={400}
      padding="48px 32px"
      gap={4}
      borderRadius={48}
      alignItems="center"
    >
      <ChakraLink as={Link} to={"/"}>
        <WuamballIcon width="110px" height="36px" />
      </ChakraLink>
      <BodyText fontSize={22} fontWeight="bold" color={colors.text.black}>
        {titleFrom}
      </BodyText>
      {children}
      {isLogin && (
        <Flex display="flex" gap="5px" alignSelf="end">
          <ChakraLink
            as={Link}
            to={"/recover_password"}
            color={colors.figmaColors.secColor}
          >
            <BodyText
              color={colors.figmaColors.secColor}
              fontSize={14}
              fontWeight="semiBold"
            >
              {i18n.t("recover_password")}
            </BodyText>
          </ChakraLink>
        </Flex>
      )}
      {isRegister && (
        <Checkbox colorScheme="teal" onChange={handleCheckboxChange}>
          <ChakraLink
            as={Link}
            to={"/terms_and_conditions"}
            target="_blank"
            rel="noopener noreferrer"
            color={colors.figmaColors.secColor}
          >
            <BodyText
              color={colors.figmaColors.secColor}
              fontSize={14}
              fontWeight="semiBold"
            >
              {i18n.t("input_terms")}
            </BodyText>
          </ChakraLink>
        </Checkbox>
      )}
      <ButtonComponent
        variant="variantAccess"
        type="submit"
        color={colors.text.white}
        onClick={onSubmit}
        isDisabled={!isInputValid || (isRegister && !isChecked)}
      >
        {buttonText}
      </ButtonComponent>
      <Flex display="flex" flexDirection="column" alignItems="center" gap="8px">
        {isLogin && (
          <>
            <BodyText
              fontSize={14}
              fontWeight="regular"
              color={colors.text.lightGray3}
            >
              {i18n.t("or_sign_up_with")}
            </BodyText>
            <LoginOptionsGrid
              showMeta={false}
              showGoogle={true}
              showApple={true}
            />
          </>
        )}
        <Flex display="flex" gap="5px">
          <BodyText
            fontSize={14}
            fontWeight="regular"
            color={colors.text.lightGray3}
          >
            {footerText}
          </BodyText>
          <ChakraLink
            as={Link}
            to={isLogin ? "/register" : "/login"}
            color={colors.figmaColors.secColor}
          >
            <BodyText
              color={colors.figmaColors.secColor}
              fontSize={14}
              fontWeight="semiBold"
            >
              {footerLink}
            </BodyText>
          </ChakraLink>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FormComponent;
