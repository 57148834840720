import { Flex } from "@chakra-ui/react";
import backgroundImage from "../../assets/images/login-screen/login.jpeg";

const AccessComponent = ({ children }) => {
  return (
    <Flex
      justify="center"
      align="center"
      backgroundImage={`linear-gradient(180deg, rgba(89, 120, 217, 0.00) 0.3%, #0D2C8D 90.33%), url(${backgroundImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      minHeight="100vh"
      flexDirection="column"
      alignItems="center"
    >
      {children}
    </Flex>
  );
};

export default AccessComponent;
