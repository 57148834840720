import { Box, Spinner } from "@chakra-ui/react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { useEffect, useState } from "react";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import { getAllInvitationsManagerToTeam } from "../../../../../models/TeamModel";
import { appStore } from "../../../../../zustand/globalStore";
import ItemRequest from "../Managers/components/ItemRequest";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../../../components/Common/BodyText";

const RequestsSent = ({
  goToStep,
  hideContainerModal,
}: {
  goToStep: (key: string) => void;
  hideContainerModal: () => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { currentTeam } = appStore((store) => store);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const findUsers = await getAllInvitationsManagerToTeam(currentTeam.id);
      setUsers(findUsers);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={4}
      >
        <ModalHeaderComponent
          title={i18n.t("applicationSent")}
          subtitle={i18n.t("StartManagingTeam")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTeams.default)}
          isSaving={loading}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
        {users.length > 0 ? (
          users.map((item) => <ItemRequest item={item} setUsers={setUsers} />)
        ) : (
          <BodyText
            fontSize={{ base: "11px", md: "12px" }}
            fontWeight="semiBold"
            textColor={colors.text.black}
          >
            {i18n.t("noData")}
          </BodyText>
        )}
      </Box>
    </>
  );
};

export default RequestsSent;
