import React, { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery, Image, AspectRatio, Box } from "@chakra-ui/react";
import {
  AuthorProps,
  PostData,
} from "../../definitions/interfaces/GlobalInterfaces";
import { colors } from "../../definitions/constants/GlobalStyles";
import ReactPlayer from "react-player";

export function PostMedia({ post }: AuthorProps) {
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  const isVideo = (post: PostData): boolean => {
    const parsedVideoInfo: any = post?.local_media;
    return parsedVideoInfo?.type === "video";
  };

  const [ref, inView] = useInView({
    threshold: 0.75,
  });

  return (
    <>
      {isVideo(post) ? (
        <Box
          ref={ref}
          width="100%"
          bg={colors.figmaColors.black}
          borderRadius={isMobile ? "0" : "20px"}
          overflow={"hidden"}
        >
          {inView ? (
            <AspectRatio ratio={9 / 16}>
              <ReactPlayer
                url={post.media_url}
                controls
                muted
                playing={true}
                width="100%"
                height="100%"
              />
            </AspectRatio>
          ) : (
            <AspectRatio ratio={9 / 16}>
              <ReactPlayer
                url={post.media_url}
                controls
                muted
                playing={false}
                width="100%"
              />
            </AspectRatio>
          )}
        </Box>
      ) : (
        <Image
          src={post.media_url}
          borderRadius={isMobile ? "0" : "20px"}
          objectFit="cover"
          width={"100%"}
          height={"100%"}
        />
      )}
    </>
  );
}

export default PostMedia;
