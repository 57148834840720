import React from "react";

const IconArrowShort = ({
  width = "16",
  height = "16",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9467 5.45337H7.79341H4.05341C3.41341 5.45337 3.09341 6.2267 3.54674 6.68004L7.00008 10.1334C7.55341 10.6867 8.45341 10.6867 9.00674 10.1334L10.3201 8.82004L12.4601 6.68004C12.9067 6.2267 12.5867 5.45337 11.9467 5.45337Z"
      fill="#123EC9"
    />
  </svg>
);

export default IconArrowShort;
