//Card match footer details
import React from "react";
import { Flex } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import i18n from "../../../i18n/i18n";

const MatchFooterCard: React.FC<{
  day?: string;
  date: string;
  month?: string;
  hour: string;
  minutes: string;
  location: string;
  status?: string;
}> = ({ day = "", date, month, hour, minutes, location, status = "" }) => {
  const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  const getStatusTextColor = (status: string) => {
    return status.toLowerCase() === "playing"
      ? colors.figmaColors.secColor
      : colors.text.lightGray3;
  };

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Flex gap={1.5} alignItems={"center"}>
        <BodyText
          fontSize={13}
          fontWeight="regular"
          textAlign="center"
          color={getStatusTextColor(status)}
        >
          {status === "playing"
            ? `${i18n.t("today")}`
            : `${i18n.t("date")}: ${
                day ? capitalizeFirstLetter(day) + "/" : ""
              }${date}${month ? "/" + capitalizeFirstLetter(month) : ""}`}
        </BodyText>

        <BodyText
          fontSize={13}
          fontWeight="regular"
          color={getStatusTextColor(status)}
        >
          -
        </BodyText>
        <BodyText
          fontSize={13}
          fontWeight="regular"
          textAlign="center"
          color={getStatusTextColor(status)}
        >
          {status === "playing"
            ? `${i18n.t("now")}`
            : `${i18n.t("hour")}: ${hour}:${minutes}`}
        </BodyText>
      </Flex>
      <BodyText
        fontSize={13}
        fontWeight="regular"
        textAlign="center"
        color={colors.text.lightGray3}
      >
        {i18n.t("place")}: {location}
      </BodyText>
    </Flex>
  );
};

export default MatchFooterCard;
