import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactDispatchDateState } from "../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import BodyText from "./BodyText";
import moment from "moment-timezone";
import IconCalendar from "../../assets/icons/IconCalendar";

interface Props {
  required: boolean;
  label: string;
  placeholder: string;
  date: Date;
  setDate: ReactDispatchDateState;
  error?: string;
}
const SelectDate = ({
  date,
  setDate,
  placeholder,
  label,
  required,
  error,
}: Props): JSX.Element => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const datePickerRef = useRef(null);
  const inputRef = useRef(null);

  const formatDateToString = (date: Date): string => {
    return moment(date).format("DD-MM-YYYY");
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FormControl sx={{ position: "relative" }}>
      <Flex gap={1} flexDirection={"column"}>
        <Flex gap={1} alignItems="center">
          <FormLabel {...formLabelStyle}>{i18n.t(label)}</FormLabel>
          {required && (
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          )}
        </Flex>
        <InputGroup>
          <Input
            ref={inputRef}
            onClick={() => setOpenCalendar(!openCalendar)}
            placeholder={i18n.t(placeholder) + "..."}
            sx={inputStyle}
            value={
              date ? formatDateToString(date) : formatDateToString(new Date())
            }
            readOnly
          />
          <InputRightElement
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              pointerEvents: "none",
              right: "12px",
            }}
          >
            <IconButton
              icon={<IconCalendar stroke={colors.text.lightGray3} />}
              aria-label="Open calendar"
              variant="ghost"
              onClick={() => setOpenCalendar(!openCalendar)}
              _focus={{ boxShadow: "none" }}
            />
          </InputRightElement>
        </InputGroup>
        {error && (
          <BodyText
            paddingLeft={4}
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="regular"
            textColor={colors.text.red}
          >
            {error}
          </BodyText>
        )}

        {openCalendar && (
          <Flex justifyContent="center" ref={datePickerRef}>
            <DatePicker
              selected={date}
              onChange={(date: Date) => {
                setDate(date);
                setOpenCalendar(false);
              }}
              // dateFormat="dd MMMM yyyy"
              // popperPlacement="bottom"
              inline
            />
          </Flex>
        )}
      </Flex>
    </FormControl>
  );
};

export default SelectDate;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  borderRadius: "20px",
  height: "60px",
  color: colors.text.lightGray3,
};
