import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { steps } from "./containers/steps.container";
import {
  getCurrentTechnicalStaffFromTeam,
  getPlayersByTeamAndTournament,
  updateTournamentToTeam,
} from "../../../models/TeamModel";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import ItemPlayerPlantel from "./components/ItemPlayerPlantel";
import DeleteContentModal from "../../Modals/DeleteContentModal";
import MessageConfirmationComponent from "../../MessageConfirmationComponent";

const Plantel = () => {
  const { hideContainerModal, currentTeam, currentTournament } = appStore(
    (store) => store
  );
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(steps.default);
  const [players, setPlayers] = useState<User[]>([]);
  const [managers, setManagers] = useState<User[]>([]);
  const DEFAULT_NUMBER_PLAYERS_ON_FIELD = 3;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getPlayersByTeamAndTournament(
        currentTeam?.id,
        currentTournament?.id
      );
      setPlayers(response);
      console.log(response, "respinse");
      setLoading(false);
    })();
  }, [currentTournament?.id, currentTeam?.id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const technicalStaffResp = await getCurrentTechnicalStaffFromTeam(
        currentTeam.id,
        currentTournament.id
      );
      setManagers(technicalStaffResp);
      setLoading(false);
    })();
  }, [currentTeam.id, currentTournament.id]);

  const handleSuscribeTournament = async (register: boolean) => {
    try {
      setLoading(true);
      let minNumPlayers = currentTournament?.number_players_on_field
        ? currentTournament.number_players_on_field
        : DEFAULT_NUMBER_PLAYERS_ON_FIELD;

      if (players.length < minNumPlayers) {
        alert(
          `${i18n.t("msgMinSuscribePlayes")} ${minNumPlayers} ${i18n.t(
            "ofPlayes"
          )}`
        );
        return;
      }
      await updateTournamentToTeam(currentTeam?.id, currentTournament?.id, {
        team_suscribed: register,
      });
      if (register) {
        setActiveStep(steps.successInscriptionTournament);
      } else {
        setActiveStep(steps.successDeclineInvitation);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      {(activeStep === steps.default ||
        activeStep === steps.technicalStaff) && (
        <Flex direction={"column"} width={"100%"} gap={"16px"}>
          <ModalHeaderComponent
            title={i18n.t("theSquad")}
            subtitle={i18n.t("get_ready_for_the_tournament")}
            onCancel={hideContainerModal}
            isSaving={loading}
            hasMoreSteps={false}
            actionButtonsRequired={true}
            confirmText={i18n.t("register_for_tournament")}
            cancelText={i18n.t("declineInscription")}
            onConfirm={() => handleSuscribeTournament(true)}
            colorButtonCancel={colors.backgrounds.red}
            colorTextButtonCancel={colors.text.white}
            declineButton={true}
            declineCallback={()=>setActiveStep(steps.declineInvitation)}
          />
          <Flex justifyContent={"center"}>
            <Flex
              gap={"10px"}
              background={colors.backgrounds.gray0}
              padding={"6px"}
              borderRadius={"24px"}
            >
              <Button
                onClick={() => setActiveStep(steps.default)}
                sx={{
                  width: "120px",
                  background:
                    activeStep === steps.default
                      ? colors.figmaColors.secColor
                      : "none",
                  borderRadius: "18px",
                }}
              >
                <BodyText
                  fontSize={{ base: "12px", md: "13px" }}
                  fontWeight="medium"
                  textColor={
                    activeStep === steps.default
                      ? colors.text.white
                      : colors.text.lightGray3
                  }
                >
                  {i18n.t("players")}
                </BodyText>
              </Button>
              <Button
                onClick={() => setActiveStep(steps.technicalStaff)}
                sx={{
                  width: "120px",
                  background:
                    activeStep === steps.technicalStaff
                      ? colors.figmaColors.secColor
                      : "none",
                  borderRadius: "18px",
                }}
              >
                <BodyText
                  fontSize={{ base: "12px", md: "13px" }}
                  fontWeight="medium"
                  textColor={
                    activeStep === steps.technicalStaff
                      ? colors.text.white
                      : colors.text.lightGray3
                  }
                >
                  {i18n.t("technicalStaff")}
                </BodyText>
              </Button>
            </Flex>
          </Flex>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={"20px 0px"}
            >
              <Spinner size="lg" color="blue.500" />
            </Box>
          ) : (
            <Flex direction={"column"}>
              {activeStep === steps.default &&
                players.length > 0 &&
                players.map((item, index) => (
                  <ItemPlayerPlantel type="player" item={item} />
                ))}
              {activeStep === steps.technicalStaff &&
                managers.length > 0 &&
                managers.map((item, index) => (
                  <ItemPlayerPlantel type="directive" item={item} />
                ))}
            </Flex>
          )}
        </Flex>
      )}
      {activeStep === steps.declineInvitation && (
        <Flex width={"100%"} height={"100%"} direction={"column"}>
          <DeleteContentModal
            message={i18n.t("are_you_sure_decline_registration", {
              manager: currentTournament?.name,
            })}
            onCancel={hideContainerModal}
            onConfirm={() => handleSuscribeTournament(false)}
            loading={loading}
          />
        </Flex>
      )}
      {activeStep === steps.successDeclineInvitation && (
        <Flex width={"100%"} height={"100%"}>
          <MessageConfirmationComponent
            title={i18n.t("you_have_declined_registration")}
            subtitle={i18n.t("completedAction")}
            isDelete={true}
            onClick={hideContainerModal}
          />
        </Flex>
      )}
      {activeStep === steps.successInscriptionTournament && (
        <Flex width={"100%"} height={"100%"}>
          <MessageConfirmationComponent
            title={i18n.t("you_have_registered_for_tournament",{manager: currentTournament?.name})}
            subtitle={i18n.t("completedAction")}
            isDelete={false}
            onClick={hideContainerModal}
          />
        </Flex>
      )}
    </>
  );
};

export default Plantel;
