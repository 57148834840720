import React, { useState, useEffect } from "react";
import { Flex, Image, Spinner } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import ImageFromText from "../../../components/Common/ImageFromText";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import { TShirtIcon } from "../../../definitions/constants/IconsComponent";
import {
  getPlayersByTeamAndMatch,
  getPlayersByTeamAndTournament,
  getTechnicalStaffByTeamAndMatch,
} from "../../../models/TeamModel";
import { getMatchById } from "../../../models/MatchModel";
import { getTournamentById } from "../../../models/Tournament";
import { appStore } from "../../../zustand/globalStore";

const MatchInfoCard: React.FC<{
  isLineups?: boolean;
}> = ({ isLineups = false }) => {
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState<any>();
  const [tournament, setTournament] = useState<any>();
  const [mainPlayersFirstTeam, setMainPlayersFirstTeam] = useState([]);
  const [substitutesPlayersFirstTeam, setSubstitutesPlayersFirstTeam] =
    useState([]);
  const [technicalStaffFirstTeam, setTechnicalStaffFirstTeam] = useState([]);
  const [mainPlayersSecondTeam, setMainPlayersSecondTeam] = useState([]);
  const [substitutesPlayersSecondTeam, setSubstitutesPlayersSecondTeam] =
    useState([]);
  const [technicalStaffSecondTeam, setTechnicalStaffSecondTeam] = useState([]);
  const { matchId } = appStore((state) => state);

  const init = async () => {
    setLoading(true);
    let match = await getMatchById(matchId);
    let tournament = await getTournamentById(match.tournament_id);
    setTournament(tournament);
    setMatch(match);
    if (tournament.required_form) {
      if (match.first_team_id && match.sended_template_second_team) {
        const mainPlayers = await getPlayersByTeamAndMatch(
          match.first_team_id,
          matchId,
          "mainPlayers"
        );
        setMainPlayersFirstTeam(mainPlayers);
        const substitutesPlayers = await getPlayersByTeamAndMatch(
          match.first_team_id,
          matchId,
          "sustitutibePlayers"
        );
        setSubstitutesPlayersFirstTeam(substitutesPlayers);
        const technicalStaff = await getTechnicalStaffByTeamAndMatch(
          match.first_team_id,
          matchId
        );
        setTechnicalStaffFirstTeam(technicalStaff);
      }
    } else {
      let playersFirstTeam = await getPlayersByTeamAndTournament(
        match.first_team_id,
        tournament.id
      );
      let playersSecondTeam = await getPlayersByTeamAndTournament(
        match.second_team_id,
        tournament.id
      );
      setMainPlayersFirstTeam(playersFirstTeam);
      setSubstitutesPlayersFirstTeam(playersFirstTeam);
      setTechnicalStaffFirstTeam(playersFirstTeam);
      setMainPlayersSecondTeam(playersSecondTeam);
      setSubstitutesPlayersSecondTeam(playersSecondTeam);
      setTechnicalStaffSecondTeam(playersSecondTeam);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <Flex justify="center" align="center">
        <Spinner size="lg" color="blue.500" />
      </Flex>
    );
  }

  const RenderItemPlayer = ({ user }: { user: any }) => {
    const initials = `${user.firstname?.charAt(0) || ""} ${
      user.lastname?.charAt(0) || ""
    }`;

    return (
      <Flex
        p={3}
        border="1px"
        borderRadius={20}
        alignItems={"center"}
        justifyContent={"space-between"}
        background={
          isLineups ? colors.backgrounds.white : colors.figmaColors.appBgColor
        }
        borderColor={isLineups ? "transparent" : colors.figmaColors.grey1}
      >
        <Flex gap={2}>
          {user.image_url ? (
            <Image
              src={user.image_url}
              boxSize={isLineups ? "45px" : "60px"}
              borderRadius={isLineups ? "16px" : "full"}
              objectFit={"cover"}
            />
          ) : (
            <ImageFromText
              sizeIcon={{
                width: isLineups ? "45px" : "60px",
                height: isLineups ? "45px" : "60px",
                borderRadius: isLineups ? "16px" : "100%",
                padding: isLineups ? "0" : "10px",
              }}
              sizeText="large"
              text={initials}
            />
          )}
          <Flex flexDirection={"column"} justifyContent={"center"}>
            <BodyText
              fontSize={14}
              fontWeight="medium"
              color={colors.text.black}
            >
              {user.firstname} {user.lastname}
            </BodyText>
            <Flex gap={1} alignItems={"center"}>
              <ImageFromText
                sizeIcon={{ width: "24px", height: "20px", borderRadius: 0 }}
                sizeText="small"
                text={initials}
              />
              <BodyText
                fontSize={12}
                fontWeight="medium"
                color={colors.text.lightGray3}
              >
                {user.firstname} {user.lastname}
              </BodyText>
            </Flex>
            <Flex flexDirection={"column"}>
              {user.oldYears && (
                <BodyText
                  fontWeight="regular"
                  size="small"
                  color={colors.text.black}
                  style={{ marginLeft: 4 }}
                >
                  {user.oldYears} Años
                </BodyText>
              )}
              {user.number && (
                <BodyText
                  fontWeight="regular"
                  size="small"
                  color={colors.text.black}
                  style={{ marginLeft: 4 }}
                >
                  Nº {user.number}
                </BodyText>
              )}
            </Flex>
          </Flex>
        </Flex>
        {isLineups ? (
          <Flex
            p={"5px 10px"}
            gap={1}
            borderRadius={20}
            background={colors.figmaColors.grey1}
          >
            <TShirtIcon />
            <BodyText
              fontSize={11}
              fontWeight="bold"
              color={colors.text.lightGray3}
            >
              #
            </BodyText>
          </Flex>
        ) : (
          <ButtonComponent variant="variantInfo" buttonName="matches">
            {i18n.t("view_info")}
          </ButtonComponent>
        )}
      </Flex>
    );
  };

  const SubstitutesPlayer = ({ users }: any) => {
    return (
      <>
        {users.map((player, index) => {
          return <RenderItemPlayer user={player} key={index} />;
        })}
      </>
    );
  };

  const MainPlayer = ({ users }: any) => {
    return (
      <>
        {users.map((player, index) => {
          return <RenderItemPlayer user={player} key={index} />;
        })}
      </>
    );
  };

  const TechnicalStaff = ({ users }: any) => {
    return (
      <>
        {users.map((player, index) => {
          return <RenderItemPlayer user={player} key={index} />;
        })}
      </>
    );
  };

  return (
    <Flex gap={3} flexDirection={"column"}>
      {!isLineups && (
        <BodyText fontSize={13} fontWeight="semiBold" color={colors.text.black}>
          {i18n.t("follow_teams")}
        </BodyText>
      )}
      <MainPlayer users={mainPlayersFirstTeam} />
      <SubstitutesPlayer users={substitutesPlayersFirstTeam} />
      <TechnicalStaff users={technicalStaffFirstTeam} />
    </Flex>
  );
};

export default MatchInfoCard;
