import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore } from "../../../zustand/globalStore";
import BodyText from "../../../components/Common/BodyText";
import OptionCard from "../../../components/Common/OptionCard";
import HeaderDefaultOptions from "../../../components/Common/HeaderDefaultOptions";
import {
  ProfileCircle,
  PeopleIcon,
  MessageSquareIcon,
} from "../../../definitions/constants/IconsComponent";
import i18n from "../../../i18n/i18n";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import DeleteContentModal from "../../../components/Modals/DeleteContentModal";
import MessageConfirmationComponent from "../../../components/MessageConfirmationComponent";
import IconTrash from "../../../assets/icons/IconTrash";
import CreateAndEditTeam from "../../../components/TeamsComponent/CreateAndEditTeam";
import ManagersOptionTeams from "./Options/Managers/Managers";
import InviteManagers from "./Options/Managers/InviteManagers";
import RequestsSent from "./Options/RequestsSent/RequestsSent";
import DeleteTeam from "./Options/DeleteTeam/DeleteTeam";
import { stepsTeams } from "../../../definitions/constants/GlobalConstants";

function UpdateOrViewTeamData() {
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(stepsTeams.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const { hideContainerModal } = appStore((state) => state);
  console.log("The current step is: ", currentStep);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={
        currentStep === stepsTeams.deleteTeam ||
        currentStep === stepsTeams.deleteManagers ||
        currentStep === stepsTeams.sureDeletion
          ? 8
          : 4
      }
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {currentStep === stepsTeams.default && (
        <HeaderDefaultOptions
          title={i18n.t("teamOptions")}
          description={i18n.t("StartManagingTeam")}
        />
      )}
      {/* Content for header modals */}
      {currentStep === stepsTeams.editTeam && null}
      {currentStep === stepsTeams.managers && (
        <ModalHeaderComponent
          title={i18n.t("teamManagers")}
          subtitle={i18n.t("StartManagingTeam")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTeams.default)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={false}
        />
      )}
      {currentStep === stepsTeams.inviteManagers && (
        <InviteManagers
          goToStep={goToStep}
          hideContainerModal={hideContainerModal}
        />
      )}
      {currentStep === stepsTeams.editManagers && (
        <ModalHeaderComponent
          title={i18n.t("editManager")}
          subtitle={i18n.t("StartManagingTeam")}
          confirmText={i18n.t("save")}
          onCancel={hideContainerModal}
          onBack={() => goToStep(stepsTeams.managers)}
          onConfirm={() => goToStep(stepsTeams.successEditManagers)}
          isSaving={isSaving}
          hasMoreSteps={true}
          actionButtonsRequired={true}
        />
      )}
      {currentStep === stepsTeams.requestsSent && (
        <RequestsSent
          goToStep={goToStep}
          hideContainerModal={hideContainerModal}
        />
      )}
      {/* Content for body modals */}
      {currentStep === stepsTeams.default && (
        <Flex flexDirection="column" gap={4} flexGrow={1}>
          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("profile")}
            </BodyText>
            <OptionCard
              title={i18n.t("profileTeam")}
              description={i18n.t("renewTeam")}
              icon={<ProfileCircle />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTeams.editTeam)}
            />
          </Flex>

          <Flex flexDirection="column" gap={2}>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("additionalSettings")}
            </BodyText>
            <OptionCard
              title={i18n.t("managers")}
              description={i18n.t("addManagers")}
              icon={<PeopleIcon />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTeams.managers)}
            />
            {/* <OptionCard
              title={i18n.t("applicationSent")}
              description={i18n.t("checkStatusInvitations")}
              icon={<MessageSquareIcon />}
              iconColor={colors.figmaColors.borderCard}
              onClick={() => goToStep(stepsTeams.requestsSent)}
            /> */}
            <OptionCard
              title={i18n.t("deleteTeam")}
              description={i18n.t("deleteTeamData")}
              icon={<IconTrash stroke={colors.backgrounds.white} />}
              iconColor={colors.backgrounds.red}
              onClick={() => goToStep(stepsTeams.deleteTeam)}
            />
          </Flex>
        </Flex>
      )}
      {/* To do content of (editTeam) */}
      {currentStep === stepsTeams.editTeam && (
        <CreateAndEditTeam
          action="edit"
          steps={stepsTeams}
          goToStep={goToStep}
          currentStep={currentStep}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {currentStep === stepsTeams.successEdit && (
        <MessageConfirmationComponent
          title={i18n.t("teamUpdated")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTeams.managers && (
        <ManagersOptionTeams
          goToStep={goToStep}
          hideContainerModal={hideContainerModal}
        />
      )}
      {/* To do content of (inviteManagers) */}
      {currentStep === stepsTeams.inviteManagers && null}
      {currentStep === stepsTeams.successInvite && (
        <MessageConfirmationComponent
          title={i18n.t("invitationSuccessfully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {/* To do content of (editManagers) */}
      {currentStep === stepsTeams.editManagers && null}
      {currentStep === stepsTeams.successEditManagers && (
        <MessageConfirmationComponent
          title={i18n.t("updatedDirective")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {currentStep === stepsTeams.deleteManagers && (
        <DeleteContentModal
          message={i18n.t("sureDeleteManager", { manager: "test" })}
          onCancel={hideContainerModal}
          onConfirm={() => goToStep(stepsTeams.successDeleteManagers)}
        />
      )}
      {currentStep === stepsTeams.successDeleteManagers && (
        <MessageConfirmationComponent
          title={i18n.t("managerDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
      {/* To do content of (requestsSent) */}
      {currentStep === stepsTeams.requestsSent && null}
      {currentStep === stepsTeams.deleteTeam && (
        <DeleteContentModal
          message={i18n.t("sureDeleteTeam")}
          subMessage={i18n.t("allDeleteTeamData")}
          onCancel={hideContainerModal}
          onConfirm={() => goToStep(stepsTeams.sureDeletion)}
        />
      )}
      {currentStep === stepsTeams.sureDeletion && (
        <DeleteTeam goToStep={goToStep} />
      )}
      {currentStep === stepsTeams.successDelete && (
        <MessageConfirmationComponent
          title={i18n.t("teamDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
        />
      )}
    </Box>
  );
}

export default UpdateOrViewTeamData;
