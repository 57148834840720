import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Link as ChakraLink,
  Box,
  Flex,
  Heading,
  Button,
  Image,
  useMediaQuery,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  WuamballIcon,
  SearchICon,
} from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import i18n from "../../i18n/i18n";
import UserMenuHeader from "./UserMenuHeader";
import NotificationModal from "../Common/NotificationModal";
import MenuItemComponent from "../Common/MenuItemComponent";
import ButtonComponent from "../Common/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { appStore } from "../../zustand/globalStore";
import ModalContainerComponent from "../ModalContainerComponent";
import SearchResults from "./SearchResult";
import { consumeApi } from "../../utils/tools";
import { TypeViewToShow } from "../../definitions/enums/GlobalEnums";
import IconNotification from "../../assets/icons/IconNotification";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";

function HeaderAuthenticated({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isResponsive] = useMediaQuery("(max-width: 992px)");
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);
  const { userData, activeButton, setActiveButton, showContainerModal } =
    appStore((state) => state);

  const handleClickOutside = (event) => {
    if (
      !inputRef.current?.contains(event.target) &&
      !resultsRef.current?.contains(event.target)
    ) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setShowResults(true);
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    searchUsers(text);
  };

  useEffect(() => {
    searchUsers(searchText);
  }, [searchText]);

  const handleLoadMore = () => {
    if (hasMore && currentPage < pages.length - 1) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setSearchResults((prevResults) => [...prevResults, ...pages[nextPage]]);
      setHasMore(nextPage < pages.length - 1);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      handleLoadMore();
    }
  };

  const renderMenuItem = (name, buttonName, icon) => (
    <MenuItemComponent
      activeItem={true}
      activeButton={activeButton}
      buttonName={buttonName}
      onClick={() => {
        setActiveButton(buttonName);
        navigate(`/${buttonName}`);
      }}
    >
      {i18n.t(name)}
      {icon && <IconArrowLinear />}
    </MenuItemComponent>
  );

  const brandIcon = (
    <Heading as={Link} fontSize="xl">
      <ChakraLink as={Link} to={"/explore"}>
        {isMobile ? (
          <Image
            src={require("../../assets/images/wuamballIcon.png")}
            boxSize="36px"
            minWidth="36px"
          />
        ) : (
          <WuamballIcon width="100%" height="36px" />
        )}
      </ChakraLink>
    </Heading>
  );

  const searchBar = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      maxWidth={400}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          top="50%"
          transform="translateY(-50%)"
        >
          <SearchICon color="black" />
        </InputLeftElement>
        <Input
          ref={inputRef}
          type="text"
          fontSize="13px"
          borderColor={colors.text.white}
          style={{
            fontWeight: "400",
            height: "48px",
            borderRadius: "10px",
            borderWidth: "1px",
            borderColor: colors.text.white,
            background: colors.figmaColors.grey0,
            color: colors.figmaColors.grey3,
          }}
          placeholder={i18n.t("search")}
          value={searchText}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
        />
      </InputGroup>
      {showResults && (
        <SearchResults
          ref={resultsRef}
          results={searchResults}
          onSelect={(value) => {
            console.log("item selected", value);
            setShowResults(false);
          }}
          onLoadMore={handleScroll}
        />
      )}
    </Box>
  );

  const searchUsers = async (text: string) => {
    if (text.length < 3) return;
    const usersAutocomplete = await consumeApi(
      `users/autocomplete_search`,
      "POST",
      {
        query: text.toLocaleLowerCase(),
        limit: 20,
        type_object: "users",
      }
    );

    const newResults = usersAutocomplete?.data?.data || [];
    console.log(`Received ${newResults.length} total items`);

    if (newResults.length === 0) {
      setPages([]);
      setSearchResults([]);
      setShowResults(true);
      return;
    }

    const pages = [];
    for (let i = 0; i < newResults.length; i += 20) {
      pages.push(newResults.slice(i, i + 20));
    }

    setPages(pages);
    setSearchResults(pages[0]);
    setHasMore(pages.length > 1);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (location.pathname === "/matches") {
      setActiveButton("matches");
    } else if (location.pathname === "/tournaments") {
      setActiveButton("tournaments");
    } else if (location.pathname === "/explore") {
      setActiveButton("explore");
    }
  }, [location.pathname, setActiveButton]);

  const handleOpenProfile = async () => {
    showContainerModal(TypeViewToShow.UpdateOrViewProfileData);
  };

  const handleOpenDocuments = async () => {
    showContainerModal(TypeViewToShow.ShowDocumentsUserView);
  };

  const handleOpenSupport = async () => {
    showContainerModal(TypeViewToShow.ShowSupportGroupView);
  };

  const handleDeleteAccount = async () => {
    showContainerModal(TypeViewToShow.SureDeleteAccountView);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const renderMenuItems = () => (
    <UserMenuHeader
      handleOpenProfile={handleOpenProfile}
      handleOpenDocuments={handleOpenDocuments}
      handleOpenSupport={handleOpenSupport}
      handleDeleteAccount={handleDeleteAccount}
      handleSignOut={handleSignOut}
      displayName={`${userData?.firstname} ${userData?.lastname}`}
      userName={userData?.username}
      userPhoto={userData?.image_url}
      userData={userData}
    >
      {isResponsive && (
        <>
          {renderMenuItem("explore", "explore", true)}
          {renderMenuItem("myMatches", "matches", true)}
          {renderMenuItem("cupsTournamentTeam", "tournaments", true)}
        </>
      )}
    </UserMenuHeader>
  );

  return (
    <>
      <Box
        p={{
          base: "8px 16px",
          lg: "12px 24px",
          xl: "12px 32px",
        }}
        bg={colors.text.white}
        boxShadow="md"
        width="100%"
        position="sticky"
        zIndex={10}
        borderRadius={24}
        shadow={"0px 4px 20px 0px rgba(0, 0, 0, 0.07)"}
      >
        <Flex align="center" justify="space-between" gap={2.5} margin="auto">
          {isResponsive ? (
            <>
              {brandIcon}
              {searchBar}
              <Box>
                <Flex align="center" justify="space-between" gap={2}>
                  <Button
                    borderRadius="50%"
                    boxSize="48px"
                    border="1px"
                    p={0}
                    borderColor={colors.backgrounds.gray1}
                    bg={colors.backgrounds.gray0}
                    onClick={onOpen}
                  >
                    <IconNotification
                      stroke={colors.figmaColors.secColor}
                      strokeWidth="0.75"
                      width="20px"
                      height="20px"
                    />
                  </Button>
                  {renderMenuItems()}
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="row"
                gap={2}
              >
                {brandIcon}
                <ButtonComponent
                  variant="variantMenu"
                  buttonName="explore"
                  activeButton={activeButton}
                  onClick={() => {
                    setActiveButton("explore");
                    navigate("/explore");
                  }}
                >
                  {i18n.t("explore")}
                </ButtonComponent>
                <ButtonComponent
                  variant="variantMenu"
                  buttonName="matches"
                  activeButton={activeButton}
                  onClick={() => {
                    setActiveButton("matches");
                    navigate("/matches");
                  }}
                >
                  {i18n.t("myMatches")}
                </ButtonComponent>
                <ButtonComponent
                  variant="variantMenu"
                  buttonName="tournaments"
                  activeButton={activeButton}
                  width="auto"
                  justifyContent="flex-start"
                  onClick={() => {
                    setActiveButton("tournaments");
                    navigate("/tournaments");
                  }}
                >
                  <Box
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {i18n.t("cupsTournamentTeam")}
                  </Box>
                </ButtonComponent>
              </Box>
              {searchBar}
              <Box>
                <Flex align="center" justify="space-between" gap={4}>
                  <Button
                    borderRadius="50%"
                    boxSize="48px"
                    border="1px"
                    p={0}
                    borderColor={colors.backgrounds.gray1}
                    bg={colors.backgrounds.gray0}
                    onClick={onOpen}
                  >
                    <IconNotification
                      stroke={colors.figmaColors.secColor}
                      strokeWidth="0.75"
                      width="20px"
                      height="20px"
                    />
                  </Button>
                  {renderMenuItems()}
                </Flex>
              </Box>
            </>
          )}
        </Flex>
      </Box>
      <NotificationModal isOpen={isOpen} onClose={onClose} />
      <Box minW={"100%"} justifyContent={"center"} display={"flex"}>
        <Box width={"100%"}>{children}</Box>
      </Box>
      <ModalContainerComponent />
    </>
  );
}

export default HeaderAuthenticated;
