import React from "react";

const IconCalendar = ({
  fill = "none",
  stroke = "currentColor",
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.33203 2V5"
      stroke={stroke}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.332 2V5"
      stroke={stroke}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.83203 9.09009H20.832"
      stroke={stroke}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.332 8.5V17C21.332 20 19.832 22 16.332 22H8.33203C4.83203 22 3.33203 20 3.33203 17V8.5C3.33203 5.5 4.83203 3.5 8.33203 3.5H16.332C19.832 3.5 21.332 5.5 21.332 8.5Z"
      stroke={stroke}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3265 13.7H12.3355"
      stroke={stroke}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.62732 13.7H8.6363"
      stroke={stroke}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.62732 16.7H8.6363"
      stroke="#CCCCCC"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default IconCalendar;
