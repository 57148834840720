import React from "react";
import { useMediaQuery, Box, Text, Flex, Image } from "@chakra-ui/react";
import { isMacOs, isIOS } from "react-device-detect";
import { colors } from "../../definitions/constants/GlobalStyles";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";
import {
  AuthorProps,
  CommentsData,
} from "../../definitions/interfaces/GlobalInterfaces";
import CommentAuthorComponent from "./CommentAuthorComponent";

const goTo = () => {
  const link = isMacOs || isIOS ? appStoreUrl : playStoreUrl;
  window.open(link, "_blank");
};

function CommentsList({ post }: AuthorProps) {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const CommentsMax = 4;

  return (
    <>
      <Box>
        {post.comments.length > CommentsMax
          ? post.comments
              .slice(0, CommentsMax)
              .map((comment: CommentsData, index: number) => (
                <CommentAuthorComponent key={index} comment={comment} />
              ))
          : post.comments.map((comment: CommentsData, index: number) => (
              <CommentAuthorComponent key={index} comment={comment} />
            ))}
      </Box>

      <Flex
        paddingLeft={isMobile ? "30px" : "0"}
        marginBottom={2}
        onClick={goTo}
        cursor="pointer"
      >
        {post.comments.length >= CommentsMax + 1 &&
        post.comments[CommentsMax + 1] ? (
          <Image
            boxSize="25px"
            borderRadius="100%"
            src={post.comments[CommentsMax + 1].image_url}
          />
        ) : null}
        {post.comments.length >= CommentsMax + 2 &&
        post.comments[CommentsMax + 2] ? (
          <Image
            boxSize="25px"
            borderRadius="100%"
            src={post.comments[CommentsMax + 2].image_url}
          />
        ) : null}
        {post.comments.length > CommentsMax ? (
          <Box
            bg={colors.figmaColors.secColor}
            boxSize="25px"
            borderRadius="full"
          >
            <Flex align="center" justifyContent="center" height="100%">
              <Text color={colors.text.white} fontWeight={600} fontSize="11px">
                +{post.comments.length - CommentsMax}
              </Text>
            </Flex>
          </Box>
        ) : null}
      </Flex>
    </>
  );
}

export default CommentsList;
