import React, { forwardRef } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../Common/BodyText";
import ItemSearch from "./ItemSearch";
import i18n from "../../i18n/i18n";

interface SearchResultsProps {
  results: any[];
  onSelect: (value: string) => void;
  onLoadMore: (event: React.UIEvent<HTMLDivElement>) => void;
}

const SearchResults = forwardRef<HTMLDivElement, SearchResultsProps>(
  ({ results, onSelect, onLoadMore }, ref) => {
    if (results.length === 0) return null;
    return (
      <Box
        ref={ref}
        onScroll={onLoadMore}
        position="absolute"
        left={{ base: "0", sm: "auto" }}
        right={{ base: "0", sm: "auto" }}
        zIndex={10}
        bg="white"
        boxShadow="md"
        borderRadius="16px"
        width={{
          base: "100%",
          sm: "400px",
        }}
        top={{
          base: "68px",
          sm: "72px",
          lg: "80px",
        }}
        maxHeight="350px"
        overflowY="auto"
        overflowX="hidden"
        border={1}
        borderColor={"black"}
        padding={"8px 16px"}
      >
        <BodyText
          fontWeight="regular"
          size="extraSmall"
          color={colors.text.black}
        >
          {i18n.t("results")}
        </BodyText>
        <VStack spacing={1} marginTop={1}>
          {results.length === 0 ? (
            <BodyText color={colors.text.black}>
              {i18n.t("no_results")}
            </BodyText>
          ) : (
            results.map((result) => (
              <ItemSearch key={result.id} item={result} onSelect={onSelect} />
            ))
          )}
        </VStack>
      </Box>
    );
  }
);

export default SearchResults;
