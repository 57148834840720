import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  queryEqual,
  setDoc,
  where,
} from "firebase/firestore";
// import { arrayElementLooper } from "../utils/tools";
import { db } from "../config/firebase";
import {
  addNotificationToUser,
  cancelNotificationToUser,
  getUserById,
  getUserByUsername,
  saveOrUpdateUser,
  updateNotificationToUser,
} from "./UserModel";
// import uuid from "react-native-uuid";
// import { TypeNotifications } from "../constants/GlobalTypesAndEnums";
import { v4 as uuidV4 } from "uuid";
import {
  APPLICATION_TABLE_NAME,
  GROUP_TABLE_NAME,
  INCIDENCE_TABLE_NAME,
  MANAGERS_TABLE_NAME,
  MATCH_TABLE_NAME,
  PHASES_TABLE_NAME,
  PLACES_TABLE_NAME,
  PLAYERS_TABLE_NAME,
  TEAM_TABLE_NAME,
  TECHNICAL_STAFF_TABLE_NAME,
  TOURNAMENT_TABLE_NAME,
  USER_TABLE_NAME,
} from "./ModelDefinition";
// import { Alert } from "react-native";
import { verifyTechnicalStaffTeamTournamentInvitation } from "./Tournament";
import { arrayElementLooper } from "../utils/tools";
import { TypeNotifications } from "../definitions/enums/GlobalEnums";
import i18n from "../i18n/i18n";

export const createTeam = async (userId: string, data: any) => {
  data.created_at = new Date();
  data.user_id = userId;
  if (!data.id) {
    data.id = uuidV4().toString();
  }
  //add team to teams
  await setDoc(doc(db, `${TEAM_TABLE_NAME}`, data.id), data, { merge: true });
  //create a user type team
  createUserForTeam(data);
  //add team to user
  await setDoc(
    doc(db, `${USER_TABLE_NAME}/${userId}/${TEAM_TABLE_NAME}`, data.id),
    {
      created_at: new Date(),
      team_id: data.id,
      rol: "owner",
    },
    { merge: true }
  );
  return data;
};

export const createUserForTeam = async (team: any) => {
  let username = team.name
    .toLowerCase()
    .split(" ")
    .join("")
    .split("@")
    .join("");
  const usersByUsername = await getUserByUsername(username);
  if (usersByUsername.length > 0) {
    username = `${username}${usersByUsername.length}`;
  }
  let userToTeam: any = {
    username: username,
    firstname: team.name,
    lastname: "",
    email: team.email,
    id: team.id,
    type: "team",
  };
  if (team.media_url) {
    userToTeam.image_url = team.media_url;
  }
  let teamData = {
    ...userToTeam,
    ...team,
  };
  await saveOrUpdateUser(team.id, teamData);
};

export const searchTeams = async (search: string) => {
  let searchString = search.toLowerCase().split(" ").join("");
  const userSearchCollectionRef = collection(db, TEAM_TABLE_NAME);
  const q = query(
    userSearchCollectionRef,
    where("search_index", "array-contains", searchString),
    limit(50)
  );
  const data = await getDocs(q);
  let dataArray = arrayElementLooper(data.docs);
  return dataArray;
};

export const updateTeam = async (teamId: string, data: any) => {
  let returnData: any = false;
  await setDoc(doc(db, TEAM_TABLE_NAME, teamId), data, { merge: true })
    .then(() => {
      returnData = true;
    })
    .catch((err) => {
      console.log(err);
    });
  if (data.media_url) {
    data.image_url = data.media_url;
  }
  await saveOrUpdateUser(teamId, data);
  return returnData;
};

export const getTeamById = async (teamId: string) => {
  const postDoc = doc(db, TEAM_TABLE_NAME, teamId);
  let returnData = null;
  await getDoc(postDoc)
    .then((snapshot: any) => {
      if (snapshot.exists()) {
        returnData = snapshot.data();
        returnData.id = snapshot.id;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return returnData;
};

export const setupIndexTeams = async () => {
  const teamsCollectionRef = collection(db, TEAM_TABLE_NAME);
  const data = await getDocs(teamsCollectionRef);
  let dataArray = arrayElementLooper(data.docs);
  for (let team of dataArray) {
    setupIndexSearchTeamId(team.id);
  }
};

export const setupIndexSearchTeamId = async (teamId: string) => {
  let team: any = await getTeamById(teamId);
  let indexSet: any = new Set();
  let user: any = await getUserById(team.user_id);
  let itemsToIndex = team.name.split(" ");
  if (user.username)
    itemsToIndex.push(
      `${team.name.split(" ").join("").toLowerCase()}${user.username}`
    );

  for (let i = 0; i < itemsToIndex.length; i++) {
    for (let y = 1; y < itemsToIndex[i].length + 1; y++) {
      indexSet.add(itemsToIndex[i].substring(0, y).toLowerCase());
    }
  }
  await setDoc(
    doc(db, TEAM_TABLE_NAME, teamId),
    { search_index: [...indexSet] },
    { merge: true }
  );
};

export const cancelIndexTeam = async (teamId: string) => {
  await setDoc(
    doc(db, TEAM_TABLE_NAME, teamId),
    { search_index: [] },
    { merge: true }
  );
};

export const removePlayerApplication = async (userData: any) => {
  // remove  user as player to tournament
  let commentDoc1 = doc(
    db,
    `${USER_TABLE_NAME}/${userData.user_id}/${TEAM_TABLE_NAME}`,
    userData.user_team_id
  );
  await deleteDoc(commentDoc1);
  // remove user to team on tournament
  let commentDoc2 = doc(
    db,
    `${USER_TABLE_NAME}/${userData.user_id}/${TOURNAMENT_TABLE_NAME}`,
    userData.user_team_id
  );
  await deleteDoc(commentDoc2);
  let commentDoc3 = doc(
    db,
    `${TEAM_TABLE_NAME}/${userData.team_id}/${TOURNAMENT_TABLE_NAME}/${userData.tournament_id}/${PLAYERS_TABLE_NAME}`,
    userData.user_id
  );
  await deleteDoc(commentDoc3);
  //remove from tournament
  const playerDoc = doc(
    db,
    `${TOURNAMENT_TABLE_NAME}/${userData.tournament_id}/${PLAYERS_TABLE_NAME}`,
    userData.user_id
  );
  await deleteDoc(playerDoc);

  return;
};

export const getTeamsByUser = async (userId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${userId}/${TEAM_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef);
  const data = await getDocs(q);
  const teamUser = arrayElementLooper(data.docs);
  return teamUser;
};

export const getTeamsOwnByUser = async (userId: string) => {
  const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  const q = query(teamsCollectionRef, where("user_id", "==", userId));
  const data = await getDocs(q);
  const teamUser = arrayElementLooper(data.docs);
  return teamUser;
};

/**
 *
 * @param userFrom Logged user
 * @param userTo User to which the invitation will be sent
 */
export const inviteUserAsPlayer = async (
  userFrom: string,
  userTo: string,
  tournamentId: string,
  teamId: string,
  tournamentName: string,
  teamName: string
) => {
  let notification = await addNotificationToUser(userTo, {
    created_at: new Date(),
    push_notification_sended: false,
    origin_user_id: userFrom,
    text: `${teamName} ${i18n.t("requestPlayerTournament")} ${tournamentName}`,
    title: i18n.t("newSigning"),
    type: TypeNotifications.playerInvitation,
    show_alert: true,
    team_id: teamId,
    tournament_id: tournamentId,
    team_name: teamName,
    tournament_name: tournamentName,
    user_id: userTo,
  });
  await addDoc(
    collection(db, `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`),
    {
      created_at: new Date(),
      user_id_from: userFrom,
      user_id_to: userTo,
      tournament_id: tournamentId,
      rol: "player",
      status: "sended",
      type: TypeNotifications.playerInvitation,
      notification_id: notification.id,
    }
  );
};

export const removeInvitationToPlayer = async (
  teamId: string,
  application: any,
  userToId: string
) => {
  await cancelNotificationToUser(userToId, application.notification_id);
  const commentDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
    application.id
  );
  await deleteDoc(commentDoc)
    .then(() => {
      console.log("Applications has been deleted");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getInvitationPlayerToTeam = async (
  teamId: string,
  tournamentId: string,
  userTo: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`
  );
  const q = query(
    teamsCollectionRef,
    where("type", "==", TypeNotifications.playerInvitation),
    where("user_id_to", "==", userTo),
    where("tournament_id", "==", tournamentId)
  );
  const data = await getDocs(q);
  let teamUser = arrayElementLooper(data.docs);
  let returnData = teamUser.length > 0 ? teamUser[0] : null;
  return returnData;
};

export const inviteUserAsTechnicalStaff = async (
  userFrom: string,
  userTo: string,
  tournamentId: string,
  teamId: string,
  tournamentName: string,
  teamName: string,
  rol: string
) => {
  let resp = await verifyTechnicalStaffTeamTournamentInvitation(
    teamId,
    tournamentId,
    userTo
  );
  if (resp) {
    alert(i18n.t("msgTeamAlreadyRegister"));
    return;
  }
  ///545645455654654656654654
  const teamUid = uuidV4().toString();
  await setDoc(
    doc(db, `${USER_TABLE_NAME}/${userTo}/${TEAM_TABLE_NAME}`, teamUid),
    {
      created_at: new Date(),
      user_id: userTo,
      team_id: teamId,
      tournament_id: tournamentId,
      rol: rol,
    },
    { merge: true }
  );

  // add  user as player to tournament
  await setDoc(
    doc(db, `${USER_TABLE_NAME}/${userTo}/${TOURNAMENT_TABLE_NAME}`, teamUid),
    {
      created_at: new Date(),
      user_id: userTo,
      team_id: teamId,
      tournament_id: tournamentId,
      rol: rol,
    },
    { merge: true }
  );

  //add user to team on tournament
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TECHNICAL_STAFF_TABLE_NAME}`,
      userTo
    ),
    {
      id: userTo,
      created_at: new Date(),
      tournament_id: tournamentId,
      user_id: userTo,
      team_id: teamId,
      rol: rol,
      user_team_id: teamUid,
    },
    { merge: true }
  );

  let notification = await addNotificationToUser(userTo, {
    created_at: new Date(),
    push_notification_sended: false,
    origin_user_id: userFrom,
    text: `${teamName} ${i18n.t("requestSignAs")} ${rol} ${i18n.t(
      "forTheTournament"
    )} ${tournamentName}`,
    title: i18n.t("newInvitation"),
    type: TypeNotifications.technicalStaff,
    show_alert: true,
    team_id: teamId,
    tournament_id: tournamentId,
    user_id: userTo,
    team_name: teamName,
    tournament_name: tournamentName,
    rol: rol,
    user_team_id: teamUid,
  });

  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
      notification.id
    ),
    {
      created_at: new Date(),
      user_id_from: userFrom,
      user_id_to: userTo,
      tournament_id: tournamentId,
      rol: rol,
      notification_id: notification.id,
      status: "acepted",
      type: TypeNotifications.technicalStaff,
    }
  );
};

export const removeInvitationToTechnicalStuff = async (
  teamId: string,
  application: any,
  userToId: string
) => {
  await cancelNotificationToUser(userToId, application.notification_id);
  const commentDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
    application.id
  );
  await deleteDoc(commentDoc)
    .then(() => {
      console.log("Applications has been deleted");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getInvitationTechnicalStaffToTeam = async (
  teamId: string,
  tournamentId: string,
  userTo: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`
  );
  const q = query(
    teamsCollectionRef,
    where("type", "==", "technical_staff"),
    where("user_id_to", "==", userTo),
    where("tournament_id", "==", tournamentId)
  );
  const data = await getDocs(q);
  let teamUser = arrayElementLooper(data.docs);
  let returnData = teamUser.length > 0 ? teamUser[0] : null;
  return returnData;
};

export const inviteUserAsManagerTeam = async (
  userFrom: string,
  userTo: string,
  teamId: string,
  teamName: string,
  rol: string
) => {
  const managerUid = uuidV4().toString();
  await setDoc(
    doc(db, `${TEAM_TABLE_NAME}/${teamId}/${MANAGERS_TABLE_NAME}`, managerUid),
    {
      id: managerUid,
      created_at: new Date(),
      user_id: userTo,
      rol: rol,
      status: "active",
    },
    { merge: true }
  );

  let notification = await addNotificationToUser(userTo, {
    created_at: new Date(),
    push_notification_sended: false,
    origin_user_id: userFrom,
    text: `${teamName} ${i18n.t("requestAddAs")} ${rol}`,
    title: i18n.t("newInvitation"),
    type: TypeNotifications.managerInvitation,
    show_alert: true,
    team_id: teamId,
    user_id: userTo,
    team_name: teamName,
    rol: rol,
    manager_id: managerUid,
  });
  // await addDoc(collection(db, `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`),
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
      notification.id
    ),
    {
      created_at: new Date(),
      user_id_from: userFrom,
      user_id_to: userTo,
      team_id: teamId,
      rol: rol,
      status: "sended",
      type: TypeNotifications.managerInvitation,
      notification_id: notification.id,
    }
  );
};

export const removeManagerApplication = async (
  teamId: string,
  application: any,
  userToId: string
) => {
  await cancelNotificationToUser(userToId, application.notification_id);
  const commentDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
    application.id
  );
  await deleteDoc(commentDoc)
    .then(() => {
      console.log("Applications has been deleted");
    })
    .catch((err) => {
      console.log(err);
    });
  return;
};

export const getInvitationManagerToTeam = async (
  teamId: string,
  userTo: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`
  );
  const q = query(
    teamsCollectionRef,
    where("type", "==", TypeNotifications.managerInvitation),
    where("user_id_to", "==", userTo)
  );
  const data = await getDocs(q);
  let teamUser = arrayElementLooper(data.docs);
  let returnData = teamUser.length > 0 ? teamUser[0] : null;
  return returnData;
};

// export const getInvitationManagerToTeam = async (teamId: string, userTo: string) => {
//     const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`);
//     const q = query(teamsCollectionRef, where("type", "==", TypeNotifications.managerInvitation), where("user_id_to", "==", userTo));
//     const data = await getDocs(q);
//     let applicationUsers = arrayElementLooper(data.docs);
//     let usersReturn = []
//     for (let applicationUser of applicationUsers) {
//         let team = await getUserById(applicationUser.user_id_to)
//         usersReturn.push(team)
//     }
//     return usersReturn
// }

export const getAllInvitationsManagerToTeam = async (teamId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`
  );
  const q = query(
    teamsCollectionRef,
    where("type", "==", TypeNotifications.managerInvitation)
  );
  const data = await getDocs(q);
  let applicationUsers = arrayElementLooper(data.docs);
  let usersReturn = [];
  for (let applicationUser of applicationUsers) {
    let team = await getUserById(applicationUser.user_id_to);
    usersReturn.push(team);
  }
  return usersReturn;
};

export const getAllInvitationsToTeam = async (teamId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`
  );
  const data = await getDocs(teamsCollectionRef);
  let applicationUsers = arrayElementLooper(data.docs);
  let usersReturn = [];
  for (let applicationUser of applicationUsers) {
    let team = getUserById(applicationUser.user_id_to);
    usersReturn.push(team);
  }
  return usersReturn;
};

export const getTeamApplicationById = async (
  teamId: string,
  applicationId: string
) => {
  const postDoc = doc(
    db,
    `${USER_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
    applicationId
  );
  let returnData = null;
  await getDoc(postDoc)
    .then((snapshot: any) => {
      if (snapshot.exists()) {
        returnData = snapshot.data();
        returnData.id = snapshot.id;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return returnData;
};

export const cancelTeamApplication = async (
  teamId: string,
  applicationId: string
) => {
  let application: any = await getTeamApplicationById(teamId, applicationId);
  await cancelNotificationToUser(
    application.user_id_to,
    application.notification_id
  );
  let returnData: any = false;
  await setDoc(
    doc(
      db,
      `${USER_TABLE_NAME}/${teamId}/${APPLICATION_TABLE_NAME}`,
      applicationId
    ),
    { status: "canceled" },
    { merge: true }
  )
    .then(() => {
      console.log("Application has been updated");
      returnData = true;
    })
    .catch((err) => {
      console.log(err);
    });

  return returnData;
};

export const getAllTeams = async () => {
  const usersCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  const q = query(
    usersCollectionRef,
    orderBy("created_at", "desc"),
    limit(100)
  );
  const data = await getDocs(q);
  let teams = arrayElementLooper(data.docs);
  teams = teams.filter(
    (team: any) =>
      !team.private &&
      !team.is_external &&
      (team.deleted === undefined || team.deleted === false)
  );
  return teams;
};

export const addTournamentToTeam = async (
  teamId: string,
  tournamentId: string
) => {
  let returnData = false;
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}`,
      tournamentId
    ),
    {
      tournament_id: tournamentId,
      created_at: new Date(),
      status: "acepted",
      status_tournament: "actually",
    },
    { merge: true }
  )
    .then(() => {
      returnData = true;
    })
    .catch((err) => {
      console.log(err);
    });
  return returnData;
};

export const updateTournamentToTeam = async (
  teamId: string,
  tournamentId: string,
  data: any
) => {
  let returnData = false;
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}`,
      tournamentId
    ),
    data,
    { merge: true }
  )
    .then(() => {
      returnData = true;
    })
    .catch((err) => {
      console.log(err);
    });
  await setDoc(
    doc(
      db,
      `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TEAM_TABLE_NAME}`,
      teamId
    ),
    data,
    { merge: true }
  );
  return returnData;
};

export const deleteTeamFromTournament = async (
  teamId: string,
  tournamentId: string
) => {
  let returnData = false;
  await deleteDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}`,
      tournamentId
    )
  );
  await deleteDoc(
    doc(
      db,
      `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TEAM_TABLE_NAME}`,
      teamId
    )
  );
  return returnData;
};

export const validateTeamIsSelected = async (
  teamId: string,
  tournamentId: string
) => {
  const data = await getDocs(
    collection(
      db,
      `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`
    )
  );
  let phases = arrayElementLooper(data.docs);
  let exist = false;
  for (let phase of phases) {
    let snapshot = await getDoc(
      doc(
        db,
        `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phase.id}/${TEAM_TABLE_NAME}`,
        teamId
      )
    );
    if (snapshot.exists()) {
      exist = true;
    }
    if (phase.have_groups) {
      const dataGroupRef = await getDocs(
        collection(
          db,
          `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}`
        )
      );
      let groups = arrayElementLooper(dataGroupRef.docs);
      for (let group of groups) {
        let snapshot = await getDoc(
          doc(
            db,
            `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}/${group.id}/${TEAM_TABLE_NAME}`,
            teamId
          )
        );
        if (snapshot.exists()) {
          exist = true;
        }
      }
    }
  }

  return exist;
};

export const getTournamentToTeam = async (
  teamId: string,
  tournamentId: string
) => {
  const postDoc = doc(
    db,
    `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TEAM_TABLE_NAME}`,
    teamId
  );
  let returnData = null;
  await getDoc(postDoc)
    .then((snapshot: any) => {
      if (snapshot.exists()) {
        returnData = snapshot.data();
        returnData.id = snapshot.id;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return returnData;
};

export const getCurrentManagersFromTeam = async (teamId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MANAGERS_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, where("status", "==", "active"));
  const data = await getDocs(q);
  let teamManagers = arrayElementLooper(data.docs);
  let usersReturn = [];
  for (let manager of teamManagers) {
    let managerData: any = await getUserById(manager.user_id);
    managerData.user_id = manager.user_id;
    managerData.id = manager.id;
    usersReturn.push({ ...manager, ...managerData });
  }
  return usersReturn;
};

export const getAllTeamsByUser = async (userId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${userId}/${TEAM_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, orderBy("created_at", "desc"));
  const data = await getDocs(q);
  let teamUser = arrayElementLooper(data.docs);
  let teamsReturn = [];
  for (let team of teamUser) {
    let teamData = getTeamById(team.team_id);
    teamsReturn.push(teamData);
  }
  return teamsReturn;
};

export const getCurrentTechnicalStaffFromTeam = async (
  teamId: string,
  tournamentId: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TECHNICAL_STAFF_TABLE_NAME}`
  );
  // const q = query(teamsCollectionRef, where("status", "==", "active"));
  const data = await getDocs(teamsCollectionRef);
  let teamTechnicalStaff = arrayElementLooper(data.docs);
  let usersReturn = [];
  for (let manager of teamTechnicalStaff) {
    let technicalStaffData = await getUserById(manager.user_id);
    usersReturn.push({ ...manager, ...technicalStaffData });
  }
  return usersReturn;
};

export const removeTechnicalStuff = async (
  userId: string,
  teamId: string,
  tournamentId: string,
  user_team_id: string
) => {
  // remove  user as player to tournament
  let commentDoc = doc(
    db,
    `${USER_TABLE_NAME}/${userId}/${TOURNAMENT_TABLE_NAME}`,
    user_team_id
  );
  await deleteDoc(commentDoc);
  //remove user to team on tournament
  commentDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TECHNICAL_STAFF_TABLE_NAME}`,
    userId
  );
  await deleteDoc(commentDoc);
};

export const removeManagerTeam = async (managerId: string, teamId: string) => {
  // remove  manager to team
  await setDoc(
    doc(db, `${TEAM_TABLE_NAME}/${teamId}/${MANAGERS_TABLE_NAME}`, managerId),
    {
      status: "inactive",
    },
    { merge: true }
  );
};

export const getPlayersByTeamAndTournament = async (
  teamId: string,
  tournamentId: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PLAYERS_TABLE_NAME}`
  );
  const dataPlayerRef = await getDocs(teamsCollectionRef);
  let players = arrayElementLooper(dataPlayerRef.docs);
  let usersReturn = [];
  for (let player of players) {
    let playerData = await getUserById(player.user_id);
    let playerDataMerge = { ...player, ...playerData };
    if (playerDataMerge.bith_date) {
      var today = new Date();
      let birthDate = new Date(playerDataMerge.bith_date.seconds * 1000);
      var years = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        years--;
      }
      playerDataMerge.oldYears = years;
    }
    usersReturn.push(playerDataMerge);
  }
  return usersReturn;
};

export const getUserByTeamAndTournamentAndUserId = async (
  teamId: string,
  tournamentId: string,
  userId: string
) => {
  const userDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PLAYERS_TABLE_NAME}`,
    userId
  );
  const dataPlayerRef = await getDoc(userDoc);
  let returnData = dataPlayerRef.data();
  returnData.id = dataPlayerRef.id;
  return returnData;
};

export const updateUserByTeamAndTournamentAndUserId = async (
  teamId: string,
  tournamentId: string,
  userId: string,
  dataToSave: any
) => {
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PLAYERS_TABLE_NAME}`,
      userId
    ),
    dataToSave,
    { merge: true }
  );
};

export const getTechnicalStaffByTeamAndMatch = async (
  teamId: string,
  matchId: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${TECHNICAL_STAFF_TABLE_NAME}`
  );
  const dataPlayerRef = await getDocs(teamsCollectionRef);
  let players = arrayElementLooper(dataPlayerRef.docs);
  let usersReturn = [];
  for (let player of players) {
    let playerData = await getUserById(player.id);
    const matchToSave = { ...player, ...playerData };
    if (matchToSave.bith_date) {
      var today = new Date();
      let birthDate = new Date(matchToSave.bith_date.seconds * 1000);
      var years = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        years--;
      }
      matchToSave.oldYears = years;
    }
    usersReturn.push(matchToSave);
  }
  return usersReturn;
};

export const getPlayersByTeamAndMatch = async (
  teamId: string,
  matchId: string,
  type: string
) => {
  console.log("============");
  console.log("teamId", teamId);
  console.log("matchId", matchId);
  console.log("type", type);
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${PLAYERS_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, where("type", "==", type));
  const dataPlayerRef = await getDocs(teamsCollectionRef);
  let players = arrayElementLooper(dataPlayerRef.docs);
  let usersReturn = [];
  console.log("players>>>>", players);
  for (let player of players) {
    let playerData = await getUserById(player.id);
    const matchToSave = { ...player, ...playerData };
    if (matchToSave.bith_date) {
      var today = new Date();
      let birthDate = new Date(matchToSave.bith_date.seconds * 1000);
      var years = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        years--;
      }
      matchToSave.oldYears = years;
    }
    if (type === "sustitutibePlayers") {
      if (!player.played) {
        usersReturn.push(matchToSave);
      }
    } else {
      usersReturn.push(matchToSave);
    }
  }
  return usersReturn;
};

export const updatePlayersByTeamAndMatch = async (
  teamId: string,
  matchId: string,
  data: any,
  userId: string
) => {
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${PLAYERS_TABLE_NAME}`,
      userId
    ),
    data,
    { merge: true }
  );
  return;
};

export const getTechnicalStaffByTeamAndTournament = async (
  teamId: string,
  tournamentId: string
) => {
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TECHNICAL_STAFF_TABLE_NAME}`
  );
  const dataPlayerRef = await getDocs(teamsCollectionRef);
  let players = arrayElementLooper(dataPlayerRef.docs);
  let usersReturn = [];
  for (let player of players) {
    let playerData = await getUserById(player.user_id);
    playerData = { ...playerData, ...player };
    usersReturn.push(playerData);
  }
  return usersReturn;
};

export const addPlayersToMatch = async (
  teamId: string,
  matchId: string,
  players: any[],
  type: string
) => {
  //remove current players
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${PLAYERS_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, where("type", "==", type));
  const dataPlayerRef = await getDocs(q);
  let currentPlayers = arrayElementLooper(dataPlayerRef.docs);
  for (let player of currentPlayers) {
    const commentDoc = doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${PLAYERS_TABLE_NAME}`,
      player.id
    );
    await deleteDoc(commentDoc);
  }

  for (let player of players) {
    player.created_at = new Date();
    player.type = type;
    await setDoc(
      doc(
        db,
        `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${PLAYERS_TABLE_NAME}`,
        player.id
      ),
      player,
      { merge: true }
    );
  }
};

export const addTechnicalStaffToMatch = async (
  teamId: string,
  matchId: string,
  players: any[any]
) => {
  //remove current players
  const teamsCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${TECHNICAL_STAFF_TABLE_NAME}`
  );
  const dataPlayerRef = await getDocs(teamsCollectionRef);
  let currentPlayers = arrayElementLooper(dataPlayerRef.docs);
  for (let player of currentPlayers) {
    const commentDoc = doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${TECHNICAL_STAFF_TABLE_NAME}`,
      player.id
    );
    await deleteDoc(commentDoc);
  }

  for (let player of players) {
    player.created_at = new Date();
    await setDoc(
      doc(
        db,
        `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${TECHNICAL_STAFF_TABLE_NAME}`,
        player.id
      ),
      player,
      { merge: true }
    );
  }
};

export const addIncidenceToTeam = async (
  teamId: string,
  data: any,
  matchId: string,
  incidenceId: string
) => {
  await setDoc(
    doc(
      db,
      `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${INCIDENCE_TABLE_NAME}`,
      incidenceId
    ),
    data,
    { merge: true }
  );
};

export const deleteIncidenceToTeam = async (
  teamId: string,
  data: any,
  matchId: string,
  incidenceId: string
) => {
  const commentDoc = doc(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}/${matchId}/${INCIDENCE_TABLE_NAME}`,
    incidenceId
  );
  await deleteDoc(commentDoc)
    .then(() => {
      console.log("Incidence team has been deleted");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const migration = async () => {
  //update users
  // const usersCollectionRef = collection(db, `${USER_TABLE_NAME}`);
  // const usersRef = await getDocs(usersCollectionRef);
  // let users = arrayElementLooper(usersRef.docs);
  // let cont = 0
  // for (let user of users) {
  //     cont++
  //     console.log(user.id)
  //     await setDoc(doc(db, `${USER_TABLE_NAME}`, user.id),
  //         { version: 3 }, { merge: true })
  // }
  // console.log("Updated users " + cont)
  //migration places
  // const placesCollectionRef = collection(db, `${PLACES_TABLE_NAME}`);
  // const placesRef = await getDocs(placesCollectionRef);
  // let places = arrayElementLooper(placesRef.docs);
  // for (let place of places) {
  //     console.log(place.id)
  //     await setDoc(doc(db, `${PLACES_TABLE_NAME}`, place.id),
  //         { version: 2 }, { merge: true })
  // }
  //migration TOURNAMENTS
  // let cont = 0
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // for (let tournament of tournaments) {
  //     console.log(tournament.id)
  //     cont++;
  //     await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}`, tournament.id), { version: 10 }, { merge: true })
  // }
  // console.log("Updated TOURNAMENTS " + cont)
  //migration TEAMS
  // const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  // const teamsRef = await getDocs(teamsCollectionRef);
  // let teams = arrayElementLooper(teamsRef.docs);
  // let cont = 0
  // for (let team of teams) {
  //     cont++;
  //     // console.log(match.id)
  //     await setDoc(doc(db, `${TEAM_TABLE_NAME}`, team.id), { version: 2 }, { merge: true })
  // }
  // console.log("Updated matches " + cont)
  //migration INSCRIPTION
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // let cont = 0
  // for (let tournament of tournaments) {
  //     const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${TEAM_TABLE_NAME}`);
  //     const teamsRef = await getDocs(teamsCollectionRef);
  //     let teams = arrayElementLooper(teamsRef.docs);
  //     for (let team of teams) {
  //         cont++
  //         await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${TEAM_TABLE_NAME}`, team.id), { version: 2 }, { merge: true })
  //     }
  // }
  // console.log("Updated " + cont)
  //migration PLAYERS
  // const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  // const teamsRef = await getDocs(teamsCollectionRef);
  // let teams = arrayElementLooper(teamsRef.docs);
  // let cont = 0
  // for (let team of teams) {
  //     const tournamentsCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}`);
  //     const tournamentsRef = await getDocs(tournamentsCollectionRef);
  //     let tournaments = arrayElementLooper(tournamentsRef.docs);
  //     for (let tournament of tournaments) {
  //         const playersCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PLAYERS_TABLE_NAME}`);
  //         const playersRef = await getDocs(playersCollectionRef);
  //         let players = arrayElementLooper(playersRef.docs);
  //         for (let player of players) {
  //             console.log(player.id)
  //             cont++
  //             await setDoc(doc(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PLAYERS_TABLE_NAME}`, player.id), { version: 2 }, { merge: true })
  //         }
  //     }
  // }
  // console.log("Updated " + cont)
  //migration PHASE
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // let cont = 0
  // for (let tournament of tournaments) {
  //     const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}`);
  //     const phaseRef = await getDocs(phasesCollectionRef);
  //     let phases = arrayElementLooper(phaseRef.docs);
  //     for (let phase of phases) {
  //         cont++
  //         console.log(phase.id)
  //         await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}`, phase.id), { version: 5 }, { merge: true })
  //     }
  // }
  // console.log("Updated " + cont)
  //migration GROUPS
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // let cont = 0
  // for (let tournament of tournaments) {
  //     const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}`);
  //     const phaseRef = await getDocs(phasesCollectionRef);
  //     let phases = arrayElementLooper(phaseRef.docs);
  //     for (let phase of phases) {
  //         if (phase.have_groups) {
  //             const groupsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}`);
  //             const groupRef = await getDocs(groupsCollectionRef);
  //             let groups = arrayElementLooper(groupRef.docs);
  //             for (let group of groups) {
  //                 cont++
  //                 console.log(group.id)
  //                 await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}`, group.id), { version: 1 }, { merge: true })
  //             }
  //         }
  //     }
  // }
  // console.log("Updated groups " + cont)
  //migration MATCHES
  // const matchesCollectionRef = collection(db, `${MATCH_TABLE_NAME}`);
  // const matchesRef = await getDocs(matchesCollectionRef);
  // let matches = arrayElementLooper(matchesRef.docs);
  // let cont = 0
  // for (let match of matches) {
  //     cont++;
  //     console.log(match.id)
  //     await setDoc(doc(db, `${MATCH_TABLE_NAME}`, match.id), { version: 10 }, { merge: true })
  // }
  // console.log("Updated matches----- " + cont)
  //migration TECHNICAL STAFF
  // const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  // const teamsRef = await getDocs(teamsCollectionRef);
  // let teams = arrayElementLooper(teamsRef.docs);
  // let cont = 0
  // for (let team of teams) {
  //     const tournamentsCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}`);
  //     const tournamentsRef = await getDocs(tournamentsCollectionRef);
  //     let tournaments = arrayElementLooper(tournamentsRef.docs);
  //     for (let tournament of tournaments) {
  //         const playersCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}/${tournament.id}/${TECHNICAL_STAFF_TABLE_NAME}`);
  //         const playersRef = await getDocs(playersCollectionRef);
  //         let players = arrayElementLooper(playersRef.docs);
  //         for (let player of players) {
  //             console.log(player.id)
  //             cont++
  //             await setDoc(doc(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}/${tournament.id}/${TECHNICAL_STAFF_TABLE_NAME}`, player.id), { version: 1 }, { merge: true })
  //         }
  //     }
  // }
  // console.log("Updated " + cont)
  //migration INCIDENCES
  // const matchesCollectionRef = collection(db, `${MATCH_TABLE_NAME}`);
  // const matchesRef = await getDocs(matchesCollectionRef);
  // let matches = arrayElementLooper(matchesRef.docs);
  // let cont = 0
  // for (let match of matches) {
  //     const incidencesCollectionRef = collection(db, `${MATCH_TABLE_NAME}/${match.id}/${INCIDENCE_TABLE_NAME}`);
  //     const incidencesRef = await getDocs(incidencesCollectionRef);
  //     let incidences = arrayElementLooper(incidencesRef.docs);
  //     for (let incidence of incidences) {
  //         cont++;
  //         console.log(incidence.id)
  //         console.log(incidence.match_id)
  //         console.log(match.scores)
  //         await setDoc(doc(db, `${MATCH_TABLE_NAME}/${match.id}/${INCIDENCE_TABLE_NAME}`, incidence.id), { version: 1 }, { merge: true })
  //     }
  // }
  // console.log("Updated matches " + cont)
  //migration PHASE TEAMS
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // let cont = 0
  // for (let tournament of tournaments) {
  //     const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}`);
  //     const phaseRef = await getDocs(phasesCollectionRef);
  //     let phases = arrayElementLooper(phaseRef.docs);
  //     for (let phase of phases) {
  //         const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${TEAM_TABLE_NAME}`);
  //         const teamsRef = await getDocs(teamsCollectionRef);
  //         let teams = arrayElementLooper(teamsRef.docs);
  //         for (let team of teams) {
  //             cont++
  //             console.log(team.id)
  //             await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${TEAM_TABLE_NAME}`, team.id), { version: 10 }, { merge: true })
  //         }
  //     }
  // }
  // console.log("Updated groups " + cont)
  //migration GROUPS TEAMS
  // const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}`);
  // const tournamentsRef = await getDocs(tournamentsCollectionRef);
  // let tournaments = arrayElementLooper(tournamentsRef.docs);
  // let cont = 0
  // for (let tournament of tournaments) {
  //     const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}`);
  //     const phaseRef = await getDocs(phasesCollectionRef);
  //     let phases = arrayElementLooper(phaseRef.docs);
  //     for (let phase of phases) {
  //         const groupsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}`);
  //         const groupsRef = await getDocs(groupsCollectionRef);
  //         let groups = arrayElementLooper(groupsRef.docs);
  //         for (let group of groups) {
  //             const tableCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}/${group.id}/${TEAM_TABLE_NAME}`);
  //             const tableRef = await getDocs(tableCollectionRef);
  //             let teams = arrayElementLooper(tableRef.docs);
  //             for (let team of teams) {
  //                 cont++
  //                 console.log(team.id)
  //                 await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}/${group.id}/${TEAM_TABLE_NAME}`, team.id), { version: 10 }, { merge: true })
  //             }
  //         }
  //     }
  // }
  // console.log("Updated groups " + cont)
  //migration TEMPLATE
  // const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  // const teamRef = await getDocs(teamsCollectionRef);
  // let teams = arrayElementLooper(teamRef.docs);
  // let cont = 0
  // for (let team of teams) {
  //     const matchesCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}`);
  //     const matchRef = await getDocs(matchesCollectionRef);
  //     let matches = arrayElementLooper(matchRef.docs);
  //     for (let match of matches) {
  //         const playersCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}/${match.id}/${PLAYERS_TABLE_NAME}`);
  //         const playerRef = await getDocs(playersCollectionRef);
  //         let players = arrayElementLooper(playerRef.docs);
  //         for (let player of players) {
  //             cont++
  //             console.log(player.id)
  //             console.log(player.type)
  //             await setDoc(doc(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}/${match.id}/${PLAYERS_TABLE_NAME}`, player.id), { version: 1 }, { merge: true })
  //         }
  //     }
  // }
  // console.log("Updated template " + cont)
  //migration TECHNICAL
  // const teamsCollectionRef = collection(db, `${TEAM_TABLE_NAME}`);
  // const teamRef = await getDocs(teamsCollectionRef);
  // let teams = arrayElementLooper(teamRef.docs);
  // let cont = 0
  // for (let team of teams) {
  //     const matchesCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}`);
  //     const matchRef = await getDocs(matchesCollectionRef);
  //     let matches = arrayElementLooper(matchRef.docs);
  //     for (let match of matches) {
  //         const playersCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}/${match.id}/${TECHNICAL_STAFF_TABLE_NAME}`);
  //         const playerRef = await getDocs(playersCollectionRef);
  //         let players = arrayElementLooper(playerRef.docs);
  //         for (let player of players) {
  //             cont++
  //             console.log(player.id)
  //             await setDoc(doc(db, `${TEAM_TABLE_NAME}/${team.id}/${MATCH_TABLE_NAME}/${match.id}/${TECHNICAL_STAFF_TABLE_NAME}`, player.id), { version: 3 }, { merge: true })
  //         }
  //     }
  // }
  // console.log("Updated template " + cont)
};
