//Card match header details
import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import ImageFromText from "../../../components/Common/ImageFromText";
import { matchStore } from "../../../zustand/globalStore";
import { MatchRolUser } from "../../../definitions/enums/GlobalEnums";

const MatchHeader: React.FC<{
  league?: string;
  leagueImage?: string;
  phase?: string;
  status?: string;
  isRight?: boolean;
  roles?: any[];
  time?: string;
}> = ({
  league,
  leagueImage,
  phase,
  status = "",
  isRight = false,
  roles = [],
  time = "",
}) => {
  const { currentMatch } = matchStore((state) => state);

  const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  const getStatusBgColor = (status: string) => {
    return status.toLowerCase() === "playing"
      ? colors.backgrounds.green
      : colors.figmaColors.grey3;
  };

  const getStatusTextColor = (status: string) => {
    return status.toLowerCase() === "playing"
      ? colors.text.black
      : colors.text.white;
  };

  const shouldShowTime =
    currentMatch?.status !== "finished" &&
    (roles.includes(MatchRolUser.vocal) || roles.includes(MatchRolUser.user));

  const shouldShowStatus = currentMatch?.status !== "playing";

  const bgColor = getStatusBgColor(status);
  const textColor = shouldShowTime
    ? colors.text.black
    : getStatusTextColor(status);
  const textContent = shouldShowTime
    ? capitalizeFirstLetter(time)
    : capitalizeFirstLetter(status);

  return (
    <Flex
      gap={2.5}
      width="100%"
      alignItems={"center"}
      justifyContent={"center"}
    >
      {(league || leagueImage) && (
        <Flex gap={1.5} alignItems={"center"}>
          {leagueImage ? (
            <Image
              src={leagueImage}
              width="18px"
              height="16px"
              objectFit={"cover"}
            />
          ) : (
            <ImageFromText
              sizeIcon={{
                width: "18px",
                height: "16px",
                borderRadius: 0,
              }}
              sizeText="10px"
              text={league ?? ""}
            />
          )}
          <BodyText
            fontSize={13}
            fontWeight="semiBold"
            color={colors.text.black}
          >
            {league}:
          </BodyText>
          <BodyText
            fontSize={13}
            fontWeight="regular"
            color={colors.text.lightGray3}
          >
            {phase}
          </BodyText>
        </Flex>
      )}
      {isRight ? (
        <>
          {(shouldShowTime || shouldShowStatus) && (
            <Flex
              width="auto"
              height="auto"
              p="3px 8px"
              borderRadius={10}
              bg={bgColor}
            >
              <BodyText fontSize={11} fontWeight="bold" color={textColor}>
                {textContent}
              </BodyText>
            </Flex>
          )}
        </>
      ) : (
        <Flex
          width={"auto"}
          height={"auto"}
          p={"3px 8px"}
          borderRadius={10}
          bg={getStatusBgColor(status)}
        >
          <BodyText
            fontSize={11}
            fontWeight="bold"
            color={getStatusTextColor(status)}
          >
            {capitalizeFirstLetter(status)}
          </BodyText>
        </Flex>
      )}
    </Flex>
  );
};

export default MatchHeader;
