import { useMediaQuery, Box, Link, Flex, Text } from "@chakra-ui/react";
import i18n from "../../i18n/i18n";
import {
  AppleStoreIcon,
  GooglePlayIcon,
  WuamballIcon,
} from "../../definitions/constants/IconsComponent";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";
import { colors } from "../../definitions/constants/GlobalStyles";
import DownloadImg from "../imgs/download_gradient.jpg";

const DownloadComponent = () => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [isMobileIcons] = useMediaQuery("(max-width: 1250px)");

  return (
    <Box
      marginTop={isMobile ? "0" : "25px"}
      height={isMobileIcons ? "244px" : "200px"}
      width={isMobile ? "95%" : "100%"}
      marginX={isMobile ? "auto" : "auto"}
      backgroundSize="70%"
      backgroundPosition="center left"
      backgroundRepeat="no-repeat"
      borderRadius={"20px"}
      backgroundColor={"white"}
      paddingTop={"50px"}
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.5)), url('${DownloadImg}')`,
      }}
    >
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Box marginLeft={"120px"}>
          <WuamballIcon width="98px" height="33px" />
        </Box>
        <Box>
          <Text
            marginTop={"10px"}
            fontWeight={"600"}
            fontSize={"13px"}
            lineHeight={"18.51px"}
            color={colors.figmaColors.black}
            textAlign={"center"}
            marginLeft={"120px"}
          >
            {i18n.t("download the app")}
          </Text>
        </Box>
        <Box>
          <Flex
            display="flex"
            flexDirection={isMobileIcons ? "column" : "row"}
            gap={2}
            marginTop={"10px"}
            align={"center"}
            justify={"center"}
            marginLeft={"120px"}
          >
            <Link target="_blank" href={playStoreUrl}>
              <GooglePlayIcon />
            </Link>
            <Link target="_blank" href={appStoreUrl}>
              <AppleStoreIcon />
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default DownloadComponent;
