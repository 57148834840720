import React from "react";

const IconCard = ({ fill = "none", width = "20", height = "20", ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mdi:card">
      <path
        id="Vector"
        d="M16.6665 17.1673H3.33317C2.89114 17.1673 2.46722 16.9917 2.15466 16.6792C1.8421 16.3666 1.6665 15.9427 1.6665 15.5007V5.50065C1.6665 5.05862 1.8421 4.6347 2.15466 4.32214C2.46722 4.00958 2.89114 3.83398 3.33317 3.83398H16.6665C17.1085 3.83398 17.5325 4.00958 17.845 4.32214C18.1576 4.6347 18.3332 5.05862 18.3332 5.50065V15.5007C18.3332 15.9427 18.1576 16.3666 17.845 16.6792C17.5325 16.9917 17.1085 17.1673 16.6665 17.1673Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default IconCard;
