import { Flex, Switch } from "@chakra-ui/react";
import BodyText from "../../components/Common/BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";

interface ToggleQuestionsProps {
  title: string;
  subTitle: string;
  value: boolean;
  onChange: () => void;
}

const ToggleQuestion: React.FC<ToggleQuestionsProps> = ({
  title,
  subTitle,
  value,
  onChange,
}) => {
  return (
    <Flex
      gap={1}
      padding="9px 16px"
      borderRadius={20}
      alignItems="center"
      justifyContent="space-between"
      borderWidth="1px"
      borderColor={colors.backgrounds.gray1}
      backgroundColor={colors.backgrounds.gray0}
    >
      <Flex direction="column">
        <BodyText
          fontSize={{ base: "13px", md: "14px" }}
          fontWeight="semiBold"
          textColor={colors.text.black}
        >
          {title}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          textColor={colors.text.lightGray3}
        >
          {subTitle}
        </BodyText>
      </Flex>
      <Switch
        size={{ base: "md", md: "lg" }}
        isChecked={value}
        onChange={onChange}
      />
    </Flex>
  );
};

export default ToggleQuestion;
