import { extendTheme } from "@chakra-ui/react";

const colors = {
  backgrounds: {
    signup: "#F5F5F5",
    white: "#FFFFFF",
    black: "#000",
    green: "#78C912",
    green1: "#C1E40E",
    action: "#F0F0F8",
    action_active: "#BCBCE0",
    lightRed: "#FF6666",
    gray0: "#F8F8F8",
    gray1: "#E0E0E0",
    gray2: "#CCC",
    gray3: "#E2E8F0",
    red: "#FF5E5E",
    yellow: "#FFE53B",
  },
  surface: {
    white: "#FFFFFF",
    offWhite: "#FCFFFD",
    offGreen: "#F9FFF7",
    primary: "#F5FCF8",
    secondary: "#EDF7F1",
    border: "#E1EBE5",
    tertiary: "#D8E0DC",
    questions: "#E4FFD1",
    borderSecondary: "#D4EDC2",
    hoverBorder: "#ADC29F",
    background: "#F5F7F6",
    focus: "#E1EBE5",
    borderQuestion: "#D2EBC0",
    gradient: "#C8FFCD",
  },
  primaryAction: {
    light: "#F5FFF9",
    light2: "#DCFAE8",
    inactive: "rgba(22, 163, 74, 0.44)",
    generateButton: "#16A34A",
    default: "#258548",
    hover: "#005720",
    pressed: "#003D16",
  },
  secondaryAction: {
    light: "#EEF0EF",
    inactive: "#C9CBCA",
    inactiveDarker: "#979998",
    default: "#6A6B6A",
    hover: "#4C4D4C",
    pressed: "#2D2E2D",
  },
  tertiaryAction: {
    hover: "#EAB308",
    pressed: "#CA8A04",
  },
  text: {
    white: "#FFFFFF",
    inactive: "#C0C2C1",
    placeholder: "#949695",
    label: "#696A69",
    body: "#3D3E3D",
    header: "#121212",
    black: "#000000",
    red: "#FF3F00",
    lightBlack: "#000",
    lightGray3: "#626262",
  },
  light: {
    text: "#000",
    background: "#fff",
    tabIconDefault: "#ccc",
  },
  accentColors: {
    errorLight: "#FFF5F5",
    errorStrong: "#B22323",
    successLight: "#EBFFF2",
    successStrong: "#078236",
    warningLight: "#FFF9EB",
    warningStrong: "#946C0D",
    infoLight: "#EBF5FF",
    infoDark: "#0D5AA7",
    errorBackground: "#FFE5E5",
  },

  figmaColors: {
    appBgColor: "#f8f8f8",
    secD: "#0D2C8D",
    black: "#000000",
    grey0: "#F8F8F8",
    grey1: "#E0E0E0",
    grey3: "#626262",
    secColor: "#123EC9",
    priColor: "#78C912",
    borderCard: "#5978D9",
    leaguesHexGradient: "#334EA7",
    leaguesGradient1:
      "linear-gradient(90.72deg, #0D2C8D 0.66%, #5978D9 99.44%)",
    leaguesGradient2: "linear-gradient(91deg, #0A1F6F 0.66%, #4863B7 99.44%)",
  },
};

export { colors };

export const theme = extendTheme({
  fonts: {
    body: "Source Sans 3 Variable, sans-serif",
    heading: "Source Sans 3 Variable, sans-serif",
  },
  styles: {
    global: {
      body: {
        bg: colors.figmaColors.appBgColor,
      },
    },
  },
});
