import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Link as ChakraLink,
  Box,
  Flex,
  Heading,
  Spacer,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  AppleStoreIcon,
  GooglePlayIcon,
  WuamballIcon,
} from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";

interface HeaderComponentProps {
  children: React.ReactNode;
}

function HeaderComponent({ children }: HeaderComponentProps) {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [checkScroll, setCheckScroll] = useState(false);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCheckScroll(true);
  };

  const checkScrollTop = () => {
    if (checkScroll && window.scrollY === 0) {
      window.location.reload();
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <>
      <Box
        p="4"
        bg={colors.text.white}
        boxShadow="md"
        gap={2}
        width="100%"
        position="fixed"
        zIndex={10}
      >
        <Flex
          maxWidth={isMobile ? "100%" : "1230px"}
          align="center"
          justify="space-between"
          margin="auto"
          gap={"10px"}
        >
          <Box>
            <Heading as={Link} fontSize="xl">
              <ChakraLink as={Link} to={"/"}>
                <WuamballIcon onClick={handleClick} />
              </ChakraLink>
            </Heading>
          </Box>
          <Spacer />
          <Box>
            <Flex align="center" justify="space-between" gap="10">
              <ChakraLink as={Link} to={"/terms_and_conditions"}>
                <Text fontSize="md">{i18n.t("terms")}</Text>
              </ChakraLink>
              <ChakraLink as={Link} target="_blank" to={appStoreUrl}>
                <AppleStoreIcon maxWidth="126px" width="100%" />
              </ChakraLink>
              <ChakraLink as={Link} target="_blank" to={playStoreUrl}>
                <GooglePlayIcon maxWidth="126px" width="100%" />
              </ChakraLink>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box minW={"100%"} justifyContent={"center"} display={"flex"}>
        <Box
          maxWidth={{
            base: "100%",
            md: "1230px",
          }}
          minW={{
            base: "100%",
            md: "80%",
          }}
          p={{
            base: "0",
            md: "5",
          }}
          width={{
            base: "100%",
            md: "85%",
            lg: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

export default HeaderComponent;
