import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import { SortIcon } from "../../../definitions/constants/IconsComponent";
import IconArrowShort from "../../../assets/icons/IconArrowShort";
import StatisticsTournamentComponent from "./StatisticsTournamentComponent";
import MatchesTournamentComponent from "./MatchesTournamentComponent";
import StandingsTournamentComponent from "./StandingsTournamentComponent";
import PlayOffsTournamentComponent from "./PlayOffsTournamentComponent";
import TeamsTournamentComponent from "./TeamsTournamentComponent";
import ProgressBarComponent from "../../../components/Tournaments/ProgressBarComponent";

const TournamentViewOwnerComponent = () => {
  const [activeButton, setActiveButton] = useState("statistics");

  return (
    <>
      <ProgressBarComponent />
      <Flex
        alignItems="flex-end"
        alignSelf="stretch"
        flexDirection="row"
        justifyContent="space-between"
        borderBottomWidth="1px"
      >
        <ButtonComponent
          variant="variantTab"
          buttonName="statistics"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("statistics");
          }}
        >
          {i18n.t("statistics")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="playoffs"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("playoffs");
          }}
        >
          {i18n.t("playoffs")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="matches"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("matches");
          }}
        >
          {i18n.t("matches")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="teams"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("teams");
          }}
        >
          {i18n.t("teams")}
        </ButtonComponent>

        <ButtonComponent
          variant="variantTab"
          buttonName="classifications"
          activeButton={activeButton}
          onClick={() => {
            setActiveButton("classifications");
          }}
        >
          {i18n.t("classifications")}
        </ButtonComponent>

        <Flex
          display="flex"
          flex="1"
          gap={2}
          alignItems="center"
          justifyContent="flex-end"
          alignSelf="center"
          flexDirection="row"
        >
          <SortIcon />

          <BodyText
            fontSize={{ base: "14px", md: "13px" }}
            fontWeight="semiBold"
            color={colors.text.black}
            textAlign="center"
          >
            {i18n.t("season")}: 2024
          </BodyText>

          <IconArrowShort />
        </Flex>
      </Flex>
      {activeButton === "statistics" && <StatisticsTournamentComponent />}
      {activeButton === "playoffs" && <PlayOffsTournamentComponent />}
      {activeButton === "matches" && <MatchesTournamentComponent />}
      {activeButton === "teams" && <TeamsTournamentComponent />}
      {activeButton === "classifications" && <StandingsTournamentComponent />}
    </>
  );
};

export default TournamentViewOwnerComponent;
