import { Avatar, Box, Button } from "@chakra-ui/react";
import { User } from "../../../../definitions/interfaces/Users/Users.interface";
import { ReactDispatchUserState } from "../../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { colors } from "../../../../definitions/constants/GlobalStyles";
import BodyText from "../../../Common/BodyText";


const ItemInviteManager = ({
  item,
  setUser,
  userActive,
}: {
  item: User;
  setUser: ReactDispatchUserState;
  userActive: User;
}): JSX.Element => {
  return (
    <Button
      sx={{
        border:
          userActive?.id === item?.id
            ? `1px solid ${colors.figmaColors.secColor}`
            : null,
      }}
      className="container-item-user"
      onClick={() => setUser(item)}
    >
      <Box className="user-detail">
        <Avatar
          src={item.image_url}
          name={`${item.firstname} ${item.lastname}`}
        />
        <Box>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="bold"
            textColor={colors.text.black}
          >
            {item.firstname} {item.lastname}
          </BodyText>
          <BodyText
            fontSize={{ base: "12px", md: "13px" }}
            fontWeight="regular"
            textColor={colors.text.lightGray3}
          >
            {`@${item.firstname.toLowerCase().trim()}${item.lastname
              .toLowerCase()
              .trim()}`.trim()}
          </BodyText>
        </Box>
      </Box>
    </Button>
  );
};
export default ItemInviteManager;
