import { Box, IconButton, Input, Select, Spinner } from "@chakra-ui/react";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";
import i18n from "../../../../../i18n/i18n";
import { useEffect, useState } from "react";
import "../styles/InviteManagers.scss";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";
import { RolManager } from "../../../../../definitions/enums/GlobalEnums";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import { SendIconWhite } from "../../../../../definitions/constants/IconsComponent";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../config/firebase";
import { appStore } from "../../../../../zustand/globalStore";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import ItemInviteManager from "./components/ItemInviteManager";
import { inviteUserAsManagerTeam } from "../../../../../models/TeamModel";

const InviteManagers = ({ goToStep, hideContainerModal }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rol, setRol] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectUser, setSelectUser] = useState<User | null>(null);
  const { userData, currentTeam } = appStore((store) => store);

  const processInvitation = async () => {
    setLoading(true);
    await inviteUserAsManagerTeam(
      userData.id,
      selectUser.id,
      currentTeam.id,
      currentTeam.name,
      rol
    );
    setLoading(false);
    goToStep(stepsTeams.successInvite);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    if (searchText.length > 0) {
      setSearchLoading(true);
      const findUsers = await httpsCallable(
        functions,
        "searchUsersByText"
      )({
        filter: `%${e.target.value.toLocaleLowerCase()}%`,
        userId: userData.id,
      });
      setSearchLoading(false);
      console.log(findUsers);
      if (findUsers && findUsers.data) {
        setUsers(findUsers.data as User[]);
      }
    }
  };

  return (
    <Box className="container">
      <ModalHeaderComponent
        title={i18n.t("inviteManager")}
        subtitle={i18n.t("StartManagingTeam")}
        confirmText={i18n.t("invite")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(stepsTeams.managers)}
        onConfirm={processInvitation}
        isSaving={loading}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      <Box className="container-inputs">
        <Box>
          <Box display={"flex"} gap={"5px"} padding={"10px 5px"}>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              textColor={colors.text.black}
            >
              {i18n.t("Rol")}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          </Box>
          <Select
            className="roles"
            placeholder={i18n.t("select")}
            value={rol}
            onChange={(e) => setRol(e.target.value)}
          >
            {Object.values(RolManager).map((item) => (
              <option>{i18n.t(item)}</option>
            ))}
          </Select>
        </Box>
        <Box className="container-search-user">
          <Box display={"flex"} gap={"5px"}>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              textColor={colors.text.black}
            >
              {i18n.t("searchUser")}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          </Box>
          <Box className="input-and-button">
            <Input size={"lg"} value={searchText} onChange={handleSearch} />
            <IconButton
              aria-label="Invite user"
              background={colors.figmaColors.secColor}
              color={"white"}
            >
              <SendIconWhite />
            </IconButton>
          </Box>
        </Box>
        <Box className="container-content-users">
          {searchLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={"20px 0px"}
            >
              <Spinner size="lg" color="blue.500" />
            </Box>
          ) : (
            <Box className="users">
              {users.length > 0 &&
                users
                  .slice(0, 3)
                  .map((item) => (
                    <ItemInviteManager
                      item={item}
                      setUser={setSelectUser}
                      userActive={selectUser}
                    />
                  ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InviteManagers;
