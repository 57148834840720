import React, { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import { MatchCardWeek, MatchCardAll } from "./MatchCard";

const MatchesLeftSide: React.FC = () => {
  const [activeButton, setActiveButton] = useState("todayMatches");

  return (
    <Flex flexDirection={"column"} gap={4}>
      <Box>
        <Flex
          align="center"
          position="relative"
          overflow="hidden"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <ButtonComponent
            variant="variantTab"
            buttonName="todayMatches"
            activeButton={activeButton}
            onClick={() => {
              setActiveButton("todayMatches");
            }}
          >
            {i18n.t("this_week")}
          </ButtonComponent>
          <ButtonComponent
            variant="variantTab"
            buttonName="allMatches"
            activeButton={activeButton}
            onClick={() => {
              setActiveButton("allMatches");
            }}
          >
            {i18n.t("all_games")}
          </ButtonComponent>
        </Flex>
      </Box>
      <Box>
        {activeButton === "todayMatches" && <MatchCardWeek />}
        {activeButton === "allMatches" && <MatchCardAll />}
      </Box>
    </Flex>
  );
};

export default MatchesLeftSide;
