import { useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { consumeApi } from "../utils/tools";
import { appStore } from "../zustand/globalStore";
import { useNavigate } from "react-router-dom";
import platform from "platform";
import i18n from "../i18n/i18n";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const { setUserData } = appStore((state) => state);
  const navigate = useNavigate();
  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org");
      const ip = await response.text();
      return ip;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  const deviceOS = () => {
    const os = platform.os;
    return os.family ? `${os.family}`.trim().toLowerCase() : i18n.t("unknown");
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authenticatedUser) => {
      console.log(authenticatedUser, "*authenticatedUser***");
      setLoading(true);
      try {
        if (authenticatedUser) {
          const userResponse = await consumeApi(
            `users/get_user_by_id`,
            "POST",
            {
              user_id: authenticatedUser?.uid,
            }
          );
          console.log(userResponse, "userResponse****");
          if (userResponse.status === "success") {
            let user = userResponse?.user_data;
            if (!user?.email) {
              await consumeApi(`users/update_user`, "POST", {
                user_id: user?.id,
                fields_to_update: { email: authenticatedUser?.email },
              });
            }
            setUserData(user);
          } else if (
            userResponse.status === "error" &&
            userResponse.message === "user not exists"
          ) {
            const getFirstName = localStorage.getItem("signup_firstname");
            const getLastName = localStorage.getItem("signup_lastname");

            const createUserResponse = await consumeApi(
              `users/create_user`,
              "POST",
              {
                email: authenticatedUser?.email,
                firebase_id: authenticatedUser?.uid,
                firstname: authenticatedUser?.displayName
                  ? authenticatedUser?.displayName
                  : getFirstName,
                lastname: getLastName ? getLastName : "",
                image_url: authenticatedUser?.photoURL,
                device_os: deviceOS(),
                ip_address: await getUserIP(),
              }
            );
            if (createUserResponse.status === "success") {
              const newUserResponse = await consumeApi(
                `users/get_user_by_id`,
                "POST",
                {
                  user_id: authenticatedUser?.uid,
                }
              );
              setUserData(newUserResponse?.user_data);
              localStorage.clear();
            }
          }
          setIsAuthenticated(true);
          navigate("/explore");
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error during authentication: ", error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return { isAuthenticated, loading };
};

export default useAuth;
