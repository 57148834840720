import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../../components/Common/BodyText";
import ModalHeaderComponent from "../Common/ModalHeaderComponent";
import CitySelector from "../../components/Common/CitySelector";
import ImageUploader from "../../components/Common/ImageUploader";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  consumeApi,
  handleImageChange,
  createLocalityFromPlace,
  uploadFileToAmazonS3Web,
} from "../../utils/tools";
import { v4 as uuidV4 } from "uuid";
import { getUserByUsername } from "../../models/UserModel";
import SelectDate from "../../components/Common/SelectDate";
import ToggleQuestion from "../../components/Shared/ToggleQuestion";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";
import { DeviceOs } from "../../hooks/DeviceOs";
import MessageConfirmationComponent from "../MessageConfirmationComponent";
import { dataForm } from "./types/dataForm.interface";

const steps = {
  default: "default",
  successAction: "successAction",
};

const UpdateOrViewProfileData = () => {
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<any>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const { isNeedToCreateANewUser } = useParams();
  const { hideContainerModal, userData, setUserData } = appStore(
    (state) => state
  );
  console.log("Current user:", userData);

  const dataForm = {
    firstname: userData ? userData.firstname : "",
    lastname: userData ? userData.lastname : "",
    email: userData ? userData.email : "",
    phone_number: userData ? userData.phone_number : "",
    address: userData ? userData.address : "",
    automaticallyAcceptInvitations: userData
      ? userData.automaticallyAcceptInvitations
      : false,
  };
  const [formData, setFormData] = useState<dataForm>(dataForm);
  const [place, setPlace] = useState<PlaceDetails | null>(
    userData ? userData.place : null
  );
  const [birthDate, setBirthDate] = useState<Date | null>(
    userData ? userData.bith_date : null
  );
  const [image, setImage] = useState(userData ? userData.image_url : "");
  const [username, setUsername] = useState(userData ? userData.username : "");

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const onPressSave = async (data: any) => {
    setIsSaving(true);
    try {
      data.place =
        place?.description === userData?.place.description
          ? userData.place
          : createLocalityFromPlace(place);
      data.place_id =
        place?.description === userData?.place.description
          ? userData.place?.place_id
          : createLocalityFromPlace(place).place_id;
      data.place_description =
        place?.description === userData?.place.description
          ? userData.place?.description
          : createLocalityFromPlace(place).description;

      if (data.bith_date) {
        data.bith_date = createDateFromString(data.bith_date).getTime();
      } else {
        delete data.bith_date;
      }

      if (isNeedToCreateANewUser) {
        let username = `${data.firstname}${data.lastname}`
          .toLowerCase()
          .split(" ")
          .join("")
          .split("@")
          .join("");
        const usersByUsername = await getUserByUsername(username);
        if (usersByUsername.length > 0) {
          username = `${username}${usersByUsername.length}`;
        }
        data.username = username;
      }

      if (media) {
        let response = await uploadFileToAmazonS3Web(
          media,
          (percentage: number) => {
            console.log(percentage);
          },
          "users"
        );
        data.image_url = response;
      } else if (image) {
        data.image_url = userData.image_url;
      }

      if (isNeedToCreateANewUser) {
        let publicIp = await fetch("https://api.ipify.org?format=json")
          .then((response) => response.json())
          .then((data) => data.ip);
        data.device_os = DeviceOs();
        data.ip_address = publicIp;
        data.firebase_id = uuidV4.toString();
        await consumeApi(`users/create_user`, "POST", data);
      } else {
        //remove email from data
        delete data.email;
        await consumeApi(`users/update_user`, "POST", {
          user_id: userData?.id,
          fields_to_update: data,
        });
        const response = await consumeApi(`users/get_user_by_id`, "POST", {
          user_id: userData?.id,
        });
        let user = response.user_data;
        setUserData(user);
        goToStep(steps.successAction);
      }
    } catch (error) {
      console.warn("Error updating data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "user", value: username },
      { key: "email", value: formData.email },
      { key: "firstname", value: formData.firstname },
      { key: "lastname", value: formData.lastname },
      { key: "birthDate", value: birthDate },
      { key: "city", value: place },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressSave(formData);
    } else {
      return;
    }
  };

  const createDateFromString = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    return date;
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {currentStep === steps.default && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              gap={4}
              flexGrow={1}
            >
              <ModalHeaderComponent
                title={i18n.t("yourProfile")}
                subtitle={i18n.t("updateYourProfile")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                actionButtonsRequired={true}
              />
              <ToggleQuestion
                title={i18n.t("automaticallyAcceptInvitations")}
                subTitle={i18n.t("you_will_be_automatically_added_to_a_team")}
                value={formData.automaticallyAcceptInvitations}
                onChange={() => {
                  setFormData((prev) => ({
                    ...prev,
                    automaticallyAcceptInvitations:
                      !prev.automaticallyAcceptInvitations,
                  }));
                }}
              />
              <Grid templateColumns="200px 1fr 1fr" gap={8}>
                <GridItem colSpan={1} display="flex">
                  <FormControl>
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("user")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("user") + "..."}
                          value={username}
                          isReadOnly
                        />
                        {errors.user && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.user}
                          </BodyText>
                        )}
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("name")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("name") + "..."}
                          value={formData.firstname}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              firstname: e.target.value,
                            }));
                            setErrors((prev) => ({ ...prev, firstname: "" }));
                          }}
                        />
                        {errors.firstname && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.firstname}
                          </BodyText>
                        )}
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("lastName")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("lastName") + "..."}
                          value={formData.lastname}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              lastname: e.target.value,
                            }));
                            setErrors((prev) => ({ ...prev, lastname: "" }));
                          }}
                        />
                        {errors.lastname && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.lastname}
                          </BodyText>
                        )}
                      </Flex>
                      <SelectDate
                        error={errors.birthDate}
                        label={i18n.t("birthDate")}
                        placeholder={i18n.t("birthDate")}
                        required={true}
                        date={birthDate}
                        setDate={setBirthDate}
                      />
                    </Flex>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("email")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("email") + "..."}
                          value={formData.email}
                          isReadOnly
                        />
                        {errors.email && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.email}
                          </BodyText>
                        )}
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("cellPhone")}
                        </FormLabel>
                        <Input
                          type="tel"
                          {...inputStyle}
                          placeholder={i18n.t("cellPhone") + "..."}
                          value={formData.phone_number}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              phone_number: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("address")}
                        </FormLabel>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("address") + "..."}
                          value={formData.address}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              address: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <CitySelector
                        error={errors.city}
                        place={place}
                        setPlace={setPlace}
                      />
                    </Flex>
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
          )}
          {currentStep === steps.successAction && (
            <MessageConfirmationComponent
              title={i18n.t("profileUpdated")}
              subtitle={i18n.t("completedAction")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default UpdateOrViewProfileData;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
