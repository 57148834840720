import { MenuItem } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";

interface MenuItemComponentProps {
  activeButton?: string;
  buttonName?: string;
  onClick: () => void;
  activeItem: boolean;
  children: React.ReactNode;
  [key: string]: any;
}

const MenuItemComponent = ({
  activeButton,
  buttonName,
  onClick,
  activeItem,
  children,
  ...menuItemProps
}: MenuItemComponentProps) => {
  const isActive = activeButton === buttonName;

  return (
    <MenuItem
      onClick={onClick}
      background={
        activeItem
          ? isActive
            ? colors.figmaColors.secColor
            : "transparent"
          : "transparent"
      }
      textColor={
        activeItem
          ? isActive
            ? colors.text.white
            : colors.figmaColors.secColor
          : colors.figmaColors.secColor
      }
      borderRadius={12}
      fontSize="13"
      fontWeight="600"
      height="40px"
      border="1px"
      justifyContent="space-between"
      borderColor={colors.figmaColors.secColor}
      transition="background-color 0.3s ease"
      _hover={{
        background: activeItem
          ? isActive
            ? colors.figmaColors.secColor
            : colors.backgrounds.gray3
          : colors.backgrounds.gray3,
      }}
      {...menuItemProps}
    >
      {children}
    </MenuItem>
  );
};

export default MenuItemComponent;
