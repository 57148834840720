import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Box,
  Text,
  Flex,
  Link,
  Avatar,
  VStack,
  SkeletonCircle,
} from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { consumeApi } from "../../utils/tools";
import {
  playStoreUrl,
  appStoreUrl,
} from "../../definitions/constants/GlobalConstants";
import { TournamentsData } from "../../definitions/interfaces/GlobalInterfaces";
import i18n from "../../i18n/i18n";
import IconArrowShort from "../../assets/icons/IconArrowShort";
import { isMacOs, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const PopularLeaguesComponent = () => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [apiResponse, setApiResponse] = useState<TournamentsData>();
  const [loader, setLoader] = useState(true);
  const [scrollLeagues, setScrollLeagues] = useState<Boolean>();
  const CheckLink = isMacOs || isIOS ? appStoreUrl : playStoreUrl;
  const navigate = useNavigate();

  let requestBody = {
    user_id: "anonymous",
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const data = await consumeApi(
        "tournaments/get_recomended_tournaments",
        "POST",
        requestBody
      );
      setApiResponse(data);
      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setScrollLeagues(true);
      } else {
        setScrollLeagues(false);
      }
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (!loader) {
        const referenceComponent = document.getElementById("flex-right");
        const referenceWidth = referenceComponent
          ? referenceComponent.offsetWidth
          : 0;
        const targetComponent = document.getElementById("fixed-leagues");
        if (targetComponent) {
          targetComponent.style.width = `${referenceWidth}px`;
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkTopMargin = (): string => {
    if (isMobile) {
      return "0";
    } else {
      if (scrollLeagues) {
        return "55px";
      } else {
        return "none";
      }
    }
  };

  return (
    <>
      {!loader ? (
        <Box
          zIndex={isMobile ? "unset" : "unset"}
          bg={isMobile ? colors.figmaColors.appBgColor : colors.surface.white}
          paddingX={isMobile ? "25px" : "25px"}
          paddingY={isMobile ? "5px" : "25px"}
          borderRadius={isMobile ? 0 : "20px"}
          width={!isMobile ? "100%" : "100%"}
          marginTop={isMobile ? "0" : "25px"}
          position={isMobile ? "relative" : "relative"}
        >
          <Flex
            justify={isMobile ? "space-around" : "space-between"}
            align="center"
            marginBottom={4}
            width={"100%"}
          >
            <Box>
              <Text fontWeight="600" fontSize="14px" lineHeight="19.94px">
                {" "}
                {i18n.t("popular leagues")}{" "}
              </Text>
            </Box>
            <Box marginLeft={"auto"}>
              <Flex justify={"center"} align={"center"} gap={2}>
                <Link target="_blank" href={CheckLink}>
                  <Text
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="19.94px"
                    color={colors.figmaColors.secColor}
                  >
                    {i18n.t("see all")}
                  </Text>
                </Link>
                <IconArrowShort
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex justify="space-between" align="start" gap={1} wrap={"wrap"}>
            {apiResponse?.tournaments.slice(0, 5).map((tournament) => (
              <Box key={tournament.id} width={"60px"}>
                <VStack spacing={1}>
                  <Box
                    onClick={() => {
                      // console.log("tournament.id", tournament.id)
                      // navigate(`/tournament/${tournament.id}`);
                    }}
                    display="flex"
                    boxSize="60px"
                    borderRadius="100%"
                    border={`4px solid ${colors.figmaColors.secD}`}
                    borderColor={colors.figmaColors.leaguesGradient1}
                    justifyContent="center"
                    alignItems="center"
                    padding="10px"
                  >
                    <Avatar src={tournament.media_url} name={tournament.name} />
                  </Box>
                  <Text
                    color={colors.figmaColors.black}
                    fontSize={"11px"}
                    fontWeight={"600"}
                    lineHeight={"15.66px"}
                    textAlign={"center"}
                    width={"100%"}
                  >
                    {tournament.name}
                  </Text>
                </VStack>
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}

      {loader ? (
        <Flex
          align="center"
          justify="center"
          marginTop={isMobile ? "0" : "25px"}
          p={"25px"}
          bg={isMobile ? "transparent" : colors.surface.white}
          borderRadius={isMobile ? "0" : "20px"}
          width={"100%"}
          marginX={"auto"}
          gap={"10px"}
          flexWrap={"wrap"}
        >
          <Box>
            <SkeletonCircle size={"50px"} />
          </Box>
          <Box>
            <SkeletonCircle size={"50px"} />
          </Box>
          <Box>
            <SkeletonCircle size={"50px"} />
          </Box>
          <Box>
            <SkeletonCircle size={"50px"} />
          </Box>
          <Box>
            <SkeletonCircle size={"50px"} />
          </Box>
        </Flex>
      ) : null}
    </>
  );
};

export default PopularLeaguesComponent;
