import {
  Box,
  Flex,
  Button,
  Menu,
  Avatar,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon } from "../../definitions/constants/IconsComponent";
import BodyText from "../Common/BodyText";
import i18n from "../../i18n/i18n";
import { colors } from "../../definitions/constants/GlobalStyles";
import IconArrowLinear from "../../assets/icons/IconArrowLinear";
import IconArrowShort from "../../assets/icons/IconArrowShort";

type UserMenuHeaderProps = {
  handleSignOut: () => void;
  handleDeleteAccount: () => void;
  handleOpenProfile: () => void;
  handleOpenDocuments: () => void;
  handleOpenSupport: () => void;
  displayName: string;
  userName: string;
  userPhoto?: string;
  userData: any;
  children?: React.ReactNode;
};

const UserMenuHeader = ({
  handleSignOut,
  handleDeleteAccount,
  handleOpenProfile,
  handleOpenDocuments,
  handleOpenSupport,
  displayName,
  userName,
  userPhoto,
  userData,
  children,
}: UserMenuHeaderProps) => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const menuItemProps = {
    borderRadius: 12,
    height: "40px",
    fontSize: 13,
    fontWeight: 600,
    justifyContent: "space-between",
    bg: colors.backgrounds.gray0,
    textColor: colors.text.lightGray3,
  };

  const menuOptions = [
    { onClick: handleOpenProfile, label: i18n.t("optionsProfile") },
    { onClick: handleOpenDocuments, label: i18n.t("documents") },
    { onClick: handleOpenSupport, label: i18n.t("support") },
  ];

  return (
    <Menu>
      {isMobile ? (
        <MenuButton
          as={IconButton}
          icon={<HamburgerIcon />}
          variant="outline"
          borderRadius="50%"
          boxSize="48px"
          minWidth="48px"
        />
      ) : (
        <MenuButton
          as={Button}
          borderRadius="50px"
          rightIcon={<IconArrowShort />}
          bg="transparent"
          p="0 16px 0 0"
          height="auto"
        >
          <Box display="flex" gap={2}>
            <Avatar
              boxSize="48px"
              src={userPhoto}
              name={`${userData?.firstname || ""} ${
                userData?.lastname ? userData.lastname.charAt(0) : ""
              }`}
            />
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <BodyText
                fontSize={14}
                fontWeight="regular"
                color={colors.text.black}
              >
                {displayName}
              </BodyText>
              <BodyText
                fontSize={13}
                fontWeight="bold"
                color={colors.text.black}
              >
                @{userName}
              </BodyText>
            </Flex>
          </Box>
        </MenuButton>
      )}
      <Box className="content-width">
        <MenuList
          borderRadius={16}
          padding={4}
          mt={{
            base: "3px",
            sm: "6px",
            lg: "11px",
          }}
        >
          <BodyText
            fontSize={13}
            fontWeight="regular"
            color={colors.text.lightGray3}
          >
            {i18n.t("options")}
          </BodyText>
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            {isMobile && children}
            {menuOptions.map((option, index) => (
              <MenuItem key={index} onClick={option.onClick} {...menuItemProps}>
                {option.label}
                <IconArrowLinear stroke={colors.figmaColors.secColor} />
              </MenuItem>
            ))}
            <MenuItem
              onClick={handleDeleteAccount}
              {...menuItemProps}
              border="1px"
              borderColor={colors.backgrounds.red}
              textColor={colors.backgrounds.red}
            >
              {i18n.t("deleteAccount")}
              <IconArrowLinear />
            </MenuItem>
            <MenuItem
              onClick={handleSignOut}
              {...menuItemProps}
              bg={colors.backgrounds.red}
              textColor={colors.text.white}
            >
              {i18n.t("closeSession")}
              <IconArrowLinear />
            </MenuItem>
          </Box>
        </MenuList>
      </Box>
    </Menu>
  );
};

export default UserMenuHeader;
