import { params } from "firebase-functions/v1";
import { MatchStatusSpanish } from "../enums/GlobalEnums";
import i18n from "../../i18n/i18n";

export const base_url = "https://wuamball-core-57e4e865a683.herokuapp.com";
// export const base_url = "http://192.168.1.5:5002";

export const stepsTeams = {
  default: "default",
  editTeam: "editTeam",
  successEdit: "successEdit",
  managers: "managers",
  inviteManagers: "inviteManagers",
  successInvite: "successInvite",
  editManagers: "editManagers",
  successEditManagers: "successEditManagers",
  deleteManagers: "deleteManagers",
  successDeleteManagers: "successDeleteManagers",
  requestsSent: "requestsSent",
  deleteTeam: "deleteTeam",
  sureDeletion: "sureDeletion",
  successDelete: "successDelete",
};

export const stepsTournaments = {
  default: "default",
  editTournament: "editTournament",
  successEdit: "successEdit",
  newSeason: "newSeason",
  successNewSeason: "successNewSeason",
  optionsSeason: "optionsSeason",
  seasonName: "seasonName",
  successSeasonName: "successSeasonName",
  finishSeason: "finishSeason",
  successFinishSeason: "successFinishSeason",
  regulations: "regulations",
  successSaveRegulations: "successSaveRegulations",
  modality: "modality",
  newPhase: "newPhase",
  successNewPhase: "successNewPhase",
  editPhase: "editPhase",
  successEditPhase: "successEditPhase",
  createGroup: "createGroup",
  updateGroup: "updateGroup",
  deleteGroup: "deleteGroup",
  deletePhase: "deletePhase",
  successDeletePhase: "successDeletePhase",
  selectTeams: "selectTeams",
  teamsSelection: "teamsSelection",
  successTeamsSelection: "successTeamsSelection",
  createMatches: "createMatches",
  matchesOf: "matchesOf",
  newMatch: "newMatch",
  technicalTeam: "technicalTeam",
  successNewMatch: "newMatch",
  editMatch: "editPhase",
  editTechnicalTeam: "editTechnicalTeam",
  successEditMatch: "successEditMatch",
  deleteMatch: "deleteMatch",
  successDeleteMatch: "successDeleteMatch",
  inviteTeams: "inviteTeams",
  successInviteTeams: "successInviteTeams",
  requestsSent: "requestsSent",
  deleteSeason: "deleteSeason",
  successDeleteSeason: "successDeleteSeason",
  deleteTournament: "deleteTournament",
  sureDeletion: "sureDeletion",
  successDeleteTournament: "successDeleteTournament",
};

export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.wuamball.wuamball";
export const appStoreUrl =
  "https://apps.apple.com/us/app/wuamball/id1665996774";

export const getMatchStatusSpanish = (status: MatchStatusSpanish): string => {
  return i18n.t(status);
};
