import React, { useState, useEffect } from "react";
import { Flex, Box, Spinner } from "@chakra-ui/react";
import MatchInfoCard from "./MatchInfoCard";
import MatchDetails from "./MatchDetails";
import MatchLineups from "./MatchLineups";
import MatchSummary from "./MatchSummary";
import ButtonComponent from "../../../components/Common/ButtonComponent";
import MatchSelected from "./MatchSelected";
import i18n from "../../../i18n/i18n";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore, matchStore } from "../../../zustand/globalStore";
import { doc, onSnapshot } from "firebase/firestore";
import { getTournamentById } from "../../../models/Tournament";
import { MATCH_TABLE_NAME } from "../../../models/ModelDefinition";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../config/firebase";
import { MatchRolUser } from "../../../definitions/enums/GlobalEnums";
import BodyText from "../../../components/Common/BodyText";

const buttonConfig = [
  { key: "teams", label: "teams" },
  { key: "details", label: "details" },
  { key: "alignment", label: "lineups" },
  { key: "summary", label: "summary" },
];

const MatchesRightSide: React.FC = () => {
  const [firstTeam, setFirstTeam] = useState<any>();
  const [secondTeam, setSecondTeam] = useState<any>();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("teams");
  const { userData, setCurrentTournament, matchId } = appStore(
    (state) => state
  );
  const { currentMatch, setCurrentMatch, setIncidences } = matchStore(
    (state) => state
  );
  const typeIncidence = ["goals", "yellow_cards", "red_cards", "substitutions"];

  useEffect(() => {
    if (!matchId) {
      setLoading(false);
      return;
    }

    init();

    const unsubscribe = onSnapshot(
      doc(db, MATCH_TABLE_NAME, matchId),
      (data) => {
        if (data.data()) {
          let match: any = data.data();

          match.id = data.id;

          getTournamentById(match.tournament_id).then((tournament) => {
            setCurrentTournament(tournament);
          });
          setCurrentMatch(match);
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [matchId]);

  const init = async () => {
    try {
      const matchData: any = await httpsCallable(
        functions,
        "getMatchDataWithIncidences"
      )({
        match_id: matchId,
      });
      const tournament = matchData?.data.tournament;
      let match = matchData?.data.match;
      let roles = [MatchRolUser.user];
      if (userData.id === match?.vocal_user_id) roles.push(MatchRolUser.vocal);
      if (userData.id === match?.user_referee_id)
        roles.push(MatchRolUser.referee);
      if (match?.user_id === userData.id) roles.push(MatchRolUser.own);

      if (match?.first_team_id) {
        const firstTeam = matchData?.data.first_team;
        setFirstTeam(firstTeam);
        if (firstTeam.user_id === userData.id) {
          roles.push(MatchRolUser.ownTeam);
        }
      }
      if (match?.second_team_id) {
        const secondTeam = matchData?.data.second_team;
        setSecondTeam(secondTeam);
        if (secondTeam.user_id === userData.id) {
          roles.push(MatchRolUser.ownTeam);
        }
      }
      setIncidences([]);
      let incidences = matchData?.data?.incidences || [];
      incidences.sort((a: any, b: any) => a.minuts - b.minuts);
      setIncidences(incidences.reverse());
      setRoles(roles);
      setCurrentTournament(tournament);
      setLoading(false);
    } catch (error) {
      console.error("Error initializing data: ", error);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center">
        <Spinner size="lg" color="blue.500" />
      </Flex>
    );
  }

  if (!currentMatch?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} gap={3}>
      <Box>
        <MatchSelected
          roles={roles}
          firstTeam={firstTeam}
          secondTeam={secondTeam}
        />
      </Box>
      <Box>
        <Flex
          align="center"
          position="relative"
          overflow="hidden"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          {buttonConfig.map(({ key, label }) => (
            <ButtonComponent
              key={key}
              variant="variantTab"
              buttonName={key}
              activeButton={activeButton}
              onClick={() => setActiveButton(key)}
            >
              {i18n.t(label)}
            </ButtonComponent>
          ))}
        </Flex>
      </Box>
      <Box>
        {activeButton === "teams" && <MatchInfoCard />}
        {activeButton === "details" && (
          <MatchDetails
            roles={roles}
            firstTeam={firstTeam}
            secondTeam={secondTeam}
          />
        )}
        {activeButton === "alignment" && (
          <MatchLineups
            roles={roles}
            firstTeam={firstTeam}
            secondTeam={secondTeam}
          />
        )}
        {activeButton === "summary" && (
          <MatchSummary
            currentMatch={currentMatch}
            typeIncidence={typeIncidence}
          />
        )}
      </Box>
    </Flex>
  );
};

export default MatchesRightSide;
