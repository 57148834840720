import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { colors } from "../definitions/constants/GlobalStyles";
import BodyText from "./Common/BodyText";
import { IncidenceTypes } from "../definitions/enums/GlobalEnums";
import { getUserById } from "../models/UserModel";
import i18n from "../i18n/i18n";
import IconCard from "../assets/icons/IconCard";
import IconSoccerBall from "../assets/icons/IconSoccerBall";
import IconArrowLarge from "../assets/icons/IconArrowLarge";

const incidenceIcons = {
  [IncidenceTypes.goal]: (props) => (
    <IconSoccerBall stroke={colors.figmaColors.secColor} {...props} />
  ),
  [IncidenceTypes.own_goal]: (props) => (
    <IconSoccerBall stroke={colors.backgrounds.red} {...props} />
  ),
  [IncidenceTypes.yellow_card]: (props) => (
    <IconCard fill={colors.backgrounds.yellow} {...props} />
  ),
  [IncidenceTypes.red_card]: (props) => (
    <IconCard fill={colors.backgrounds.red} {...props} />
  ),
};

interface IncidenceProps {
  incidence: any;
  modify: any;
  left: boolean;
  typeUser: any;
  setIncidences: any;
}

const ItemActionMatch: React.FC<IncidenceProps> = ({ incidence, left }) => {
  const [player, setPlayer] = useState<any>({});
  const [newPlayer, setNewPlayer] = useState<any>({});
  const IconComponent = incidenceIcons[incidence.type] || null;

  useEffect(() => {
    const init = async () => {
      const playerData = await getUserById(incidence.player_id);
      setPlayer(playerData || {});
      if (incidence.type === IncidenceTypes.change_player) {
        const newPlayerData = await getUserById(incidence.new_player_id);
        setNewPlayer(newPlayerData || {});
      }
    };
    init();
  }, [incidence]);

  const getMinutesFromTimeString = (time: string): string =>
    time ? time.split(":")[0] : "";

  const getFullName = (player: any) => ({
    firstName: player?.firstname?.split(" ", 1)[0] || i18n.t("input_name"),
    lastName: player?.lastname?.split(" ", 1)[0] || i18n.t("input_last_name"),
  });

  const PlayerNameView = () => (
    <Flex width="100%" flexDirection={"column"} alignItems={"start"}>
      {incidence.type === IncidenceTypes.change_player && (
        <BodyText
          fontStyle="light"
          color={colors.text.black}
          textAlign="center"
          fontWeight="medium"
          size="xxSmall"
        >
          {`${newPlayer.firstname?.split(" ", 1)[0] || ""} ${
            newPlayer.lastname?.split(" ", 1)[0] || ""
          }`}
        </BodyText>
      )}
      <BodyText
        whiteSpace="nowrap"
        color={
          incidence.type === IncidenceTypes.change_player
            ? colors.text.lightGray3
            : colors.text.black
        }
        textAlign="center"
        size={
          incidence.type === IncidenceTypes.change_player
            ? "xxSmall"
            : "extraSmall"
        }
        fontWeight="medium"
      >
        {getFullName(player).firstName} {getFullName(player).lastName}
      </BodyText>
    </Flex>
  );

  const PlayerPositionView = () => (
    <Flex width="100%">
      <BodyText
        color={colors.text.lightGray3}
        fontSize={11}
        fontWeight="regular"
      >
        {i18n.t("position")}
      </BodyText>
    </Flex>
  );

  const IconView = () => (
    <Flex>
      {incidence.type === IncidenceTypes.change_player ? (
        <Flex flexDirection={"column"}>
          <IconArrowLarge stroke={colors.figmaColors.secColor} />
          <IconArrowLarge
            stroke={colors.backgrounds.gray2}
            style={{
              transform: "rotate(180deg)",
            }}
          />
        </Flex>
      ) : IconComponent ? (
        <IconComponent width="20px" height="20px" />
      ) : null}
    </Flex>
  );

  const MinuteView = () => (
    <Flex
      style={{
        width: "10%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.backgrounds.gray0,
      }}
    >
      <BodyText fontWeight="medium" size="small" color={colors.text.lightGray3}>
        {getMinutesFromTimeString(incidence.time)}'
      </BodyText>
    </Flex>
  );

  return (
    <Flex
      justifyContent="space-between"
      width="52.5%"
      alignSelf={left ? "start" : "end"}
      style={{
        flexDirection: "row",
        backgroundColor: colors.backgrounds.gray0,
      }}
    >
      {left ? (
        <>
          <Flex flexDirection="row" alignItems="center" gap={2.5}>
            <IconView />
            <Flex flexDirection={"column"}>
              <PlayerNameView />
              {incidence.type !== IncidenceTypes.change_player && (
                <PlayerPositionView />
              )}
            </Flex>
          </Flex>
          <MinuteView />
        </>
      ) : (
        <>
          <MinuteView />
          <Flex flexDirection="row" alignItems="center" gap={2.5}>
            <Flex flexDirection={"column"}>
              <PlayerNameView />
              {incidence.type !== IncidenceTypes.change_player && (
                <PlayerPositionView />
              )}
            </Flex>
            <IconView />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default ItemActionMatch;
