import React from "react";
import i18n from "../../i18n/i18n";
import { Flex, Button } from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../../components/Common/BodyText";
import { CloseCircleIcon } from "../../definitions/constants/IconsComponent";

const HeaderDefaultOptions = ({ title, description }) => {
  const { hideContainerModal } = appStore((state) => state);

  const SectionHeader = ({ title, description }) => (
    <Flex flexDirection="column">
      <BodyText
        fontSize={{ base: "20px", md: "28px" }}
        fontWeight="light"
        textColor={colors.text.black}
      >
        {title}
      </BodyText>
      <BodyText
        fontSize={{ base: "12px", md: "13px" }}
        fontWeight="regular"
        textColor={colors.text.lightGray3}
      >
        {description}
      </BodyText>
    </Flex>
  );

  return (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent="flex-end" width="100%">
        <Button
          onClick={hideContainerModal}
          p={0}
          height="auto"
          minWidth={0}
          variant="ghost"
          color={colors.text.black}
          _hover={{
            backgroundColor: "transparent",
            color: colors.text.black,
          }}
        >
          <CloseCircleIcon />
        </Button>
      </Flex>
      <SectionHeader title={title} description={description} />
    </Flex>
  );
};

export default HeaderDefaultOptions;
