import { Box, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

function YoutubeVideos() {
  const { videoId } = useParams();

  return (
    <iframe
      width={972}
      height={547}
      src={"https://www.youtube.com/embed/hFd84RqVo3A"}
      title="YouTube Video"
      frameBorder={0}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      sandbox="allow-forms allow-scripts allow-same-origin"
    />
  );
}

export default YoutubeVideos;
