import React from "react";
import { useMediaQuery, Text, Flex, Avatar } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { AuthorProps } from "../../definitions/interfaces/GlobalInterfaces";
import i18n from "../../i18n/i18n";
import {
  EllipseIcon,
  PublicIcon,
} from "../../definitions/constants/IconsComponent";

function PostAuthor({ post }: AuthorProps) {
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  return (
    <Flex gap={2} p={isMobile ? "2" : "0"}>
      <Flex alignSelf="center">
        <Avatar
          boxSize="40px"
          borderRadius="100%"
          src={post.user_profile_image}
          name={post.firstname + post.lastname}
        />
      </Flex>
      <Flex direction="column" justifyContent="center">
        <Text
          fontSize="13px"
          fontWeight="600"
          lineHeight="18.51px"
          color={colors.figmaColors.black}
        >
          {post.firstname}
          {post.lastname}
        </Text>
        <Flex gap={2} alignItems="center">
          <Text
            fontSize="11px"
            fontWeight="400"
            lineHeight="15.66px"
            color={colors.figmaColors.grey3}
          >
            {post.created_minuts < 60
              ? `${i18n.t("posted on")} ${post.created_minuts} ${i18n.t(
                  "minutes"
                )}`
              : post.created_hours < 48
              ? `${i18n.t("posted on")} ${post.created_hours} ${i18n.t(
                  "hours"
                )}`
              : post.created_days < 30
              ? `${i18n.t("posted on")} ${post.created_days} ${i18n.t("days")}`
              : `${i18n.t("posted on")} ${post.date_format}`}
          </Text>
          <EllipseIcon />
          <PublicIcon />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PostAuthor;
