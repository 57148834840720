import React, { useCallback, useEffect, useState } from "react";
import { Box, Spinner, VStack, HStack } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { appStore, matchStore } from "../../../zustand/globalStore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import { consumeApi } from "../../../utils/tools";
import BodyText from "../../../components/Common/BodyText";
import { getMatchStatusSpanish } from "../../../definitions/constants/GlobalConstants";
import i18n from "../../../i18n/i18n";
import ItemTournamentMatchComponent from "../../../components/ItemTournamentMatchComponent";
import MatchItem from "../../../components/MatchItem";

export default function MatchesTournamentComponent() {
  // Zustand states
  const { currentTournament, setCurrentTournament, currentTeam } = appStore(
    (state) => state
  );
  const { setCurrentMatch } = matchStore((state) => state);

  // Local states
  const [matches, setMatches] = useState([]);
  const [isMatchesLoading, setIsMatchesLoading] = useState(true);
  const [phases, setPhases] = useState([]);

  // const navigation = useNavigation();

  useEffect(() => {
    if (currentTournament?.is_external) {
      getTopMatches();
    } else {
      getMatchesForTournamentInternal();
    }
  }, [currentTournament, currentTeam]);

  const getMatchesForTournamentInternal = async () => {
    const responseMatchesByTeams = await consumeApi(
      "/tournaments/get_matches_by_tournament_week",
      "POST",
      { team_id: currentTeam?.id }
    );
    if (responseMatchesByTeams?.status === "success") {
      setPhases(responseMatchesByTeams.data);
      setIsMatchesLoading(false);
    } else {
      setIsMatchesLoading(false);
      setPhases([]);
    }
  };

  const getTopMatches = async () => {
    if (!currentTournament.matches) {
      let season = null;
      if (currentTournament?.seasons.length > 0) {
        season = currentTournament?.seasons[0].year;
      }
      const data = await consumeApi(
        `tournaments/get_matches_by_tournament`,
        "POST",
        {
          tournament_id: currentTournament.id,
          season: season,
        }
      );
      console.log("data");
      console.log(data);
      if (data.status === "success") {
        const matches = data.matches.slice(0, 20);
        setMatches(matches);
        setCurrentTournament({ ...currentTournament, matches: matches });
      }
      setIsMatchesLoading(false);
    } else {
      setMatches(currentTournament.matches);
      setIsMatchesLoading(false);
    }
  };

  useEffect(() => {
    // trackEvent("MatchesTournamentComponent", {});
  }, []);

  const RenderGroup = ({ phase, group }: any) => (
    <Box width={{ md: "auto", base: "100%" }}>
      {/* <Box pl="5" pb='5'>
        <BodyText
          fontWeight="bold"
          size="small"
          color={colors.text.black}
          py="2"

        >
          {phase.name} - {group.name}
        </BodyText>
      </Box> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "10px 0px",
        }}
      >
        <BodyText
          fontWeight="bold"
          size="small"
          color={colors.figmaColors.secColor}
          py="2"
        >
          {phase.tournamnet_name}
        </BodyText>
        <span
          style={{
            display: "inline-block",
            width: "5px",
            height: "5px",
            borderRadius: "10px",
            background: colors.figmaColors.secColor,
          }}
        ></span>
        <BodyText
          fontWeight="bold"
          size="small"
          color={colors.text.black}
          py="2"
        >
          {phase.name} - {group.name}
        </BodyText>
      </Box>
      <Box width={{ md: "auto", base: "100%" }}>
        {group.matches.map((match, index) => (
          <Box key={index}>
            <HStack
              flexDirection="row-reverse"
              pl="6"
              position="relative"
              mb="-3"
              zIndex="10"
              w="full"
              bgColor={colors.backgrounds.white}
            >
              <Box
                bgColor={
                  match.status !== "finished"
                    ? colors.backgrounds.green
                    : colors.backgrounds.lightRed
                }
                px="3"
              >
                <BodyText
                  fontWeight="bold"
                  size="extraSmall"
                  color={colors.text.white}
                >
                  {match.status ? getMatchStatusSpanish[match.status] : ""}
                </BodyText>
              </Box>
            </HStack>
            <MatchItem
              match={match}
              onClick={() => {
                console.log("12312");
                // setCurrentMatch(null);
                // navigate("MatchScreen", { matchId: match.id });
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderItemPhases = ({ item }) => (
    <Box width="100%">
      {item.have_groups ? (
        <>
          {item?.groups?.map((group, index) => (
            <RenderGroup phase={item} group={group} key={index} />
          ))}
        </>
      ) : (
        <Box>
          <Box py="2" px="5">
            <BodyText
              fontWeight="bold"
              size="small"
              color={colors.text.black}
              ml="1"
            >
              {item.name}
            </BodyText>
          </Box>
          {item.matches &&
            item.matches.map((match, index) => (
              <Box key={index}>
                <HStack
                  flexDirection="row-reverse"
                  pl="6"
                  position="relative"
                  mb="-3"
                  zIndex="10"
                  w="full"
                  bgColor="rgba(52, 52, 52, alpha)"
                >
                  {/* <Box
                    bgColor={
                      match.status !== "finished"
                        ? colors.backgrounds.green
                        : colors.backgrounds.lightRed
                    }
                    borderRadius="md"
                    px="3"
                  // align="center"
                  >
                    <BodyText
                      fontWeight="bold"
                      size="extraSmall"
                      color={colors.text.white}
                    >
                      {match.status ? getMatchStatusSpanish(match.status) : ""}
                    </BodyText>
                  </Box> */}
                </HStack>
                <MatchItem
                  match={match}
                  onClick={() => {
                    setCurrentMatch(null);
                    // navigation.navigate("MatchScreen", { matchId: match.id });
                  }}
                />
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );

  const PhasesList = useCallback(
    () => (
      <Box>
        <VStack>
          {phases?.map((item, index) => (
            <React.Fragment key={index}>
              {renderItemPhases({ item })}
            </React.Fragment>
          ))}
        </VStack>
      </Box>
    ),
    [phases]
  );

  return (
    <Box gap="4" display="flex" flexDirection="column">
      {isMatchesLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="2"
          flexShrink="0"
          mt="4"
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <BodyText
          color={colors.text.black}
          textAlign="left"
          size="extraSmall"
          fontWeight="semiBold"
        >
          {i18n.t("matches")}
        </BodyText>
      )}
      {currentTournament?.is_external ? (
        <>
          {matches.map((match, index) => (
            <ItemTournamentMatchComponent key={index} match={match} />
          ))}
        </>
      ) : (
        <PhasesList />
      )}
    </Box>
  );
}
