import React, { useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { appStore } from "../../../zustand/globalStore";
import ModalHeaderComponent from "../../../components/Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import MessageConfirmationComponent from "../../../components/MessageConfirmationComponent";

const steps = {
  default: "default",
  newCup: "newCup",
  selectTournaments: "selectTournaments",
  successScreen: "successScreen",
  editCup: "editCup",
  successEdit: "successEdit",
  deleteCup: "deleteCup",
  successDelete: "successDelete",
};

const CreateOrEditCupView = () => {
  const dataForm = {};
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(dataForm);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);
  const { hideContainerModal } = appStore((state) => state);

  const onPressCreateCup = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      goToStep(steps.successScreen);
    } catch (error) {
      console.error("Error creating cup:", error);
    } finally {
      setIsSaving(false);
    }
  }; // TODO: implement this function

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateCup(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={currentStep === steps.deleteCup ? 8 : 4}
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {currentStep === steps.default && (
            <ModalHeaderComponent
              title={i18n.t("titleCup")}
              subtitle={i18n.t("msgCreateCup")}
              confirmText={i18n.t("createCup")}
              onCancel={hideContainerModal}
              onConfirm={handleSubmit}
              isSaving={isSaving}
              actionButtonsRequired={true}
            />
          )}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("cupReady")}
              subtitle={i18n.t("operationCompleted")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default CreateOrEditCupView;
