import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import { appStore } from "../../../zustand/globalStore";

import { useEffect, useState } from "react";
import { steps } from "./containers/steps.container";
import RegisterPlayer from "../RegisterPlayer/Register&EditPlayer";
import InvitePlayers from "../InvitePlayer/InvitePlayers";
import "./styles/Players.style.scss";
import {
  MessageDotIcon,
  ProfileIcon,
} from "../../../definitions/constants/IconsComponent";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import MessageConfirmationComponent from "../../MessageConfirmationComponent";
import { getPlayersByTeamAndTournament, removePlayerApplication } from "../../../models/TeamModel";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import ItemPlayerUser from "../../Common/ItemPlayerUser";
import DeleteContentModal from "../../Modals/DeleteContentModal";
import { select } from "firebase-functions/params";
import RegisterAndEditPlayer from "../RegisterPlayer/Register&EditPlayer";

const Players = () => {
  const { hideContainerModal } = appStore((store) => store);
  const [step, setStep] = useState(steps.default);
  const { currentTournament, currentTeam } = appStore((store) => store);
  const [playersTeam, setPlayersTeam] = useState<User[]>([]);
  const [selectPlayer, setSelectPlayer] = useState<User>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getPlayersByTeamAndTournament(
        currentTeam?.id,
        currentTournament?.id
      );
      setPlayersTeam(response);
      console.log(response, "respinse");
      setLoading(false);
    })();
  }, [currentTournament?.id, currentTeam?.id]);
  
  const handleDeletePlayer = async() => {
    if(selectPlayer){
        setLoading(true);
        await removePlayerApplication(selectPlayer)
        setLoading(false);
        setStep(steps.successDeletePlayer)
    }
  }

  return (
    <Flex
      direction={"column"}
      width={"100%"}
    >
      {step === steps.default && (
        <Box className="container-default">
          <ModalHeaderComponent
            title={i18n.t("players")}
            subtitle={i18n.t("get_ready_for_the_tournament")}
            hasMoreSteps={true}
            actionButtonsRequired={false}
            onBack={null}
            onCancel={hideContainerModal}
          />
          <Box className="container-actions">
            <Button
              leftIcon={<MessageDotIcon />}
              className="button-action"
              sx={{
                border:
                  step === steps.invitePlayer
                    ? `1px solid ${colors.figmaColors.secColor}`
                    : `none`,
              }}
              onClick={() => setStep(steps.invitePlayer)}
            >
              <BodyText
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="regular"
                textColor={colors.figmaColors.secColor}
              >
                {i18n.t("invitePlayers")}
              </BodyText>
            </Button>
            <Button
              leftIcon={<ProfileIcon />}
              className="button-action"
              sx={{
                border:
                  step === steps.registerPlayer
                    ? `1px solid ${colors.figmaColors.secColor}`
                    : `none`,
              }}
              onClick={() => setStep(steps.registerPlayer)}
            >
              <BodyText
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="regular"
                textColor={colors.figmaColors.secColor}
              >
                {i18n.t("registerPlayers")}
              </BodyText>
            </Button>
          </Box>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={"20px 0px"}
            >
              <Spinner size="lg" color="blue.500" />
            </Box>
          ) : (
            <Flex direction={"column"}>
              {playersTeam.length > 0 &&
                playersTeam.map((item, index) => {
                  return (
                    <ItemPlayerUser
                      item={item}
                      setUser={setSelectPlayer}
                      userActive={selectPlayer}
                      goStep={setStep}
                    />
                  );
                })}
            </Flex>
          )}
        </Box>
      )}
      {step === steps.invitePlayer && <InvitePlayers goStep={setStep} />}
      {step === steps.registerPlayer && <RegisterAndEditPlayer action="create"  goToStep={setStep} />}
      {step === steps.successInvitePlayer && (
        <MessageConfirmationComponent
          title={i18n.t("invitationSuccessfully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {step === steps.successRegisterPlayer&&(
        <MessageConfirmationComponent
          title={i18n.t("aNewPlayerHasBeenRegistered")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {step === steps.successEditPlayer&&(
        <MessageConfirmationComponent
          title={i18n.t("playerUpdatedSuccessFully")}
          subtitle={i18n.t("completedAction")}
          isDelete={false}
          onClick={hideContainerModal}
        />
      )}
      {step === steps.successDeletePlayer && (
        <MessageConfirmationComponent
          title={i18n.t("playerDeleted")}
          subtitle={i18n.t("completedAction")}
          isDelete={true}
          onClick={hideContainerModal}
      />
      )}
      {step === steps.deletePlayer && (
        <DeleteContentModal
          message={i18n.t("sureDeletePlayer", { manager: `${selectPlayer.firstname} ${selectPlayer.lastname}`,  })}
          onCancel={hideContainerModal}
          onConfirm={handleDeletePlayer}
          loading={loading}
        />
      )}
       {step === steps.editPlayer && <RegisterAndEditPlayer action="edit"  goToStep={setStep} player={selectPlayer}/>}
    </Flex>
  );
};

export default Players;
