import React from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import MatchHeader from "./MatchHeader";
import TeamScore from "../../../components/Shared/TeamScore";
import MatchFooterCard from "./MatchFooterCard";
import { appStore } from "../../../zustand/globalStore";
import { MatchIncidence } from "../../../definitions/interfaces/GlobalInterfaces";

interface MatchItemProps {
  item: MatchIncidence;
  index: any;
  onClick: () => void;
}

const MatchCardItem: React.FC<MatchItemProps> = ({ item, index, onClick }) => {
  const { activeCardIndex, setActiveCardIndex } = appStore((state) => state);
  const isLeftTeamHigherScore = item.goals_first_team > item.goals_second_team;
  const isRightTeamHigherScore = item.goals_second_team > item.goals_first_team;

  return (
    <Flex
      flexDirection="column"
      align="center"
      borderRadius={24}
      gap="8px"
      padding={"8px 16px"}
      border="1px solid"
      borderColor={
        activeCardIndex === index
          ? colors.figmaColors.borderCard
          : colors.figmaColors.grey1
      }
      onClick={() => {
        setActiveCardIndex(index);
        onClick();
      }}
      cursor="pointer"
    >
      <MatchHeader
        //league={item.tournamnet_name}
        //leagueImage={item.tournamnet_media_url}
        //phase={item.phase || i18n.t("friendly")}
        status={item.status}
      />
      <Flex gap={1.5} width="100%" alignItems="center" justifyContent="center">
        <TeamScore
          teamImage={item.first_team_media_url}
          team={item.first_team_name}
          score={item.goals_first_team}
          showArrowIcon={isLeftTeamHigherScore}
        />
        <BodyText fontSize={17} fontWeight="semiBold" color={colors.text.black}>
          -
        </BodyText>
        <TeamScore
          teamImage={item.second_team_media_url}
          team={item.second_team_name}
          score={item.goals_second_team}
          showArrowIcon={isRightTeamHigherScore}
          reverse
        />
      </Flex>
      <MatchFooterCard
        day={item?.day ?? i18n.t("undefined")}
        date={item?.number_day ?? i18n.t("undefined")}
        month={item?.month ?? i18n.t("undefined")}
        hour={item?.hour ?? i18n.t("undefined")}
        minutes={item?.minuts ?? i18n.t("undefined")}
        location={item?.field_name ?? i18n.t("undefined")}
        status={item.status}
      />
    </Flex>
  );
};

export default MatchCardItem;
