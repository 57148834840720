import React, { useEffect, useState } from "react";
import i18n from "../../../i18n/i18n";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { getCurrentTournamentsForTeam } from "../../../models/Tournament";
import CardTournamentFromTeam from "./CardTournamentFromTeam";
import { ReactDispatchStringState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";

const TournamentsFromTeam: React.FC<{
  teamId: string;
  setTabActive: ReactDispatchStringState;
}> = ({ teamId, setTabActive }) => {
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCurrentTournamentsForTeam(teamId);
      setLoading(false);
      setTournaments(response);
    })();
  }, [teamId]);
  
  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="2"
          flexShrink="0"
          mt="4"
        >
          <Spinner size="lg" />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          gap={"10px"}
        >
          {!loading && tournaments?.length > 0 ? (
            tournaments.map((item, index) => (
              <CardTournamentFromTeam
                tournament={item}
                setTabActive={setTabActive}
              />
            ))
          ) : (
            <Box>{i18n.t("noData")}</Box>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default TournamentsFromTeam;
