import React, { useEffect, useState } from "react";
import { useMediaQuery, Grid, GridItem, Button } from "@chakra-ui/react";
import { trackEvent } from "../utils/tools";
import PostComponent from "../components/PostComponent/PostComponent";
import PopularLeaguesComponent from "../components/PopularLeagues/PopularLeaguesComponent";
import MatchLivesComponent from "../components/MatchLivesComponent/MatchLivesComponent";
import DownloadComponent from "../components/DownloadComponent/DownloadComponent";
import PopUpComponent from "../components/PopUpComponent/PopUpComponent";
import HomeTitle1 from "../components/HomePage/HomeTitle1";
import HomeTitle2 from "../components/HomePage/HomeTitle2";
import i18n from "../i18n/i18n";
import { appStore } from "../zustand/globalStore";
import { TypeViewToShow } from "../definitions/enums/GlobalEnums";

const HomePage: React.FC = () => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [showPopUp, setShowPopUp] = useState(false);
  const {showContainerModal} = appStore(store=>store)
  const CheckLanguage = () => {
    if (navigator.language === "es") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const openPopUp = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    CheckLanguage();
    trackEvent("Home Page Visited", {});
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const popup = localStorage.getItem("popup");
      if (popup !== null && popup === "showed") {
        setShowPopUp(false);
      } else {
        localStorage.setItem("popup", "showed");
        setShowPopUp(true);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showPopUp ? <PopUpComponent openPopUp={openPopUp} /> : null}

      <Grid
        marginTop="68px"
        p="0"
        width={"100%"}
        templateColumns={{ base: "100%", md: "60%", lg: "64% 34%" }}
        gridColumnGap="2%"
        justifyContent="center"
      >
        <GridItem order={{ base: 3, md: 3, lg: 1 }}>
          <HomeTitle1 />
        </GridItem>

        <GridItem order={{ base: 1, md: 1, lg: 2 }}>
          <HomeTitle2 />
        </GridItem>

        <GridItem order={{ base: 4, md: 4, lg: 3 }}>
          <PostComponent />
        </GridItem>

        <GridItem order={{ base: 2, md: 2, lg: 4 }}>
          <PopularLeaguesComponent />
          <MatchLivesComponent />
          <DownloadComponent />
        </GridItem>
      </Grid>
    </>
  );
};

export default HomePage;
