import { useMediaQuery, Box, Text } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";

function HomeTitle1() {
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  return (
    <Box paddingX={isMobile ? "10px" : 0} paddingY={isMobile ? "10px" : 0}>
      <Text
        color={colors.figmaColors.secD}
        fontWeight="600"
        fontSize="17px"
        lineHeight="24.21px"
      >
        {i18n.t("home text 1")}
      </Text>
      <Text
        color={colors.figmaColors.grey3}
        fontWeight="500"
        fontSize="13px"
        lineHeight="18.51px"
      >
        {i18n.t("home text 2")}
      </Text>
    </Box>
  );
}

export default HomeTitle1;
