import { background, Button } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";

const ButtonComponent = ({ children, variant, ...props }) => {
  const variantStyles = {
    variantAccess: {
      display: "flex",
      height: "60px",
      padding: "0px 20px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      alignSelf: "stretch",
      borderRadius: "20px",
      background: colors.figmaColors.leaguesGradient1,
      _hover: {
        background: colors.figmaColors.leaguesGradient2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      },
    },
    variantActive: {
      p: "10px",
      width: "100%",
      minWidth: "fit-content",
      borderRadius: 18,
      fontSize: "14px",
      fontWeight: 600,
      _focus: { boxShadow: "none" },
      _hover: {
        background:
          props.activeButton === props.buttonName
            ? colors.figmaColors.secColor
            : colors.backgrounds.gray3,
      },
      transition: "background-color 0.3s ease",
      background:
        props.activeButton === props.buttonName
          ? colors.figmaColors.secColor
          : "transparent",
      color:
        props.activeButton === props.buttonName
          ? colors.text.white
          : colors.text.lightGray3,
    },
    variantTab: {
      p: "10px",
      width: "fit-content",
      borderRadius: "16px 16px 0px 0px",
      fontSize: "14px",
      fontWeight: 600,
      _focus: { boxShadow: "none" },
      _hover: {
        background:
          props.activeButton === props.buttonName
            ? colors.backgrounds.black
            : colors.backgrounds.gray3,
      },
      transition: "background-color 0.3s ease",
      background:
        props.activeButton === props.buttonName
          ? colors.backgrounds.black
          : "transparent",
      color:
        props.activeButton === props.buttonName
          ? colors.text.white
          : colors.text.lightGray3,
    },
    variantInfo: {
      p: "0 15px",
      borderRadius: "10px",
      fontSize: "13px",
      fontWeight: 600,
      border: "1px",
      background: "transparent",
      color: colors.figmaColors.secColor,
      borderColor: colors.figmaColors.secColor,
      _focus: { boxShadow: "none" },
    },

    variantMenu: {
      p: "10px",
      width: "fit-content",
      borderRadius: "16px 16px 0px 0px",
      fontSize: props.activeButton === props.buttonName ? "14px" : "13px",
      fontWeight: props.activeButton === props.buttonName ? 600 : 400,
      _focus: { boxShadow: "none" },
      _hover: {
        background: colors.backgrounds.white,
      },
      transition: "background-color 0.3s ease, border-color 0.3s ease",
      background: "transparent",
      color:
        props.activeButton === props.buttonName
          ? colors.figmaColors.secColor
          : colors.text.lightGray3,
      borderBottom:
        props.activeButton === props.buttonName
          ? `3px solid ${colors.figmaColors.secColor}`
          : "none", // Underlined if active
    },
  };

  const variantStyle = variantStyles[variant] || {};

  return (
    <Button {...props} sx={variantStyle}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
