export const steps = {
    default: "default",
    invitePlayer: "invitePlayer",
    registerPlayer: "registerPlayer",
    editPlayer: "editPlayer",
    deletePlayer: "deletePlayer",
    successInvitePlayer: "successInvitePlayer",
    successRegisterPlayer: "successRegisterPlayer",
    successDeletePlayer:  "successDeletePlayer",
    successEditPlayer: "successEditPlayer"
}