import { Box, Flex, Spinner } from "@chakra-ui/react";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import IconAddSquare from "../../../../../assets/icons/IconAddSquare";
import BodyText from "../../../../../components/Common/BodyText";
import i18n from "../../../../../i18n/i18n";
import { useEffect, useState } from "react";
import {
  getCurrentManagersFromTeam,
  removeManagerTeam,
} from "../../../../../models/TeamModel";
import { appStore } from "../../../../../zustand/globalStore";
import { User } from "../../../../../definitions/interfaces/Users/Users.interface";
import ItemManagerTeam from "./components/ItemManagerTeam";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";

const ManagersOptionTeams = ({
  goToStep,
  hideContainerModal,
}: {
  goToStep: (key: string) => void;
  hideContainerModal: () => void;
}): JSX.Element => {
  const [managers, setManagers] = useState<User[]>([]);
  const [deleteManager, setDeleteManager] = useState<User | false>(false);
  const { currentTeam, userData } = appStore((store) => store);
  const [loading, setLoading] = useState(false);
  const goDeleteManager = ({ manager }) => {
    setDeleteManager(manager);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCurrentManagersFromTeam(currentTeam.id);
      setManagers([userData, ...response]);
      setLoading(false);
    })();
  }, [currentTeam.id, userData]);

  const handleConfirm = async () => {
    if (deleteManager) {
      setLoading(true);
      await removeManagerTeam(deleteManager.id, currentTeam.id);
      goToStep(stepsTeams.successDeleteManagers);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={999}
      >
        <Spinner size="lg" color="blue.500" />
      </Box>
    );
  }

  if (deleteManager) {
    return (
      <DeleteContentModal
        message={i18n.t("sureDeleteManager", {
          manager: `${deleteManager.firstname} ${deleteManager.lastname}`,
        })}
        onCancel={hideContainerModal}
        onConfirm={handleConfirm}
      />
    );
  }

  return (
    <Flex flexDirection="column" gap={4} flexGrow={1}>
      <Flex
        padding="0px 16px"
        borderRadius={16}
        gap={3}
        height="60px"
        alignItems="center"
        cursor="pointer"
        justifyContent="center"
        backgroundColor={colors.backgrounds.gray0}
        onClick={() => goToStep(stepsTeams.inviteManagers)}
      >
        <IconAddSquare stroke={colors.text.lightGray3} />
        <BodyText
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="medium"
          textColor={colors.text.lightGray3}
        >
          {i18n.t("addManager")}
        </BodyText>
      </Flex>
      {managers.length > 0 &&
        managers.map((item) => {
          return (
            <ItemManagerTeam item={item} goDeleteManager={goDeleteManager} />
          );
        })}
    </Flex>
  );
};

export default ManagersOptionTeams;
