import React, { useState } from "react";
import { Box, FormLabel, Input, Flex, Spinner } from "@chakra-ui/react";
import { consumeApi } from "../../../../../utils/tools";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import ToggleQuestion from "../../../../../components/Shared/ToggleQuestion";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";

interface StepData {
  [key: string]: any;
}

interface CreateSeasonBaseProps {
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string, data?: StepData) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditSeasonProps =
  | (CreateSeasonBaseProps & { isEdit: false; selectedSeason?: never })
  | (CreateSeasonBaseProps & {
      isEdit: true;
      selectedSeason: { name: string; [key: string]: any };
    });

const CreateOrEditSeason: React.FC<CreateOrEditSeasonProps> = ({
  isEdit,
  steps,
  goToStep,
  currentStep,
  isSaving,
  setIsSaving,
  selectedSeason,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDuplicateRegulations, setIsDuplicateRegulations] = useState(false);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedSeason.name : "",
  };
  const [formData, setFormData] = useState(dataForm);

  const onPressCreateOrEditSeason = async (data: any) => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isEdit) {
        const payloadEdit = {
          season_id: selectedSeason.season_id,
          season_name: data.name.trim(),
        };

        await consumeApi(`tournaments/edit_season`, "POST", payloadEdit);
        goToStep(steps.successSeasonName);
      } else {
        const payloadCreate = {
          tournament_id: currentTournament.id,
          season_name: data.name.trim() || i18n.t("season") + " 1",
          season_year: new Date().getFullYear(),
          duplicate_regulations: isDuplicateRegulations,
        };

        const createdSeason = await consumeApi(
          `tournaments/set_season`,
          "POST",
          payloadCreate
        );

        goToStep(steps.successNewSeason, { name: createdSeason.season.name });
      }
    } catch (error) {
      console.error("Error creating or updating season: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditSeason(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.seasonName
            : currentStep === steps.newSeason) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              {isEdit ? (
                <ModalHeaderComponent
                  title={i18n.t("seasonName")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.optionsSeason)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("newSeason")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.default)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              )}
              {!isEdit && (
                <ToggleQuestion
                  title={i18n.t("duplicateRegulations")}
                  subTitle={i18n.t("regulationsAdded")}
                  value={isDuplicateRegulations}
                  onChange={() =>
                    setIsDuplicateRegulations(!isDuplicateRegulations)
                  }
                />
              )}
              <Flex gap={1} flexDirection={"column"}>
                <Flex gap={1} alignItems="center">
                  <FormLabel {...formLabelStyle}>
                    {i18n.t("seasonName")}
                  </FormLabel>
                  <BodyText
                    fontSize={{ base: "12px", md: "13px" }}
                    fontWeight="regular"
                    textColor={colors.text.lightGray3}
                  >
                    {i18n.t("required")}
                  </BodyText>
                </Flex>
                <Input
                  type="text"
                  {...inputStyle}
                  placeholder={i18n.t("seasonName") + "..."}
                  defaultValue={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                    setErrors((prev) => ({ ...prev, name: "" }));
                  }}
                />
                {errors.name && (
                  <BodyText
                    paddingLeft={4}
                    fontSize={{ base: "12px", md: "13px" }}
                    fontWeight="regular"
                    textColor={colors.text.red}
                  >
                    {errors.name}
                  </BodyText>
                )}
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditSeason;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
