import React from "react";

const IconSoccerBall = ({
  fill = "none",
  stroke = "currentColor",
  width = "20",
  height = "20",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="iconoir:soccer-ball">
      <path
        id="Vector"
        d="M9.99984 7.16602L13.1698 9.46935M9.99984 7.16602L6.82984 9.46935M9.99984 7.16602V4.66602M13.1698 9.46935L11.959 13.196H8.0415M13.1698 9.46935L15.4165 8.41602M6.82984 9.46935L8.0415 13.196M6.82984 9.46935L4.58317 8.41602M9.99984 4.66602L7.1865 2.65268M9.99984 4.66602L12.8132 2.65268M15.4165 8.41602L18.2915 11.3327M15.4165 8.41602L16.2498 4.98685M8.0415 13.196L6.6665 15.0827M11.9598 13.196L13.3332 15.0827M13.3332 15.0827L17.2182 14.666M13.3332 15.0827L11.2498 18.7393M6.6665 15.0827L2.7815 14.666M6.6665 15.0827L8.74984 18.7393M4.58317 8.41602L1.70817 11.3327M4.58317 8.41602L3.74984 4.98685M18.3332 10.4993C18.3332 15.1018 14.6023 18.8327 9.99984 18.8327C5.39734 18.8327 1.6665 15.1018 1.6665 10.4993C1.6665 5.89685 5.39734 2.16602 9.99984 2.16602C14.6023 2.16602 18.3332 5.89685 18.3332 10.4993Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default IconSoccerBall;
