import { useState, useEffect } from "react";
import { Box, Text, Grid, Center } from "@chakra-ui/react";
import { appStore } from "../../../zustand/globalStore";
import { trackEvent } from "../../../utils/tools";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import bestTeamBackground from "../../../assets/images/bestTeamBackground.jpeg";
import { getTeamsFromTournament } from "../../../models/Tournament";
import TournamentCardComponent from "../tournament/TournamentCardComponent";

export default function DetailsTeamComponent() {
  // Zustand states
  const { currentTournament, setCurrentTournament } = appStore(
    (state) => state
  );
  // Local states

  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const init = async () => {
    getTeamsFromTournament(currentTournament?.id).then((teams) =>
      setTeams(teams)
    );
  };

  useEffect(() => {
    init();
  }, [currentTournament]);

  useEffect(() => {
    trackEvent("DetailsTeamComponent", {});
  }, []);

  const renderPost = (item: any) => {
    return (
      item.media_url && (
        <Box
          as="button"
          onClick={() => {
            // navigation.navigate("PostScreenInside", { post: item })
          }}
          w="30%"
          p={2}
        >
          {/* <VideoItemGrid post={item} /> */}
        </Box>
      )
    );
  };

  const notResult = () => {
    return (
      <Center h="30vh">
        <Text fontWeight="semibold" fontSize="xl" color="black">
          {i18n.t("dontPostYet")}
        </Text>
        <Text fontWeight="regular" fontSize="sm" color="gray.400">
          {i18n.t("coming_soon")}
        </Text>
      </Center>
    );
  };

  return (
    <Box w="100%">
      <Grid
        templateColumns="1fr 1fr"
        gap={2}
        p={6}
        bg={colors.backgrounds.gray0}
        borderRadius={20}
      >
        <Box display="flex" justifyContent="flex-start" pb={2}>
          <BodyText
            color={colors.text.black}
            textAlign="left"
            size="small"
            fontWeight="semiBold"
          >
            {i18n.t("teamInformation")}
          </BodyText>
        </Box>
        <Box display="flex" justifyContent="flex-end"></Box>

        <Box display="flex" justifyContent="flex-start">
          <BodyText
            color={colors.text.lightGray3}
            textAlign="left"
            size="extraSmall"
            fontWeight="semiBold"
          >
            {i18n.t("coach")}
          </BodyText>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <BodyText
            color={colors.figmaColors.secColor}
            textAlign="left"
            size="small"
            fontWeight="regular"
          >
            Juan Perez
          </BodyText>
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <BodyText
            color={colors.text.lightGray3}
            textAlign="left"
            size="extraSmall"
            fontWeight="semiBold"
          >
            {i18n.t("foundation")}
          </BodyText>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <BodyText
            color={colors.text.black}
            textAlign="left"
            size="small"
            fontWeight="regular"
          >
            2 diciembre
          </BodyText>
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <BodyText
            color={colors.text.lightGray3}
            textAlign="left"
            size="extraSmall"
            fontWeight="semiBold"
          >
            {i18n.t("city")}
          </BodyText>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <BodyText
            color={colors.text.black}
            textAlign="left"
            size="small"
            fontWeight="regular"
          >
            Ecuador
          </BodyText>
        </Box>
      </Grid>

      <Box paddingTop={5}>
        <BodyText
          color={colors.text.black}
          textAlign="left"
          size="extraSmall"
          fontWeight="semiBold"
        >
          {i18n.t("Tournaments")}
        </BodyText>
      </Box>

      <Box paddingTop={5}>
        <TournamentCardComponent tournaments={tournaments} />
      </Box>
    </Box>
  );
}
