import React, { useState, useEffect, useCallback } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../Common/BodyText";
import { CloseCircleIcon } from "../../definitions/constants/IconsComponent";
import { consumeApi } from "../../utils/tools";
import IconEdit from "../../assets/icons/IconEdit";
import i18n from "../../i18n/i18n";
import IconAddSquare from "../../assets/icons/IconAddSquare";
import IconTrash from "../../assets/icons/IconTrash";
import ModalHeaderComponent from "../Common/ModalHeaderComponent";
import HeaderDefaultOptions from "../Common/HeaderDefaultOptions";

const steps = {
  default: "default",
  createDocument: "createDocument",
  editDocument: "editDocument",
  deleteDocument: "deleteDocument",
  successAction: "successAction",
};

interface StepData {
  name?: string;
}

function ShowDocumentsUserView() {
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [stepData, setStepData] = useState<StepData>({});
  const [currentStep, setCurrentStep] = useState(steps.default);
  const [stepHistory, setStepHistory] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const goToStep = (stepKey: string, data?: StepData) => {
    setStepHistory((prevHistory) => [...prevHistory, currentStep]);
    setCurrentStep(stepKey);
    if (data) setStepData((prevData) => ({ ...prevData, ...data }));
  };

  const fetchSeasons = useCallback(async () => {
    try {
      const payload = { tournament_id: currentTournament?.id };
      const response = await consumeApi(
        "tournaments/get_seasons",
        "POST",
        payload
      );

      if (response.status === "success") {
        setSeasons(response.seasons);
      } else {
        console.error("Error fetching seasons:", response.message);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }, [currentTournament?.id, setSeasons]);

  useEffect(() => {
    if (currentTournament?.id) {
      fetchSeasons();
    }
  }, [fetchSeasons, currentTournament]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={4}
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      <HeaderDefaultOptions
        title={i18n.t("documents")}
        description={i18n.t("signedByChampions")}
      />
      <Flex flexDirection="column" gap={4} flexGrow={1}>
        <Flex
          padding="0px 16px"
          borderRadius={16}
          gap={3}
          height="60px"
          alignItems="center"
          cursor="pointer"
          justifyContent="center"
          backgroundColor={colors.backgrounds.gray0}
          onClick={() => goToStep(steps.createDocument)}
        >
          <IconAddSquare stroke={colors.text.lightGray3} />
          <BodyText
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="medium"
            textColor={colors.text.lightGray3}
          >
            {i18n.t("newDocument")}
          </BodyText>
        </Flex>
        {seasons.length > 0 ? (
          seasons.map((season) => (
            <Flex
              padding="0px 16px"
              borderRadius={16}
              gap={3}
              height="60px"
              alignItems="center"
              justifyContent="space-between"
              border="1px solid"
              borderColor={colors.backgrounds.gray1}
            >
              <Flex direction="column">
                <BodyText
                  fontSize={{ base: "12px", md: "14px" }}
                  fontWeight="medium"
                  textColor={colors.text.black}
                >
                  {season.name}
                </BodyText>
                <BodyText
                  fontSize={{ base: "11px", md: "12px" }}
                  fontWeight="regular"
                  textColor={colors.text.lightGray3}
                >
                  {season.year}
                </BodyText>
              </Flex>
              <Flex gap={3}>
                <IconEdit
                  stroke={colors.figmaColors.secColor}
                  cursor="pointer"
                  onClick={() => {
                    setSelectedSeason(season);
                    goToStep(steps.editDocument);
                  }}
                />
                <IconTrash
                  stroke={colors.backgrounds.red}
                  cursor="pointer"
                  onClick={() => {
                    setSelectedSeason(season);
                    goToStep(steps.deleteDocument);
                  }}
                />
              </Flex>
            </Flex>
          ))
        ) : (
          <BodyText
            fontSize={{ base: "11px", md: "12px" }}
            fontWeight="semiBold"
            textColor={colors.text.black}
          >
            {i18n.t("noData")}
          </BodyText>
        )}
      </Flex>
    </Box>
  );
}

export default ShowDocumentsUserView;
