import React, { useState } from "react";
import { Box, FormLabel, Input, Flex, Spinner } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import BodyText from "../../../../../components/Common/BodyText";
import { colors } from "../../../../../definitions/constants/GlobalStyles";
import { createGroup, editGroup } from "../../../../../models/Tournament";
import ModalHeaderComponent from "../../../../../components/Common/ModalHeaderComponent";

interface CreateGroupBaseProps {
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  onBack: () => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateOrEditGroupProps =
  | (CreateGroupBaseProps & {
      isEdit: false;
      selectedPhase?: never;
      selectedGroup?: never;
    })
  | (CreateGroupBaseProps & {
      isEdit: true;
      selectedPhase: { [key: string]: any };
      selectedGroup: { [key: string]: any };
    });

const CreateOrEditGroup: React.FC<CreateOrEditGroupProps> = ({
  isEdit,
  steps,
  onBack,
  currentStep,
  isSaving,
  setIsSaving,
  selectedPhase,
  selectedGroup,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [groups, setGroups] = useState([]);
  const { currentTournament, hideContainerModal } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? selectedGroup.name : "",
  };
  const [formData, setFormData] = useState(dataForm);
  console.log("Current group:", selectedGroup);

  const onPressCreateOrEditGroup = async (data: any) => {
    setIsSaving(true);
    try {
      if (selectedGroup?.id && isEdit) {
        let editedGroup: any = await editGroup(
          currentTournament?.id,
          selectedPhase,
          data.name,
          selectedPhase?.id,
          selectedGroup?.id
        );
        let index = groups.findIndex((group) => group.id === selectedGroup.id);
        let newGroups = [...groups];
        newGroups[index] = editedGroup;
        setGroups(newGroups);
      } else if (selectedPhase?.id && !isEdit) {
        let createdGroup: any = await createGroup(
          currentTournament?.id,
          selectedPhase,
          data?.name,
          selectedPhase?.id
        );
        setGroups([...groups, createdGroup]);
      } else {
        setGroups([...groups, selectedGroup]);
      }
    } catch (error) {
      console.error("Error adding or editing the group: ", error);
    } finally {
      setIsSaving(false);
      onBack();
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      {
        key: "name",
        value: formData.name,
      },
    ];

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditGroup(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.updateGroup
            : currentStep === steps.createGroup) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={4}
            >
              <ModalHeaderComponent
                title={i18n.t("groups")}
                subtitle={i18n.t("completeGroupName")}
                confirmText={i18n.t("save")}
                onCancel={hideContainerModal}
                onBack={onBack}
                onConfirm={handleSubmit}
                isSaving={isSaving}
                hasMoreSteps={true}
                actionButtonsRequired={true}
              />
              <Flex gap={1} flexDirection={"column"}>
                <Flex gap={1} alignItems="center">
                  <FormLabel {...formLabelStyle}>
                    {i18n.t("nameGroup")}
                  </FormLabel>
                  <BodyText
                    fontSize={{ base: "12px", md: "13px" }}
                    fontWeight="regular"
                    textColor={colors.text.lightGray3}
                  >
                    {i18n.t("required")}
                  </BodyText>
                </Flex>
                <Input
                  type="text"
                  {...inputStyle}
                  placeholder={i18n.t("nameGroup") + "..."}
                  defaultValue={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                    setErrors((prev) => ({ ...prev, name: "" }));
                  }}
                />
                {errors.name && (
                  <BodyText
                    paddingLeft={4}
                    fontSize={{ base: "12px", md: "13px" }}
                    fontWeight="regular"
                    textColor={colors.text.red}
                  >
                    {errors.name}
                  </BodyText>
                )}
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditGroup;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
