import { track } from "@amplitude/analytics-browser";
import axios from "axios";
import { isMobile, isTablet } from "react-device-detect";
import { base_url } from "../definitions/constants/GlobalConstants";
import { AxiosProgressEvent } from "axios"; // Be sure to import this type

// For Amplitude tracking
export async function trackEvent(
  eventName: string,
  additionalEventProperties: any
) {
  let deviceType = "Desktop";
  if (isMobile) {
    deviceType = "Mobile";
  } else if (isTablet) {
    deviceType = "Tablet";
  }

  const eventTrackingProperties = {
    ...additionalEventProperties,
    platform: "Web",
    deviceType: deviceType,
  };

  track(eventName, eventTrackingProperties);
}

export const consumeApi = (
  endPoint: string,
  method: "GET" | "POST",
  body?: any
): Promise<any> => {
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    url: `${base_url}/${endPoint}`,
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export function generateNumberFromText(text: any) {
  if (!text) return 0;
  let firstLetter = text[0];
  let number = firstLetter.charCodeAt(0) - 97;
  return (number < 0 ? number * -1 : number) % 10;
}

// Helper function to loop over documents in a collection and id to the returned objects
export const arrayElementLooper = (snapshot: any) => {
  let data: any = [];
  snapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
      id: doc.id,
    });
  });
  return data;
};

// Helper function to return the search results in the correct order
export const userSearchElementLooper = (snapshot: any) => {
  let data: any = [];
  snapshot.forEach((doc) => {
    data.push({
      bio: doc.data().bio,
      id: doc.id,
      name: doc.data().name,
      profile_picture: doc.data().profile_picture,
      username: doc.data().username,
    });
  });
  return data;
};

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const uploadFileToS3 = async (
  file: any,
  urlForFileUpload: any,
  updatePercentage: (percentage: number) => void
): Promise<any> => {
  const postData = new FormData();

  for (const key in urlForFileUpload.fields) {
    postData.append(key, urlForFileUpload.fields[key]);
  }
  postData.append("file", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.lengthComputable) {
        const percentage = (progressEvent.loaded / progressEvent.total) * 100;
        updatePercentage(percentage);
      }
    },
  };

  try {
    const response = await axios.post(urlForFileUpload.url, postData, config);
    if (response.status >= 200 && response.status <= 299) {
      return "success";
    } else {
      throw new Error(`Failed to upload: ${response.status}`);
    }
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw new Error("Error during file upload");
  }
};

export const uploadFileToAmazonS3Web = async (
  mediaFile: File,
  updatePercentage: (percentage: number) => void,
  typeMedia: string
): Promise<string> => {
  const extensionFile = mediaFile.name.split(".").pop();
  const dataUrl = await consumeApi(`posts/get_url_for_upload_to_s3`, "POST", {
    extension: extensionFile,
    type: typeMedia,
  });

  if (!dataUrl || !dataUrl.fields || !dataUrl.url) {
    throw new Error("Invalid S3 URL data");
  }

  const response = await uploadFileToS3(mediaFile, dataUrl, updatePercentage);
  if (response === "success") {
    return `${dataUrl.url}${dataUrl.fields.key}`;
  } else {
    throw new Error("Failed to upload file");
  }
};

const formatDateTime = (
  dateToFormat: any,
  options: Intl.DateTimeFormatOptions
) => {
  if (!dateToFormat || typeof dateToFormat.seconds !== "number") {
    return undefined;
  }

  const date = new Date(dateToFormat.seconds * 1000);
  return date.toLocaleString(undefined, options);
};

export const formatLocalDateAndTime = (dateToFormat: any) => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const datePart = formatDateTime(dateToFormat, optionsDate);
  const timePart = formatDateTime(dateToFormat, optionsTime);

  return `${datePart}\n${timePart}`;
};

export const formatLocalDate = (dateToFormat: any) => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return formatDateTime(dateToFormat, optionsDate);
};

export const formatLocalHour = (dateToFormat: any) => {
  const optionsTimeHour: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
  };
  return formatDateTime(dateToFormat, optionsTimeHour);
};

export const formatLocalMinutes = (dateToFormat: any) => {
  if (!dateToFormat || typeof dateToFormat.seconds !== "number") {
    return undefined;
  }

  const date = new Date(dateToFormat.seconds * 1000);
  const optionsTimeMinute: Intl.DateTimeFormatOptions = {
    minute: "2-digit",
  };

  const timePart = date.toLocaleTimeString(undefined, optionsTimeMinute);
  return timePart === "0" ? "00" : timePart;
};

export const createLocalityFromPlace = (place: any) => {
  if (!place) return null;

  const countryComponent = place.address_components.find((component: any) =>
    component.types.includes("country")
  )?.long_name;

  return {
    created_at: new Date().toISOString(),
    description: `${place.name}, ${countryComponent}`,
    id: place.place_id,
    matched_substrings: [
      {
        length: 4,
        offset: 0,
      },
    ],
    place_id: place.place_id,
    reference: place.reference,
    structured_formatting: {
      main_text: place.name,
      main_text_matched_substrings: [
        {
          length: 4,
          offset: 0,
        },
      ],
      secondary_text: countryComponent,
    },
    terms: [
      {
        offset: 0,
        value: place.name,
      },
      {
        offset: place.name.length + 1,
        value: countryComponent,
      },
    ],
  };
};

export const handleImageChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  onImageSet: (imageUrl: string) => void,
  onMediaSet: (file: File) => void
) => {
  const file = e.target.files?.[0];
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    onImageSet(imageUrl);
    onMediaSet(file);
  }
  e.target.value = "";
};

export const languageTag = {
  es: "Español",
  en: "Inglés",
  fr: "Francés",
  de: "Alemán",
  it: "Italiano",
  pt: "Portugués",
  zh: "Chino",
  ja: "Japonés",
  ru: "Ruso",
}
