import { Box, IconButton, Input, Select, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { appStore } from "../../../zustand/globalStore";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import { steps } from "./container/steps.container";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import { RolDirective } from "../../../definitions/enums/GlobalEnums";
import { SendIconWhite } from "../../../definitions/constants/IconsComponent";
import ItemInviteManager from "./components/ItemInviteManager";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import { inviteUserAsTechnicalStaff } from "../../../models/TeamModel";


const InviteManagers = ({ goToStep, hideContainerModal }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rol, setRol] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectUser, setSelectUser] = useState<User | null>(null);
  const { userData, currentTeam,currentTournament } = appStore((store) => store);

  
  const processInvitation = async () => {
    if(selectUser){
      setLoading(true)
      await inviteUserAsTechnicalStaff(userData?.id, selectUser?.id, currentTournament?.id, currentTeam?.id, currentTournament?.name, currentTeam?.name, rol)
      setLoading(false)
      goToStep(steps.successInviteTechnicalStaff)
    }
};

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    setSearchLoading(true)
      if (searchText.length > 0) {
          const findUsers = await httpsCallable(functions, 'searchUsersByText')({
              filter: `%${searchText.toLocaleLowerCase()}%`,
              userId: userData.id,
              type_user: 'user'
          });
          if(findUsers.data){
              setUsers(findUsers.data as User[]);
          }
      } 
      setSearchLoading(false)
  };

  return (
    <Box className="container" sx={{width:"100%"}}>
      <ModalHeaderComponent
        title={i18n.t("inviteCoachingStaff")}
        subtitle={i18n.t("get_ready_for_the_tournament")}
        confirmText={i18n.t("invite")}
        onCancel={hideContainerModal}
        onBack={() => goToStep(steps.default)}
        onConfirm={processInvitation}
        isSaving={loading}
        hasMoreSteps={true}
        actionButtonsRequired={true}
      />
      <Box className="container-inputs">
        <Box>
          <Box display={"flex"} gap={"5px"} padding={"10px 5px"}>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              textColor={colors.text.black}
            >
              {i18n.t("Rol")}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          </Box>
          <Select
            className="roles"
            placeholder={i18n.t("select")}
            value={rol}
            onChange={(e) => setRol(e.target.value)}
          >
            {Object.values(RolDirective).map((item) => (
              <option>{i18n.t(item)}</option>
            ))}
          </Select>
        </Box>
        <Box className="container-search-user">
          <Box display={"flex"} gap={"5px"}>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="bold"
              textColor={colors.text.black}
            >
              {i18n.t("searchUser")}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="medium"
              textColor={colors.text.lightGray3}
            >
              {i18n.t("required")}
            </BodyText>
          </Box>
          <Box className="input-and-button">
            <Input size={"lg"} value={searchText} onChange={handleSearch} />
            <IconButton
              aria-label=""
              background={colors.figmaColors.secColor}
              color={"white"}
            >
              <SendIconWhite />
            </IconButton>
          </Box>
        </Box>
        <Box className="container-content-users">
          {searchLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={"20px 0px"}
            >
              <Spinner size="lg" color="blue.500" />
            </Box>
          ) : (
            <Box className="users">
              {users.length > 0 &&
                users
                  .slice(0, 3)
                  .map((item) => (
                    <ItemInviteManager
                      item={item}
                      setUser={setSelectUser}
                      userActive={selectUser}
                    />
                  ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InviteManagers;
