import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { consumeApi } from "../../../../../utils/tools";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";

const DeleteSeason = ({
  goToStep,
  setSeasons,
  selectedSeason,
}: {
  goToStep: (key: string) => void;
  setSeasons: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSeason: { season_id: string } | null;
}): JSX.Element => {
  const { hideContainerModal } = appStore((store) => store);

  const deleteSeason = async (seasonId) => {
    try {
      const response = await consumeApi("tournaments/delete_season", "POST", {
        season_id: seasonId,
      });

      if (response.status === "success") {
        setSeasons((prevSeasons) =>
          prevSeasons.filter((season) => season.season_id !== seasonId)
        );
        goToStep(stepsTournaments.successDeleteSeason);
      } else {
        console.error("Error deleting season:", response.message);
      }
    } catch (error) {
      console.error("Unexpected error deleting season:", error);
    }
  };

  const handleDeleteConfirmation = () => {
    if (selectedSeason) {
      deleteSeason(selectedSeason.season_id);
    }
  };

  return (
    <DeleteContentModal
      message={i18n.t("sureDeleteSeason")}
      onCancel={hideContainerModal}
      onConfirm={handleDeleteConfirmation}
    />
  );
};

export default DeleteSeason;
