import React, { useState } from "react";
import CreateAndEditTeam from "../../../components/TeamsComponent/CreateAndEditTeam";

const steps = {
  default: "default",
  successScreen: "successScreen",
};

const CreateTeam = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);

  return (
    <CreateAndEditTeam
      action="create"
      steps={steps}
      goToStep={goToStep}
      currentStep={currentStep}
      isSaving={isSaving}
      setIsSaving={setIsSaving}
    />
  );
};

export default CreateTeam;
