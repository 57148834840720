import React from "react";
import { Flex, Grid, useMediaQuery } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import BodyText from "../../components/Common/BodyText";
import i18n from "../../i18n/i18n";

const ExploreScreen: React.FC = () => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  return (
    <>
      <Flex width={"100%"} flexDirection={"column"}>
        <BodyText
          fontSize={{ base: "24px", md: "28px" }}
          fontWeight="light"
          color={colors.text.black}
        >
          {i18n.t("exploreSoccer")}
        </BodyText>
        <BodyText
          fontSize={{ base: "12px", md: "13px" }}
          fontWeight="regular"
          color={colors.text.lightGray3}
        >
          {i18n.t("foundMatches")}
        </BodyText>
      </Flex>
      <Grid
        width={"100%"}
        templateColumns={isMobile ? "1fr" : "1fr 2fr"}
        gap={8}
      ></Grid>
    </>
  );
};

export default ExploreScreen;
