import { useEffect, useState, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import BodyText from "../../../components/Common/BodyText";
import { appStore } from "../../../zustand/globalStore";
import { getPhasesByTournamentId } from "../../../models/Tournament";
import StatisticsTeamsGroup from "../statistics/StatisticsTeamsGroup";

export default function StandingsTournamentComponent() {
  const { currentTournament } = appStore((state) => state);
  const [phases, setPhases] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let phases = await getPhasesByTournamentId(currentTournament?.id);
    setPhases(phases);
  };

  const PositionsList = ({ phase }: { phase: any }) => {
    return (
      <Box w="100%">
        <Box py={2}>
          <BodyText fontWeight="semiBold" fontSize="sm" color="black">
            {phase.name}
          </BodyText>
        </Box>
        <StatisticsTeamsGroup phase={phase} />
      </Box>
    );
  };

  const PositionTable = useCallback(() => {
    return (
      <Box
        w="100%"
        flex="1"
        overflowY="auto"
        py={4}
        maxH="calc(100vh - 100px)"

      >
        {phases.map((phase, index) => (
          <PositionsList key={index} phase={phase} />
        ))}
      </Box>
    );
  }, [phases]);

  return (
    <>
      {currentTournament?.is_external ? (
        <StatisticsTeamsGroup />
      ) : (
        <PositionTable />
      )}
    </>
  );
}
