import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import i18n from "../../../i18n/i18n";
import BodyText from "../../../components/Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import TournamentViewOtherComponent from "./TournamentViewOtherComponent";
import TournamentViewOwnerComponent from "./TournamentViewOwnerComponent";
import TournamentTeamHeader from "../../../components/Common/TournamentTeamHeader";
import { appStore } from "../../../zustand/globalStore";
import CupViewComponent from "../cups/CupViewComponent";
import { trackEvent } from "../../../utils/tools";

export default function TournamentViewComponent() {
  const { currentTournament, userData } = appStore((state) => state);
  const [view, setView] = useState("tournament");
  console.log(currentTournament);

  useEffect(() => {
    trackEvent("TournamentViewComponent", {});
  }, []);

  // if (!userData.id === currentTournament?.user_id) {
  if (!currentTournament?.id) {
    return (
      <Flex justify="center" align="center" padding="20px">
        <BodyText size="medium" color={colors.text.black}>
          {i18n.t("current_id_tournament_not_found")}
        </BodyText>
      </Flex>
    );
  }

  return (
    <Flex sx={styles.container}>
      {view === "tournament" ? (
        <>
          <TournamentTeamHeader
            isTeam={false}
            data={currentTournament}
            onCupClick={() => setView("cup")}
          />
          {currentTournament?.user_id === userData.id ? (
            <TournamentViewOwnerComponent />
          ) : (
            <TournamentViewOtherComponent />
          )}
        </>
      ) : (
        <CupViewComponent
        //data={currentTournament}
        //onBack={() => setView("tournament")}
        />
      )}
    </Flex>
  );
}

const styles = {
  container: {
    gap: 3,
    flexDirection: "column",
  },
};
