import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { db, functions } from "../config/firebase";
import {
  GROUP_TABLE_NAME,
  MATCH_DELETED_TABLE_NAME,
  MATCH_TABLE_NAME,
  PHASES_TABLE_NAME,
  TEAM_TABLE_NAME,
  TOURNAMENT_TABLE_NAME,
  USER_TABLE_NAME,
} from "./ModelDefinition";
import {
  getPlayersByTeamAndTournament,
  getTeamById,
  getTechnicalStaffByTeamAndTournament,
} from "./TeamModel";
import {
  getPhasesFromTournamentByPhaseId,
  getTournamentById,
} from "./Tournament";
import { addNotificationToUser, getUserById } from "./UserModel";
import {
  RefereeTypes,
  TypeNotifications,
} from "../definitions/enums/GlobalEnums";
import i18n from "../i18n/i18n";
import { arrayElementLooper } from "../utils/tools";

export const createMatch = async (
  userId: string,
  data: any,
  tournamentId: string,
  phase: any,
  groupId?: string
) => {
  data.created_at = new Date();
  data.user_id = userId;
  data.tournament_id = tournamentId;
  data.phase_id = phase.id;
  if (phase.have_groups) {
    data.group_id = groupId;
  }
  data.status = "pending";
  const docRef = await addDoc(collection(db, MATCH_TABLE_NAME), data);
  return docRef;
};

export const updateTimeMatch = async (matchId: string, data: any) => {
  await setDoc(doc(db, `${MATCH_TABLE_NAME}`, matchId), data, { merge: true });
};
export const updateMatch = async (
  matchId: string,
  data: any,
  tournamentId?: string,
  phaseId?: string,
  groupId?: string
) => {
  const matchData: any = await httpsCallable(
    functions,
    "getMatchData"
  )({
    match_id: matchId,
  });
  // let matchData: any = await getMatchById(matchId)
  const userId = matchData.data.match.user_id;
  await setDoc(doc(db, `${MATCH_TABLE_NAME}`, matchId), data, { merge: true });
  //assign referee
  if (data.referees && data.referees[RefereeTypes.central]) {
    let newReferee = data.referees[RefereeTypes.central];
    if (newReferee.id !== userId) {
      addNotificationToUser(newReferee.id, {
        created_at: new Date(),
        push_notification_sended: false,
        origin_user_id: userId,
        status: "sended",
        text: `${matchData.data.user.firstname} ${
          matchData.data.user.lastname
        } ${i18n.t("assignedAsReferee")} ${matchData.data.first_team.name} VS ${
          matchData.data.second_team.name
        } ${i18n.t("ofTheTournament")} ${matchData.data.tournament.name}`,
        title: i18n.t("addedYouAsReferee"),
        type: TypeNotifications.refereeAssignment,
        show_alert: true,
        first_team_id: matchData.data.first_team.id,
        first_team_name: matchData.data.first_team.name,
        tournament_id: matchData.data.tournament.id,
        tournament_name: matchData.data.tournament.name,
        second_team_id: matchData.data.second_team.id,
        second_team_name: matchData.data.second_team.name,
        user_name: `${matchData.data.user.firstname} ${matchData.data.user.lastname}`,
        user_id: newReferee.id,
      });
    }
  }

  return data;
};
export const deleteMatch = async (matchId: string) => {
  let matchData: any = await getMatchById(matchId);
  await setDoc(doc(db, `${MATCH_DELETED_TABLE_NAME}`, matchId), matchData, {
    merge: true,
  });
  await deleteDoc(doc(db, `${MATCH_TABLE_NAME}`, matchId));
  return matchData;
};
export const getMatchById = async (matchId: string) => {
  const postDoc = doc(db, MATCH_TABLE_NAME, matchId);
  let returnData = null;
  await getDoc(postDoc)
    .then(async (snapshot: any) => {
      if (snapshot.exists()) {
        returnData = snapshot.data();
        returnData.first_team = await getTeamById(returnData.first_team_id);
        returnData.second_team = await getTeamById(returnData.second_team_id);
        returnData.id = snapshot.id;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return returnData;
};

export const getPendingMatchesForTeam = async (teamId: string) => {
  const matchesCollectionRef = collection(
    db,
    `${TEAM_TABLE_NAME}/${teamId}/${MATCH_TABLE_NAME}`
  );
  const q = query(matchesCollectionRef, where("status", "!=", "finished"));
  const data = await getDocs(q);
  let matchUser = arrayElementLooper(data.docs);
  return matchUser;
};

export const getMatchesByTournamentAndPhaseAndGroup = async (
  tournamentId: string,
  phaseId: string,
  groupId: string
) => {
  const matchesCollectionRef = collection(
    db,
    `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}/${groupId}/${MATCH_TABLE_NAME}`
  );
  const data = await getDocs(matchesCollectionRef);
  const matchesIds = arrayElementLooper(data.docs);
  return matchesIds;
};

export const getMatchesByTournamentAndPhase = async (
  tournamentId: string,
  phaseId: string
) => {
  const matchesCollectionRef = collection(
    db,
    `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${MATCH_TABLE_NAME}`
  );
  const data = await getDocs(matchesCollectionRef);
  const matchesIds = arrayElementLooper(data.docs);
  return matchesIds;
};

export const getAllMatchesByUser = async (userId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${userId}/${MATCH_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, orderBy("created_at", "desc"));
  const data = await getDocs(q);
  let matchUser = arrayElementLooper(data.docs);
  return matchUser;
};

export const getAllOwnMatchByUser = async (userId: string) => {
  const teamsCollectionRef = collection(db, `${MATCH_TABLE_NAME}`);
  const q = query(teamsCollectionRef, where("user_id", "==", userId));
  const data = await getDocs(q);
  let matchUser = arrayElementLooper(data.docs);
  return matchUser;
};

export const getPendingMatchesForUser = async (userId: string) => {
  const matchesCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${userId}/${MATCH_TABLE_NAME}`
  );
  const q = query(matchesCollectionRef, where("status", "!=", "finished"));
  const data = await getDocs(q);
  let matchUser = arrayElementLooper(data.docs);
  return matchUser;
};

export const getTopMatchesByUser = async (userId: string) => {
  const teamsCollectionRef = collection(
    db,
    `${USER_TABLE_NAME}/${userId}/${MATCH_TABLE_NAME}`
  );
  const q = query(teamsCollectionRef, orderBy("match_date", "desc"));
  const data = await getDocs(q);
  let matchUser = arrayElementLooper(data.docs);
  return matchUser;
};

export const matchStateChanged = (userId: string, callbackFunction: any) => {
  const usersCollectionRef = doc(db, MATCH_TABLE_NAME, userId);
  onSnapshot(usersCollectionRef, (snapshot) => {
    let returnData = snapshot.data();
    if (returnData) {
      returnData.id = snapshot.id;
      callbackFunction(returnData);
    }
  });
};
