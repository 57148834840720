import { Avatar, Box, Flex, IconButton } from "@chakra-ui/react";
import { colors } from "../../definitions/constants/GlobalStyles";
import { User } from "../../definitions/interfaces/Users/Users.interface";
import {
  ReactDispatchStringState,
  ReactDispatchUserState,
} from "../../definitions/interfaces/Global/ReactDispatchStringState.type";
import BodyText from "./BodyText";
import i18n from "../../i18n/i18n";
import "../styles/ItemPlayerUser.style.scss";
import IconTrash from "../../assets/icons/IconTrash";
import { steps } from "../TournamentsOfTeamsOptions/Players/containers/steps.container";
import IconEdit from "../../assets/icons/IconEdit";

const ItemPlayerUser = ({
  item,
  setUser,
  userActive,
  goStep,
}: {
  item: User;
  setUser: ReactDispatchUserState;
  userActive: User;
  goStep: ReactDispatchStringState;
}): JSX.Element => {
  return (
    <Box
      sx={{
        ...style.container,
        border:
          userActive?.id === item?.id
            ? `1px solid ${colors.figmaColors.secColor}`
            : null,
      }}
      key={item.id}
      className="container-item-player-user"
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"} gap={"10px"} justifyContent={"flex-start"}>
          <Avatar
            src={item.image_url}
            name={`${item.firstname} ${item.lastname}`}
          />
          <Box>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="bold"
              textColor={colors.text.black}
              textAlign="left"
            >
              {item.firstname} {item.lastname}
            </BodyText>
            <BodyText
              fontSize={{ base: "12px", md: "13px" }}
              fontWeight="regular"
              textColor={colors.text.lightGray3}
              textAlign="left"
            >
              {i18n.t("jersey")} #{item.number ? item.number : "-"}
            </BodyText>
          </Box>
        </Flex>
        <Flex>
          <IconButton
            aria-label="Edit player"
            background={"transparent"}
            className="edit-button"
            onClick={() => {
              setUser(item);
              goStep(steps.editPlayer);
            }}
          >
            <IconEdit/>
          </IconButton>
          <IconButton
            aria-label="Delete player"
            background={"transparent"}
            onClick={() => {
              setUser(item);
              goStep(steps.deletePlayer);
            }}
          >
            <IconTrash stroke={colors.backgrounds.red} />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  );
};
export default ItemPlayerUser;

const style = {
  container: {
    padding: "15px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
};
