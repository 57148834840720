export const TEAM_TABLE_NAME = "teams";
export const APPLICATION_PLAYERS_TABLE_NAME = "application_players";
export const APPLICATION_TECHNICAL_STAFF_TABLE_NAME =
  "application_technical_staff";
export const APPLICATION_MANAGER_TABLE_NAME = "application_managers";
export const APPLICATION_TABLE_NAME = "applications";
export const MANAGERS_TABLE_NAME = "managers";
export const TOURNAMENT_TABLE_NAME = "tournaments";
export const TOURNAMENT_TEAMS_TABLE_NAME = "tournament_teams_subscription";
export const PHASES_TABLE_NAME = "phases";
export const GROUP_TABLE_NAME = "groups";
export const RANKING_TABLE_NAME = "ranking_players";
export const FOLLOWING_TABLE_NAME = "following";
export const USER_TABLE_NAME = "users";
export const PLAYERS_TABLE_NAME = "players";
export const TECHNICAL_STAFF_TABLE_NAME = "technical_staff";
export const NOTIFICATIONS_TABLE_NAME = "notifications";
export const FILES_TABLE_NAME = "files";
export const PLACES_TABLE_NAME = "places";
export const POSTS_TABLE_NAME = "posts";
export const COMMENTS_TABLE_NAME = "comments";
export const INCIDENCE_TABLE_NAME = "incidences";
export const USER_POST_TABLE_NAME = "user_post_mapping";
export const MATCH_TABLE_NAME = "matchs";
export const MATCH_DELETED_TABLE_NAME = "matchs_deleteds";
export const USER_MESSAGE_MAP = "user_map_messages";
export const MESSAGES = "messages";
export const CONVERSATIONS = "conversations";
export const update_match = "update_match";
export const LIVE_TABLE_NAME = "lives";
export const CONNECTED_USERS_TABLE_NAME = "connected_users";
