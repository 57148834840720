import React, { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import BodyText from "../../components/Common/BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import i18n from "../../i18n/i18n";
import {
  consumeApi,
  handleImageChange,
  uploadFileToAmazonS3Web,
  createLocalityFromPlace,
} from "../../utils/tools";
import { createTournament, updateTournament } from "../../models/Tournament";
import ModalHeaderComponent from "../../components/Common/ModalHeaderComponent";
import PrivacySwitch from "../../components/Common/PrivacySwitch";
import CitySelector from "../../components/Common/CitySelector";
import ImageUploader from "../../components/Common/ImageUploader";
import MessageConfirmationComponent from "../../components/MessageConfirmationComponent";
import { PlaceDetails } from "../../definitions/interfaces/Place.interface";

const CreateOrEditTournament: React.FC<{
  isEdit: boolean;
  steps: { [key: string]: string };
  currentStep: string;
  goToStep: (key: string) => void;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isEdit, steps, goToStep, currentStep, isSaving, setIsSaving }) => {
  const [seasonName, setSeasonName] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [media, setMedia] = useState<File | undefined>(undefined);

  const {
    currentTournament,
    hideContainerModal,
    userData,
    setCurrentTournament,
    tournaments,
    setTournaments,
  } = appStore((state) => state);

  const dataForm = {
    name: isEdit ? currentTournament.name : "",
    address: isEdit ? currentTournament.address : "",
    phone_number: isEdit ? currentTournament.phone_number : "",
    email: isEdit ? currentTournament.email : "",
    location: isEdit ? currentTournament.location : "",
  };
  const [place, setPlace] = useState<PlaceDetails | null>(
    isEdit ? currentTournament.place : null
  );
  const [image, setImage] = useState(isEdit ? currentTournament.media_url : "");
  const [isPrivate, setIsPrivate] = useState(
    isEdit ? currentTournament.private : false
  );
  const [formData, setFormData] = useState(dataForm);

  const handleInputImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e, setImage, setMedia);
  };

  const handleSwitchChange = () => {
    setIsPrivate(!isPrivate);
  };

  const onPressCreateOrEditTournament = async (data: any) => {
    if (isSaving) return;

    setIsSaving(true);

    try {
      const tournamentData = prepareTournamentData(data);

      if (media) {
        tournamentData.media_url = await uploadMedia(media);
      } else if (image) {
        tournamentData.media_url = currentTournament.media_url;
      }

      const newTournament = isEdit
        ? await updateExistingTournament(currentTournament.id, tournamentData)
        : await createNewTournament(tournamentData);

      data.user_type = "tournament";
      await consumeApi(
        `users/create_or_update_user_for_team_or_tournament`,
        "POST",
        tournamentData
      );

      updateTournamentsState(newTournament);
      navigateToNextStep(isEdit);
    } catch (error) {
      console.error("Error creating or updating tournament: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const prepareTournamentData = (data: any): any => {
    return {
      ...data,
      user_id: userData.id,
      name: data.name.toUpperCase(),
      place:
        isEdit && place?.description === currentTournament?.place?.description
          ? currentTournament.place
          : createLocalityFromPlace(place),
      place_id:
        isEdit && place?.description === currentTournament?.place?.description
          ? currentTournament.place?.place_id
          : createLocalityFromPlace(place).place_id,
      place_description:
        isEdit && place?.description === currentTournament?.place?.description
          ? currentTournament.place?.description
          : createLocalityFromPlace(place).description,
      user_type: "tournament",
      private: isPrivate,
      id: isEdit ? currentTournament.id : undefined,
    };
  };

  const uploadMedia = async (media: File): Promise<string> => {
    return uploadFileToAmazonS3Web(
      media,
      (percentage: number) => console.log(percentage),
      "tournaments"
    );
  };

  const createNewTournament = async (data: any) => {
    const newTournament = await createTournament(userData.id, data);
    setCurrentTournament(newTournament);
    const payload = {
      tournament_id: newTournament.id,
      season_name: seasonName.trim() || `${i18n.t("season")} 1`,
      season_year: new Date().getFullYear(),
      duplicate_regulations: false,
    };
    await consumeApi(`tournaments/set_season`, "POST", payload);
    return newTournament;
  };

  const updateExistingTournament = async (id: string, data: any) => {
    await updateTournament(id, data);
    return { ...data, id };
  };

  const updateTournamentsState = (newTournament: any) => {
    const updatedTournaments = isEdit
      ? tournaments.map((tournament) =>
          tournament.id === newTournament.id ? newTournament : tournament
        )
      : [newTournament, ...tournaments];

    setTournaments(updatedTournaments);
  };

  const navigateToNextStep = (isEdit: boolean) => {
    goToStep(isEdit ? steps.successEdit : steps.successScreen);
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsToValidate = [
      { key: "name", value: formData.name },
      { key: "city", value: place },
    ];

    if (!isEdit) {
      fieldsToValidate.push({ key: "seasonName", value: seasonName });
    }

    fieldsToValidate.forEach((field) => {
      if (!field.value) newErrors[field.key] = i18n.t("fieldRequired");
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      onPressCreateOrEditTournament(formData);
    } else {
      return;
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <>
          {(isEdit
            ? currentStep === steps.editTournament
            : currentStep === steps.default) && (
            <Box
              as="form"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flexGrow={1}
            >
              {isEdit ? (
                <ModalHeaderComponent
                  title={i18n.t("yourTournament")}
                  subtitle={i18n.t("readyTournament")}
                  confirmText={i18n.t("save")}
                  onCancel={hideContainerModal}
                  onBack={() => goToStep(steps.default)}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  hasMoreSteps={true}
                  actionButtonsRequired={true}
                />
              ) : (
                <ModalHeaderComponent
                  title={i18n.t("titleTournamentTeam")}
                  subtitle={i18n.t("msgCreateTournament")}
                  confirmText={i18n.t("createTournament")}
                  onCancel={hideContainerModal}
                  onConfirm={handleSubmit}
                  isSaving={isSaving}
                  actionButtonsRequired={true}
                />
              )}
              <Grid templateColumns="200px 1fr 1fr" gap={8}>
                <GridItem
                  colSpan={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControl>
                    <ImageUploader
                      image={image}
                      onImageChange={handleInputImageChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <Flex gap={1} alignItems="center">
                          <FormLabel {...formLabelStyle}>
                            {i18n.t("nameTournament")}
                          </FormLabel>
                          <BodyText
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.lightGray3}
                          >
                            {i18n.t("required")}
                          </BodyText>
                        </Flex>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("nameTournament") + "..."}
                          value={formData.name}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                            setErrors((prev) => ({ ...prev, name: "" }));
                          }}
                        />
                        {errors.name && (
                          <BodyText
                            paddingLeft={4}
                            fontSize={{ base: "12px", md: "13px" }}
                            fontWeight="regular"
                            textColor={colors.text.red}
                          >
                            {errors.name}
                          </BodyText>
                        )}
                      </Flex>

                      {!isEdit && (
                        <Flex gap={1} flexDirection={"column"}>
                          <Flex gap={1} alignItems="center">
                            <FormLabel {...formLabelStyle}>
                              {i18n.t("seasonName")}
                            </FormLabel>
                            <BodyText
                              fontSize={{ base: "12px", md: "13px" }}
                              fontWeight="regular"
                              textColor={colors.text.lightGray3}
                            >
                              {i18n.t("required")}
                            </BodyText>
                          </Flex>
                          <Input
                            type="text"
                            {...inputStyle}
                            placeholder={i18n.t("seasonName") + "..."}
                            value={seasonName}
                            onChange={(e) => setSeasonName(e.target.value)}
                          />
                          {errors.seasonName && (
                            <BodyText
                              paddingLeft={4}
                              fontSize={{ base: "12px", md: "13px" }}
                              fontWeight="regular"
                              textColor={colors.text.red}
                            >
                              {errors.seasonName}
                            </BodyText>
                          )}
                        </Flex>
                      )}
                      <CitySelector
                        error={errors.city}
                        place={place}
                        setPlace={setPlace}
                      />
                    </Flex>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl>
                    <Flex gap={2.5} flexDirection={"column"}>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("cellPhone")}
                        </FormLabel>
                        <Input
                          type="tel"
                          {...inputStyle}
                          placeholder={i18n.t("cellPhone") + "..."}
                          value={formData.phone_number}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              phone_number: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("email")}
                        </FormLabel>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("email") + "..."}
                          value={formData.email}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                      <Flex gap={1} flexDirection={"column"}>
                        <FormLabel {...formLabelStyle}>
                          {i18n.t("address")}
                        </FormLabel>
                        <Input
                          type="text"
                          {...inputStyle}
                          placeholder={i18n.t("address") + "..."}
                          value={formData.address}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              address: e.target.value,
                            }))
                          }
                        />
                      </Flex>
                    </Flex>
                  </FormControl>
                </GridItem>
              </Grid>
              <PrivacySwitch
                isPrivate={isPrivate}
                isPrivateText={i18n.t("createTournamentPrivate")}
                onSwitchChange={handleSwitchChange}
              />
            </Box>
          )}
          {currentStep === steps.successScreen && (
            <MessageConfirmationComponent
              title={i18n.t("tournamentReady")}
              subtitle={i18n.t("messageAlert")}
              isDelete={false}
              onClick={hideContainerModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateOrEditTournament;

const formLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: colors.text.black,
  margin: 0,
  paddingLeft: 4,
};
const inputStyle = {
  fontSize: "14px",
  fontWeight: "400",
  height: "60px",
  borderRadius: "20px",
  color: colors.text.lightGray3,
};
