import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Avatar, Box, Button, Spinner } from "@chakra-ui/react";
import { User } from "../../../../../../definitions/interfaces/Users/Users.interface";
import BodyText from "../../../../../../components/Common/BodyText";
import { colors } from "../../../../../../definitions/constants/GlobalStyles";
import i18n from "../../../../../../i18n/i18n";
import IconTrash from "../../../../../../assets/icons/IconTrash";
import { useCamelCase } from "../../../../../../hooks/useCamelCase";
import {
  getInvitationManagerToTeam,
  removeManagerApplication,
} from "../../../../../../models/TeamModel";
import { appStore } from "../../../../../../zustand/globalStore";

const ItemRequest = ({
  item,
  setUsers,
}: {
  item: User;
  setUsers: Dispatch<SetStateAction<User[]>>;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { currentTeam } = appStore((store) => store);
  const [invitation, setInvitation] = useState(null);

  useEffect(() => {
    (async () => {
      const respInvitation = await getInvitationManagerToTeam(
        currentTeam.id,
        item.id
      );
      if (respInvitation != null) {
        setInvitation(respInvitation);
      }
    })();
  }, []);

  const deleteInvitation = async () => {
    setLoading(true);
    await removeManagerApplication(currentTeam.id, invitation, item.id);
    setLoading(false);
    setUsers((prev) => prev.filter((prevItem) => prevItem.id !== item.id));
  };

  return (
    <Box
      className="container-item-user"
      sx={{
        display: "flex !important",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box className="user-detail">
        <Avatar
          src={item.image_url}
          name={`${item.firstname} ${item.lastname}`}
        />
        <Box>
          <BodyText
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight="semiBold"
            textColor={colors.text.black}
          >
            {useCamelCase(item.firstname)} {useCamelCase(item.lastname)}
          </BodyText>
          <BodyText
            fontSize={{ base: "11px", md: "12px" }}
            fontWeight="medium"
            textColor={colors.text.lightGray3}
          >
            {`${i18n.t("request")}: ${i18n.t(item.rol)}`}
          </BodyText>
        </Box>
      </Box>
      <Button
        rightIcon={
          loading ? (
            <Spinner size="lg" color="blue.500" />
          ) : (
            <IconTrash
              stroke={colors.backgrounds.white}
              width="16px"
              height="16px"
            />
          )
        }
        background={colors.backgrounds.red}
        borderRadius={"10px"}
        padding="5px 10px"
        height="32px"
        onClick={deleteInvitation}
      >
        <BodyText
          fontSize={{ base: "11px", md: "12px" }}
          fontWeight="medium"
          textColor={colors.text.white}
        >
          {i18n.t("cancelRequest")}
        </BodyText>
      </Button>
    </Box>
  );
};

export default ItemRequest;
