import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { User } from "../../../definitions/interfaces/Users/Users.interface";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";
import { appStore } from "../../../zustand/globalStore";
import ItemInviteUser from "../../Common/ItemInviteUser";
import ModalHeaderComponent from "../../Common/ModalHeaderComponent";
import i18n from "../../../i18n/i18n";
import SearchUser from "../../Common/SearchUser";
import BodyText from "../../Common/BodyText";
import { colors } from "../../../definitions/constants/GlobalStyles";
import "../Players/styles/InvitePlayers.style.scss";
import { ReactDispatchStringState } from "../../../definitions/interfaces/Global/ReactDispatchStringState.type";
import { steps } from "../Players/containers/steps.container";
import { inviteUserAsPlayer } from "../../../models/TeamModel";
import { addPlayerToTeam } from "../../../models/Tournament";

const InvitePlayers = ({goStep}:{goStep:ReactDispatchStringState}):JSX.Element => {
  const [players, setPlayers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { userData, currentTournament, hideContainerModal, currentTeam } = appStore(
    (store) => store
  );
  const [selectUser, setSelectUser] = useState<User | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (searchText.length > 0) {
        const users = await httpsCallable(functions, 'searchPlayer')({
            filter: `%${searchText.toLocaleLowerCase()}%`,
            type_user: "user",
            userId: userData.id,
            tournamentId: currentTournament?.id
        });
        setPlayers(users.data as User[])
        setLoading(false);
      }else{
        const users = await httpsCallable(
            functions,
            "searchPlayer"
          )({
            filter: "%%",
            type_user: "user",
            userId: userData.id,
            tournamentId: currentTournament?.id,
          });
          setPlayers(users.data as User[]);
          setLoading(false);
      }    
    })();
  }, [currentTournament?.id, userData?.id, searchText]);

  const processInvitation = async () => {
    setIsSaving(true)
    await inviteUserAsPlayer(userData.id, selectUser.id, currentTournament?.id, currentTeam?.id, currentTournament?.name, currentTeam?.name)
    await addPlayerToTeam(userData, selectUser, currentTeam, currentTournament)
    setIsSaving(false)
    goStep(steps.successInvitePlayer)
  };
  const handleSearch = async(e) => {
    setSearchText(e.target.value);
  };

  return (
    <Flex width={"100%"} height="100%" direction={"column"} gap={"20px"}>
      <ModalHeaderComponent
        title={i18n.t("invitePlayers")}
        subtitle={i18n.t("get_ready_for_the_tournament")}
        hasMoreSteps={true}
        actionButtonsRequired={true}
        onBack={() => goStep(steps.default)}
        onCancel={hideContainerModal}
        onConfirm={processInvitation}
        confirmText={i18n.t("confirm")}
        isSaving={isSaving}
      />
      <SearchUser searchText={searchText} handleSearch={handleSearch} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={"20px 0px"}
        >
          <Spinner size="lg" color="blue.500" />
        </Box>
      ) : (
        <Box
         overflow={"scroll"}      
         className="container-content-users"
        >
          {searchText.trim() !== ""&&
             <BodyText
             fontSize={{ base: "12px", md: "13px" }}
             fontWeight="regular"
             textColor={colors.text.black}
           >
             {i18n.t('searchResults')} : {searchText}
           </BodyText>
          }
          {players.length > 0 &&
            players.map((item, index) => (
              <ItemInviteUser
                item={item}
                setUser={setSelectUser}
                userActive={selectUser}
              />
            ))}
        </Box>
      )}
    </Flex>
  );
};

export default InvitePlayers;
