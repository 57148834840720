import { Box, Image, Input } from "@chakra-ui/react";
import { AddIcon } from "../../definitions/constants/IconsComponent";
import { EditIcon } from "../../definitions/constants/IconsComponent";
import { colors } from "../../definitions/constants/GlobalStyles";

interface ImageUploaderProps {
  image: string;
  onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageIdUploader: React.FC<ImageUploaderProps> = ({
  image,
  onImageChange,
}) => {
  return (
    <Box
      width="250px"
      height="150px"
      borderRadius={"20px"}
      bg={colors.backgrounds.gray1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={() => document.getElementById("file-input-id")?.click()}
    >
      <Input
        type="file"
        accept="image/*"
        onChange={onImageChange}
        display="none"
        id="file-input-id"
        onClick={(e) => e.stopPropagation()}
      />
      {image && (
        <Image
          src={image}
          boxSize="100%"
          objectFit="cover"
          borderRadius={"20px"}
        />
      )}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {image ? (
          <EditIcon boxSize="20px" color="white" />
        ) : (
          <AddIcon boxSize="20px" color="white" />
        )}
      </Box>
    </Box>
  );
};

export default ImageIdUploader;