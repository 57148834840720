import { useState } from "react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { updateTeam } from "../../../../../models/TeamModel";
import SureDeleteContent from "../../../../../components/Modals/SureDeleteContent";
import { stepsTeams } from "../../../../../definitions/constants/GlobalConstants";

const DeleteTeam = ({
  goToStep,
}: {
  goToStep: (key: string) => void;
}): JSX.Element => {
  const { currentTeam, hideContainerModal, teams, setTeams } = appStore(
    (store) => store
  );
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await updateTeam(currentTeam.id, { deleted: true });
      setTeams(teams.filter((team) => team.id !== currentTeam.id));
      goToStep(stepsTeams.successDelete);
    } catch (error) {
      console.error("Error deleting team:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SureDeleteContent
      title={i18n.t("enterTeamName")}
      subtitle={i18n.t("teamSureDeleted")}
      textLabel={i18n.t("nameTeam")}
      textInput={i18n.t("nameTeam")}
      onCancel={hideContainerModal}
      onConfirm={onConfirm}
      loading={loading}
      currentName={currentTeam.name.trim()}
    />
  );
};

export default DeleteTeam;
