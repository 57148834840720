import { Box, Button, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import i18n from "../i18n/i18n";

interface ItemTournamentMatchComponentProps {
  match: {
    timestamp: number;
    fase: string;
    status: string;
    team_home_image_url?: string;
    team_home?: string;
    goals_home?: number;
    team_away_image_url?: string;
    team_away?: string;
    goals_away?: number;
    field_name?: string;
    match_date?: string;
    hour?: string;
  };
}

export default function ItemTournamentMatchComponent({
  match,
}: ItemTournamentMatchComponentProps) {
  const navigate = useNavigate();
  const localDate = new Date(match.timestamp * 1000).toLocaleDateString();
  const localTime = new Date(match.timestamp * 1000).toLocaleTimeString();

  // useEffect(() => {
  //     trackEvent("ItemTournamentMatchComponent", {});
  // }, []);

  return (
    <Button
      onClick={() => {
        navigate("/MatchViewScreen", { state: { match } });
      }}
      variant="outline"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
      backgroundColor="gray.50"
      padding={5}
      marginBottom={4}
      width="full"
      _hover={{ bg: "gray.100" }}
      _active={{ bg: "gray.200" }}
      textAlign="left"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={4}
        width="full"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="full"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flex="1"
          >
            {match.team_home_image_url ? (
              <Image
                src={match.team_home_image_url}
                boxSize="25px"
                borderRadius="full"
              />
            ) : (
              <Image
                boxSize="25px"
                borderRadius="full"
                objectFit="contain"
                alt={match.team_home}
                src={`path_to_placeholder_image/${match.team_home}.png`} // Replace with actual path or logic
              />
            )}
            <Text fontSize="sm" color="gray.600">
              {match.fase}
            </Text>
          </Box>
          <Box
            backgroundColor="blue.500"
            color="white"
            borderRadius="md"
            paddingX={3}
            paddingY={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="xs" fontWeight="semibold">
              {match.status}
            </Text>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={4}
          width="full"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flex="1"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              flex="1"
            >
              {match.team_home_image_url ? (
                <Image
                  src={match.team_home_image_url}
                  boxSize="25px"
                  borderRadius="full"
                  objectFit="contain"
                />
              ) : (
                <Image
                  boxSize="25px"
                  borderRadius="full"
                  objectFit="contain"
                  alt={match.team_home}
                  src={`path_to_placeholder_image/${match.team_home}.png`} // Replace with actual path or logic
                />
              )}
              <Text fontSize="sm" color="gray.600">
                {match.team_home}
              </Text>
            </Box>
            <Box
              backgroundColor="white"
              borderRadius="md"
              paddingX={3}
              paddingY={1}
              width="50px"
              textAlign="center"
            >
              <Text fontSize="md" color="gray.600">
                {match.goals_home}
              </Text>
            </Box>
          </Box>
          <Text fontSize="md" fontWeight="semibold" color="gray.600">
            -
          </Text>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
            flex="1"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              flex="1"
            >
              {match.team_away_image_url ? (
                <Image
                  src={match.team_away_image_url}
                  boxSize="25px"
                  borderRadius="full"
                  objectFit="contain"
                />
              ) : (
                <Image
                  boxSize="25px"
                  borderRadius="full"
                  objectFit="contain"
                  alt={match.team_away}
                  src={`path_to_placeholder_image/${match.team_away}.png`} // Replace with actual path or logic
                />
              )}
              <Text fontSize="sm" color="gray.600">
                {match.team_away}
              </Text>
            </Box>
            <Box
              backgroundColor="white"
              borderRadius="md"
              paddingX={3}
              paddingY={1}
              width="50px"
              textAlign="center"
            >
              <Text fontSize="md" color="gray.600">
                {match.goals_away}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          width="full"
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Text fontSize="xs" color="gray.500">
              {`${i18n.t("date")}: ${
                match.timestamp ? localDate : match.match_date
              }`}
            </Text>
            <Text fontSize="xs" color="gray.500">
              {`${i18n.t("hour")}: ${match.timestamp ? localTime : match.hour}`}
            </Text>
          </Box>
          <Text fontSize="xs" color="gray.500">
            {`${i18n.t("place")}: ${match.field_name}`}
          </Text>
        </Box>
      </Box>
    </Button>
  );
}
