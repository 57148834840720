import React from "react";
import { Flex } from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import i18n from "../../../i18n/i18n";
import MatchInfoCard from "./MatchInfoCard";
import { MatchSelectedProps } from "../../../definitions/interfaces/GlobalInterfaces";

interface TeamLineupProps {
  teamName: string;
}

const TeamLineup: React.FC<TeamLineupProps> = ({ teamName }) => (
  <Flex
    p={4}
    gap={2}
    width={"100%"}
    borderRadius={16}
    direction="column"
    background={colors.figmaColors.appBgColor}
  >
    <BodyText
      fontSize={17}
      fontWeight="semiBold"
      textAlign="center"
      color={colors.text.black}
    >
      {i18n.t("lineup_of", { team: teamName })}
    </BodyText>
    <BodyText fontSize={13} fontWeight="semiBold" color={colors.text.black}>
      {i18n.t("coach")}
    </BodyText>
    <MatchInfoCard isLineups={true} />
    <BodyText fontSize={13} fontWeight="semiBold" color={colors.text.black}>
      {i18n.t("players")}
    </BodyText>
    <MatchInfoCard isLineups={true} />
    <BodyText fontSize={13} fontWeight="semiBold" color={colors.text.black}>
      {i18n.t("substitutePlayer")}
    </BodyText>
    <MatchInfoCard isLineups={true} />
  </Flex>
);

const MatchLineups: React.FC<MatchSelectedProps> = ({
  firstTeam,
  secondTeam,
}) => {
  return (
    <Flex gap={3}>
      <TeamLineup teamName={firstTeam.name} />
      <TeamLineup teamName={secondTeam.name} />
    </Flex>
  );
};

export default MatchLineups;
