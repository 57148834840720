import React from "react";
import { Box, Button, Flex, Avatar } from "@chakra-ui/react";
import BodyText from "../Common/BodyText";
import { colors } from "../../definitions/constants/GlobalStyles";
import IconGroup from "../../assets/icons/IconGroup";
import IconPeopleFC from "../../assets/icons/IconPeopleFC";

interface ItemResultProps {
  item: any;
  onSelect: (value: any) => void;
}

const ItemSearch: React.FC<ItemResultProps> = ({ item, onSelect }) => {
  return (
    <Button
      display="flex"
      boxSize="60px"
      bg={colors.backgrounds.white}
      key={item.id}
      gap={2.5}
      p={0}
      width="100%"
      onClick={() => onSelect(item)}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box
        onClick={() => onSelect(item.firstname)}
        display="flex"
        alignItems="center"
      >
        <Avatar
          boxSize="56px"
          src={item.image_url}
          name={`${item.firstname ?? ""} ${item.lastname ?? ""}`}
          sx={{
            ".chakra-avatar__initials": {
              fontSize: "1.5rem",
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        flex="1"
      >
        <Flex gap={"5px"}>
          <BodyText
            fontWeight="medium"
            size="extraSmall"
            color={colors.text.black}
          >
            {`${item.firstname ?? ""} ${
              item.lastname && item.lastname !== "None" ? item.lastname : ""
            }`}
          </BodyText>
          {item.type_user === "team" && (
            <IconPeopleFC
              width="16px"
              height="16px"
              fill={colors.figmaColors.secColor}
              stroke={colors.figmaColors.secColor}
            />
          )}
          {item.type_user === "tournament" && (
            <IconGroup
              width="16px"
              height="16px"
              stroke={colors.figmaColors.secColor}
            />
          )}
        </Flex>
        <BodyText
          size="xxSmall"
          color={colors.text.black}
          fontWeight="bold"
          //fontWeight="medium"
          //color={colors.backgrounds.gray2}
        >
          {item.username ? "@" + item.username : ""}
        </BodyText>
      </Box>
    </Button>
  );
};

export default ItemSearch;
