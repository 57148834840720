import React from "react";
import CardComponent from "../../../components/Common/CardComponent";
import { appStore } from "../../../zustand/globalStore";
import i18n from "../../../i18n/i18n";

const TournamentCardComponent: React.FC<{ tournaments: any[] }> = ({
  tournaments,
}) => {
  const { setCurrentTournament } = appStore((state) => state);

  return (
    <CardComponent
      items={tournaments}
      isTeam={false}
      onSetCurrentItem={setCurrentTournament}
      getItemName={(tournament) => tournament.name}
      getItemRegion={(tournament) => {
        return tournament.place?.terms?.at(-1)?.value || i18n.t("noRegion");
      }}
      getItemImage={(tournament) => tournament.media_url}
      getItemCountryImage={(tournament) =>
        tournament.place?.terms?.at(-1)?.value
      }
    />
  );
};

export default TournamentCardComponent;
