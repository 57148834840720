import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  useMediaQuery,
  Avatar,
} from "@chakra-ui/react";
import { colors } from "../../../definitions/constants/GlobalStyles";
import BodyText from "../../../components/Common/BodyText";
import i18n from "../../../i18n/i18n";

const PlayerCard: React.FC<{ players: any[] }> = ({ players }) => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0);

  const players1 = [
    {
      name: "Juan Perez",
      image_url:
        "https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg",
      position: "CentroCampista",
      image_country: "https://media.api-sports.io/flags/br.svg",
      goals: 10,
    },
    {
      name: "Hernan Galindez",
      image_url:
        "https://wuamballapp.s3.amazonaws.com/wuamball%2Ftournaments%2F7ed620af-5d8e-4569-9c47-e7de9eb744ad-c7dfb664-4d61-46bc-be76-a1c2a131ec70.jpeg",
      position: "Defensa",
      image_country: "https://media.api-sports.io/flags/br.svg",
      goals: 5,
    },
  ];
  players = players1;

  return (
    <Flex gap="12px" flexDirection={"column"}>
      {players.map((player, index) => (
        <Flex
          key={index}
          flexDirection={"column"}
          align="center"
          borderRadius={20}
          gap={2.5}
          padding={4}
          border="1px solid"
          bg={colors.figmaColors.grey0}
          borderColor={colors.figmaColors.grey1}
          // borderColor={
          //   activeCardIndex === index
          //     ? colors.figmaColors.borderCard
          //     : colors.figmaColors.grey1
          // }
          // onClick={() => setActiveCardIndex(index)}
          // cursor="pointer"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex alignItems="center">
              <Box
                display="flex"
                boxSize="60px"
                borderRadius="16px"
                // border={`2px solid ${colors.figmaColors.secD}`}
                borderColor={colors.figmaColors.leaguesGradient1}
                justifyContent="center"
                alignItems="center"
                width="40px"
                height="40px"
              >
                <Avatar src={player.media_url} name={`${player.name ?? ""} `} />
              </Box>
              <Box
                marginLeft={5}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <BodyText
                  size="small"
                  fontWeight="regular"
                  color={colors.text.black}
                >
                  {player.name}
                </BodyText>
                <Flex alignItems="center">
                  <Image
                    src={player.image_country}
                    height="16px"
                    width="21px"
                  />
                  <BodyText
                    color={colors.text.lightGray3}
                    size="xxSmall"
                    fontWeight="regular"
                    marginLeft={2}
                  >
                    {player.position}
                  </BodyText>
                </Flex>
              </Box>
            </Flex>
            <Button
              borderRadius={20}
              marginLeft={2}
              height="30px"
              width="80px"
              bg={colors.backgrounds.green1}
              color={colors.text.black}
              border="px solid"
              borderColor={colors.backgrounds.green1}
              fontSize="13px"
              fontWeight={"600"}
            >
              {player.goals ? player.goals : 0} {i18n.t("goals")}
            </Button>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default PlayerCard;
