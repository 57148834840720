import React, { useState } from "react";
import CreateOrEditTournament from "../../../components/Tournaments/CreateOrEditTournament";

const steps = {
  default: "default",
  successScreen: "successScreen",
};

const CreateTournament = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.default);
  const goToStep = (stepKey) => setCurrentStep(stepKey);

  return (
    <CreateOrEditTournament
      isEdit={false}
      steps={steps}
      goToStep={goToStep}
      currentStep={currentStep}
      isSaving={isSaving}
      setIsSaving={setIsSaving}
    />
  );
};

export default CreateTournament;
