import { useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import i18n from "../../../../../i18n/i18n";
import { appStore } from "../../../../../zustand/globalStore";
import { deletePhase } from "../../../../../models/Tournament";
import { deleteMatch } from "../../../../../models/MatchModel";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../config/firebase";
import DeleteContentModal from "../../../../../components/Modals/DeleteContentModal";
import { stepsTournaments } from "../../../../../definitions/constants/GlobalConstants";
import { GetMatchesResponse } from "../../../../../definitions/types/GlobalTypes";

const DeletePhase = ({
  goToStep,
  selectedPhase,
}: {
  goToStep: (key: string) => void;
  selectedPhase: { [key: string]: any };
}): JSX.Element => {
  const { currentTournament, hideContainerModal } = appStore((store) => store);
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await deletePhase(selectedPhase.id, currentTournament.id, {
        deleted: true,
      });
      await deleteMatchesPhase();
      goToStep(stepsTournaments.successDeletePhase);
    } catch (error) {
      console.error("Error deleting team:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteMatchesPhase = async () => {
    if (selectedPhase.id) {
      try {
        const response = await httpsCallable<{}, GetMatchesResponse>(
          functions,
          "getMatchesAndTeamsByPhase"
        )({
          phase_id: selectedPhase.id,
        });

        const matches = response.data.matches;

        if (matches && matches.length > 0) {
          await Promise.all(
            matches.map(async (match) => {
              return deleteMatch(match.id);
            })
          );
        }
      } catch (error) {
        console.error("Error deleting match of phase", error);
      }
    }
  };

  if (loading) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={999}
      >
        <Spinner size="lg" color="blue.500" />
      </Box>
    );
  }

  return (
    <DeleteContentModal
      message={i18n.t("sureDeletePhase", { name: selectedPhase.name })}
      onCancel={hideContainerModal}
      onConfirm={onConfirm}
    />
  );
};

export default DeletePhase;
