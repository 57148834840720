import React from "react";
import { Route, Routes } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import HomePage from "./pages/HomePage";
import HomeAuthenticated from "./pages/HomeAuthenticated";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/login/RegisterPage";
import RecoverPage from "./pages/login/RecoverPage";
import HeaderComponent from "./components/LayoutsComponents/HeaderComponent";
import HeaderAuthenticated from "./components/LayoutsComponents/HeaderAuthenticated";
import TermsAndConditions from "./pages/TermsAndConditions";
import YoutubeVideos from "./pages/YoutubeVideos";
import TournamentPage from "./pages/tournaments/public_tournament/TournamentPage";
import ExploreScreen from "./pages/tournaments/ExploreScreen";
import MatchesScreen from "./pages/tournaments/MatchesScreen";
import TournamentCupsAndTeamsPage from "./pages/tournaments/TournamentCupsAndTeamsPage";
import useAuth from "./hooks/useAuth";

function AppRouter() {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/recover_password" element={<RecoverPage />} />
      <Route
        path="/*"
        element={
          isAuthenticated ? (
            <Flex
              direction="column"
              minHeight="100vh"
              p={{
                base: "8px 8px",
                sm: "8px 16px",
                md: "12px 24px",
                lg: "12px 32px",
              }}
              gap={{
                base: "8px",
                sm: "16px",
              }}
            >
              <HeaderAuthenticated>
                <Routes>
                  <Route
                    path="/explore"
                    element={
                      <HomeAuthenticated>
                        <ExploreScreen />
                      </HomeAuthenticated>
                    }
                  />
                  <Route
                    path="/matches"
                    element={
                      <HomeAuthenticated>
                        <MatchesScreen />
                      </HomeAuthenticated>
                    }
                  />
                  <Route
                    path="/tournaments"
                    element={
                      <HomeAuthenticated>
                        <TournamentCupsAndTeamsPage />
                      </HomeAuthenticated>
                    }
                  />
                </Routes>
              </HeaderAuthenticated>
            </Flex>
          ) : (
            <HeaderComponent>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/terms_and_conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="/youtube_video/:videoId"
                  element={<YoutubeVideos />}
                />
                <Route
                  path="/tournament/:tournamentName"
                  element={<TournamentPage />}
                />
              </Routes>
            </HeaderComponent>
          )
        }
      />
    </Routes>
  );
}

export default React.memo(AppRouter);
