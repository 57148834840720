import React from "react";

const IconArrowLarge = ({
  fill = "none",
  stroke = "currentColor",
  width = "16",
  height = "16",
  strokeWidth = "1.5",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="vuesax/linear/arrow-right">
      <g id="arrow-right">
        <path
          id="Vector"
          d="M9.62012 4.45312L13.6668 8.49979L9.62012 12.5465"
          stroke={stroke}
          stroke-width={strokeWidth}
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.3335 8.5H13.5535"
          stroke={stroke}
          stroke-width={strokeWidth}
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default IconArrowLarge;
